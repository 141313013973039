import { SET_CURRENT_PAGE_USERS } from 'actions/users.action';

const INITIAL_STATE = {
  result: [],
};

const usersReduxer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE_USERS:
      return { ...state, result: action.item };

    default:
      return state;
  }
};

export default usersReduxer;
