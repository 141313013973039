import React from 'react';
import parse from 'html-react-parser';
import {
  Avatar,
  ConversationTile,
  SneakPeak,
  SneakPeakText,
} from './Communicator.css';

import { convertSneakPeakDate } from './atoms/DateConverter';

const Conversation = ({
  setSelectedThread,
  conversation,
  index,
  selected,
  toggleNavigator,
}) => {
  const handleConversationClick = () => {
    setSelectedThread(index);
    toggleNavigator();
  };

  const conversationReceiver = conversation.conversationWith.split(' ');

  return (
    <ConversationTile onClick={handleConversationClick} selected={selected}>
      <p>{convertSneakPeakDate(conversation.lastMessageDateSent)}</p>
      <SneakPeak>
        <Avatar>
          <p>
            {conversationReceiver[1]?.slice(0, 1)}
            {conversationReceiver[2]?.slice(0, 1)}
          </p>
        </Avatar>
        <div>
          <h2>{conversation.conversationWith}</h2>
          <SneakPeakText>
            {parse(conversation.lastMessageContent.slice(0, 50))}
          </SneakPeakText>
        </div>
      </SneakPeak>
    </ConversationTile>
  );
};

export default Conversation;
