import React, { useState } from 'react';

import { Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Table from 'components/tables/Table';
import ModalDeleteFile from 'components/ImportedFiles/ModalDeleteFile';

import EyeIcon from 'assets/icons/eye.svg';
import TrashCan from 'assets/icons/trash_can.svg';
import styled from 'styled-components';
import { apiEndpoints, sendRequest } from 'API';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Indicator from 'components/atoms/Indicator';

const Button = styled.button`
  padding: 0px;
  font-weight: 600;
  color: ${({ theme, deleteButton }) =>
    deleteButton ? '#c4c3ca' : theme.primaryLight};
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  :hover {
    transform: scale(1.05);
  }

  img {
    margin-right: 10px;
  }
`;

const filesFiltersTable = [
  {
    type: 'dataPicker',
    name: 'UploadDate',
    nameTable: 'importedFiles',
    label: 'Data importowania',
  },
];

const ImportedFilesTable = ({
  importFile,
  dataTable = [],
  getValues,
  simple,
}) => {
  const { user } = useSelector(state => state.auth);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState({});
  const [isLoading, setLoading] = useState(false);

  const columns = [
    { title: 'Nazwa pliku', fieldName: 'Name', sorting: true },
    { title: 'Data importowania', fieldName: 'UploadDate', sorting: true },
    { title: 'Urzędnik', fieldName: 'Owner' },
    { title: '' },
    { title: '' },
  ];

  const rows = dataTable?.map(row => (
    <Tr key={row.id}>
      <Td>{row.name}</Td>
      <Td>{row.uploadDate}</Td>
      <Td>{row.owner}</Td>

      <Td>
        <Button type='button' onClick={() => showFile(row.id)}>
          <img src={EyeIcon} alt='ikona oka' /> Podgląd
        </Button>
      </Td>

      {user.permissions.includes('CRUD_FILES') && (
        <Td>
          <Button type='button' onClick={() => deleteFile(row)} deleteButton>
            <img src={TrashCan} alt='ikona kosza' /> Usuń
          </Button>
        </Td>
      )}
    </Tr>
  ));

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(prev => !prev);
  };

  const showFile = async id => {
    setLoading(true);

    try {
      const { data } = await sendRequest.get(apiEndpoints.getFile(id));

      const blob = new Blob([data], {
        type: 'text/xml',
      });
      const url = URL.createObjectURL(blob);
      window.open(url);
      URL.revokeObjectURL(url);
    } catch (e) {
      toast.error('Wystąpił błąd podczas pobierania pliku');
    } finally {
      setLoading(false);
    }
  };

  const deleteFile = file => {
    setFileToDelete(file);
    toggleDeleteModal();
  };

  return (
    <>
      {isLoading ? (
        <Indicator />
      ) : (
        <>
          <ModalDeleteFile
            isDeleteModalOpen={isDeleteModalOpen}
            toggleDeleteModal={toggleDeleteModal}
            getValues={getValues}
            fileToDelete={fileToDelete}
            setFileToDelete={setFileToDelete}
          />
          <Table
            menuButtonOnClick={
              user.permissions.includes('CRUD_FILES') ? importFile : null
            }
            columns={columns}
            rows={rows}
            type='files'
            getValues={getValues}
            searchPlaceholder='Szukaj nazwę pliku'
            filtersTable={filesFiltersTable}
            simple={simple}
          />
        </>
      )}
    </>
  );
};

export default ImportedFilesTable;
