import React, { useEffect, useState } from 'react';
import { Button, Form } from 'm-web-components';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { apiEndpoints, sendRequest } from 'API';

import { statusValues, statusNames } from '../../constans';
import theme from 'styles/theme';

import { formFieldsAccount, formFieldsUser } from './userDataInputs';
import UserDataSection from './UserDataSection';
import Indicator from 'components/atoms/Indicator';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const UserData = ({ userData }) => {
  const { id } = useParams();
  const { user } = useSelector(state => state.auth);

  const [isEditableUserData, setIsEditableUserData] = useState(false);
  const [isEditableAccountData, setIsEditableAccountData] = useState(false);

  const [rolesList, setRolesList] = useState([]);

  const getRoles = async () => {
    const { data } = await sendRequest.get(apiEndpoints.getRoles);
    setRolesList(data.map(role => ({ label: role.name, value: role.roleId })));
  };

  useEffect(() => {
    getRoles();
  }, []);

  const handleSubmitUserData = async values => {
    try {
      await sendRequest.put(apiEndpoints.updateUserDataByAdmin(id), values);
      setIsEditableUserData(false);
      toast.success('Dane zostały zapisane');
    } catch (e) {
      toast.error(
        'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z administratorem',
      );
    }
  };

  const handleSubmitAccountData = async values => {
    const body = {
      roleId: values.role.value,
      userStatus: values.userStatus.value,
    };

    try {
      await sendRequest.put(apiEndpoints.updateUserRoleByAdmin(id), body);
      setIsEditableAccountData(false);
      toast.success('Dane zostały zapisane');

      // it's for change status after change role and reverse
      // it's nessesary because we dont have acces to react hook api
      // if we use useForm instead Form from m-web-components we could use setValue
      // if reload will be a problem - it should be change
      window.location.reload(true);
    } catch (e) {
      toast.error(
        'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z administratorem',
      );
    }
  };

  const buttonFormProps = isEditable => ({
    colorType: 'primary',
    padding: '5px 20px',
    margin: '0',
    style: {
      display: `${isEditable ? 'block' : 'none'}`,
      position: 'absolute',
      top: '15px',
      right: '15px',
    },
  });

  return (
    <>
      {!userData && rolesList ? (
        <Indicator />
      ) : (
        <>
          <UserDataSection
            title='Dane użytkownika'
            status={isEditableUserData}
            setStatus={setIsEditableUserData}
            isPermission={user.permissions.includes('EDIT_OTHERS_DETAILS')}
          >
            <Form
              defaultValues={userData}
              maxWidth='500px'
              onSubmit={handleSubmitUserData}
              formFields={formFieldsUser(isEditableUserData)}
              labelSubmit='Zapisz'
              center
              buttonProps={buttonFormProps(isEditableUserData)}
            />
          </UserDataSection>
          <UserDataSection
            title='Dane konta'
            status={isEditableAccountData}
            setStatus={setIsEditableAccountData}
            isPermission={user.permissions.includes('EDIT_ACCOUNT_STATUS')}
          >
            <Form
              defaultValues={{
                userStatus: {
                  value: statusValues[userData.status],
                  label: statusNames[userData.status],
                  color: theme[userData.status],
                },
                role: { name: userData.role, label: userData.role },
              }}
              maxWidth='500px'
              onSubmit={handleSubmitAccountData}
              formFields={formFieldsAccount(isEditableAccountData, rolesList)}
              labelSubmit='Zapisz'
              center
              buttonProps={buttonFormProps(isEditableAccountData)}
            />
          </UserDataSection>
          {user.permissions.includes('EDIT_OTHERS_DETAILS') &&
            user.userDetails.id !== userData.id && (
              <UserDataSection title='Ustawienia konta'>
                <Link to={`/admin/users/delete/${userData.id}`}>
                  <Button
                    type='button'
                    colorType='deny'
                    padding='5px 20px'
                    margin='15px 20px 25px'
                    fontWeight='600'
                  >
                    Usuń konto
                  </Button>
                </Link>
              </UserDataSection>
            )}
        </>
      )}
    </>
  );
};

export default UserData;
