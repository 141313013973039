import React from 'react';
import styled from 'styled-components';

import MayerynLogoImg from 'assets/images/mayeryn.png';
import { device } from 'styles/devices';

const Mayeryn = styled.div`
  /* position: absolute; */
  bottom: 10px;
  right: 40px;
  text-align: right;
  opacity: ${({ left }) => (left ? 0.3 : 0.6)};
  margin-top: auto;
  padding: 20px;

  p {
    color: #fff;
    font-size: 1rem;
    margin-bottom: 3px;
    padding: 0;
  }

  @media ${device.tablet} {
    right: 20px;
    left: auto;

    text-align: ${({ left }) => (left ? 'left' : 'right')};
    right: ${({ left }) => !left && '20px'};
    left: ${({ left }) => left && '20px'};
  }
`;

const MayerynLogo = ({ left }) => {
  return (
    <Mayeryn left={left}>
      <p>Projekt i realizacja</p>
      <a href='https://mportal.mayeryn.com/r/Fbh'>
        <img src={MayerynLogoImg} alt='logo-mayeryn' />
      </a>
    </Mayeryn>
  );
};

export default MayerynLogo;
