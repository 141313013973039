import styled, { css } from 'styled-components';
import { device } from 'styles/devices';
import { scroll } from 'styles/mixins';

export const Wrapper = styled.div`
  padding-bottom: ${({ simple }) => (simple ? '0' : '150px')};

  table {
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    border-collapse: collapse;
    background-color: #fff;
  }

  th {
    color: ${({ theme }) => theme.light};
    text-align: left;
    padding: 10px;
    font-weight: bold;
  }

  td,
  th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  td {
    padding: 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 15px;

  @media (min-width: 1140px) {
    flex-direction: row;
    align-items: center;
  }

  aside {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;

    @media ${device.mobileL} {
      flex-direction: row;
      align-items: center;
    }
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  margin-top: 5px;
  border: 1px solid #f2f2f2;
  box-shadow: 1px 1px 15px rgba(0, 4, 40, 0.03);
  background-color: #fff;
  height: 32px;
  padding-right: 7px;

  & > div {
    width: 320px;
  }

  svg {
    padding-top: 3px;
    fill: #888794;
  }

  input {
    border: none;
  }
`;

export const FiltersContainer = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 5px;
`;

export const FiltersButtonToggle = styled.button`
  border: 1px solid #f2f2f2;
  box-shadow: 1px 1px 15px rgba(0, 4, 40, 0.03);
  border-radius: 4px;
  background-color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }
`;

export const FiltersStyled = styled.form`
  position: absolute;
  background-color: white;
  width: 350px;
  padding: 20px;
  border: 1px solid #f2f2f2;
  box-shadow: 2px 2px 20px rgba(0, 4, 40, 0.08);
  border-radius: 4px;

  max-height: ${({ openFilter }) => (openFilter ? 'auto' : 0)};
  /* overflow: hidden; */
  transition: 0.2s;
  transform: ${({ openFilter }) => (openFilter ? 'scale(1)' : 'scale(0)')}
    translateX(-75%);
  opacity: ${({ openFilter }) => (openFilter ? '1' : '0')};

  ${scroll}

  .nice-dates-grid {
    background-color: white;
    width: 300px;
  }

  .nice-dates-day::before {
    background-color: ${({ theme }) => theme.primary};
    color: white;
  }
`;

export const Title = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.titleLight};
`;

export const Menu = styled.div`
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  height: 32px;
  margin-left: auto;

  @media ${device.mobileL} {
    margin: 0;
  }

  @media (max-width: 530px) {
    height: 70px;
  }
`;

export const customStyles = css`
  display: flex;
  justify-content: space-between !important;

  @media (max-width: 745px) {
    flex-direction: column-reverse;

    .MuiGrid-item.MuiGrid-grid-xs-7 {
      width: 100% !important;
    }
  }

  .MuiPagination-ul {
    display: flex;
    justify-content: flex-end;
  }

  .MuiPaginationItem-root {
    border: none;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .MuiPaginationItem-page,
  .MuiPaginationItem-page.Mui-selected {
    :hover {
      font-weight: bold;
    }
  }

  .css-tlfecz-indicatorContainer,
  .css-lgtu0rj-indicatorContainer {
    padding: 2px 8px !important;
  }
`;

export const customSelectStyles = {
  container: provided => ({
    ...provided,
    maxWidth: '150px',
  }),
  control: provided => ({
    ...provided,
    minWidth: '150px',
    border: '1px solid #F2F2F2',
    height: '30px',
    borderRadius: '4px',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : '#000428',
    backgroundColor: state.isSelected ? '#000428' : 'white',
    padding: 5,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  menu: provided => ({
    ...provided,
    marginTop: '0px',
  }),
};
