import React from 'react';
import { Input } from 'components/atoms/Input';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';

const Button = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.primaryLight};
  text-decoration: underline;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export const formFields = showRegulation => [
  {
    as: <Input variant='outlined' disabled={true} />,
    name: 'name',
    label: 'Imię*',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: <Input variant='outlined' disabled={true} />,
    name: 'surname',
    label: 'Nazwisko*',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: <Input variant='outlined' />,
    name: 'address',
    label: 'Adres (ulica, numer budynu/mieszkania)*',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: <Input variant='outlined' />,
    name: 'city',
    label: 'Miejscowość*',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: <Input variant='outlined' disabled={true} />,
    name: 'email',
    label: 'Email',
    type: 'text',
  },
  {
    as: <Input variant='outlined' />,
    name: 'phoneNumber',
    label: 'Telefon',
    type: 'text',
  },
  {
    as: <Checkbox />,
    name: 'agree',
    rules: { required: 'To pole jest wymagane' },
    label: (
      <span>
        Akceptuję{' '}
        <Button type='button' onClick={showRegulation}>
          regulamin
        </Button>{' '}
        systemu eBOK. *
      </span>
    ),
    type: 'checkbox',
  },
];

export const businessFormFields = showRegulation => [
  {
    as: <Input variant='outlined' disabled={true} />,
    name: 'name',
    label: 'Imię*',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: <Input variant='outlined' disabled={true} />,
    name: 'surname',
    label: 'Nazwisko*',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: <Input variant='outlined' />,
    name: 'companyName',
    label: 'Nazwa firmy*',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: <Input variant='outlined' />,
    name: 'nip',
    label: 'NIP*',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: <Input variant='outlined' />,
    name: 'address',
    label: 'Adres (ulica, numer budynu/mieszkania)*',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: <Input variant='outlined' />,
    name: 'city',
    label: 'Miejscowość*',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: <Input variant='outlined' disabled={true} />,
    name: 'email',
    label: 'Email',
    type: 'text',
  },
  {
    as: <Input variant='outlined' />,
    name: 'phoneNumber',
    label: 'Telefon',
    type: 'text',
  },
  {
    as: <Checkbox />,
    name: 'agree',
    rules: { required: 'To pole jest wymagane' },
    label: (
      <span>
        Akceptuję{' '}
        <Button type='button' onClick={showRegulation}>
          regulamin
        </Button>{' '}
        systemu eBOK. *
      </span>
    ),
    type: 'checkbox',
  },
];
