import React, { useEffect, useState } from 'react';
import PermissionsSection from './PermissionsSection';
import { TitleInput } from './Permissions.css';

import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import EditIcon from '@material-ui/icons/Edit';
import { apiEndpoints, sendRequestFormData } from 'API';
import { toast } from 'react-toastify';

const PermissionsBookmark = ({
  roleId,
  roleData,
  sections,
  getRoleData,
  nameRole,
  getRoles,
}) => {
  const [title, setTitle] = useState(nameRole);
  const [isEditable, setEditable] = useState(false);

  useEffect(() => {
    setTitle(nameRole);
  }, [nameRole]);

  const changeName = async () => {
    try {
      await sendRequestFormData.put(apiEndpoints.changeRoleName(roleId, title));
      getRoles();
      toast.success('Udało się zmienić nazwę.');
      setEditable(false);
    } catch (e) {
      if (e?.response?.data) {
        toast.error(e.response.data);
      } else {
        toast.error(
          'Nie udało się zmienić nazwy. Spróbuj ponownie lub skontaktuj się z dostwcą oprogramowania',
        );
      }
    }
  };

  return (
    <>
      <TitleInput>
        {isEditable ? (
          <>
            <input value={title} onChange={e => setTitle(e.target.value)} />
            <CheckCircleOutlinedIcon
              fontSize='large'
              style={{ color: '#3B9833', opacity: 0.7 }}
              onClick={changeName}
            />
            <HighlightOffOutlinedIcon
              fontSize='large'
              style={{ color: '#B43236', opacity: 0.7 }}
              onClick={() => {
                setTitle(nameRole);
                setEditable(false);
              }}
            />
          </>
        ) : (
          <>
            <h2>{title}</h2>
            <EditIcon
              onClick={() => {
                setEditable(true);
              }}
            />
          </>
        )}
      </TitleInput>
      {sections.map(section => (
        <PermissionsSection
          key={section.name}
          title={section.name}
          category={section}
          data={roleData}
          roleId={roleId}
          getRoleData={getRoleData}
        />
      ))}
    </>
  );
};

export default PermissionsBookmark;
