import React from 'react';
import { useDispatch } from 'react-redux';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import allActions from 'actions';

const RadioFilter = ({ label, radios, currentValue, name, nameTable }) => {
  const dispatch = useDispatch();

  const handleChange = e => {
    dispatch(
      allActions.filtersTableActions.setUserFilterTable({
        nameFilter: name,
        value: e.target.value,
        nameTable,
      }),
    );
  };

  return (
    <>
      <p style={{ margin: '20px 0 0 0' }}>{label}</p>
      <RadioGroup
        aria-label='gender'
        name='userStatus'
        value={currentValue}
        onChange={handleChange}
      >
        {radios.map(radio => (
          <FormControlLabel
            key={radio.id}
            value={radio.name}
            control={<Radio style={{ color: '#1B69F2' }} />}
            label={radio.label}
          />
        ))}
      </RadioGroup>
    </>
  );
};

export default RadioFilter;
