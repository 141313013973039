import React from 'react';
import TextField from '@material-ui/core/TextField';
import { format } from 'date-fns';

import { pl } from 'date-fns/locale';
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';

const DataPicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  disabled,
}) => {
  return (
    <DateRangePicker
      startDate={startDate}
      endDate={endDate}
      onStartDateChange={setStartDate}
      onEndDateChange={setEndDate}
      minimumLength={1}
      format='dd-MM-yyyy'
      locale={pl}
    >
      {({ startDateInputProps, endDateInputProps, focus }) => (
        <div className='date-range' style={{ display: 'flex' }}>
          <TextField
            className={`input${focus === START_DATE ? ' -focused' : ''}`}
            {...startDateInputProps}
            placeholder={format(new Date(), 'dd/MM/yyyy')}
            disabled={disabled}
          />
          <span className='date-range_arrow' />
          <TextField
            className={`input'${focus === END_DATE ? ' -focused' : ''}`}
            {...endDateInputProps}
            placeholder={format(new Date(), 'dd/MM/yyyy')}
            style={{ marginLeft: '20px' }}
            disabled={disabled}
          />
        </div>
      )}
    </DateRangePicker>
  );
};

export default DataPicker;
