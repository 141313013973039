import React, { useEffect, useState } from 'react';
import { Charts, Select } from 'm-web-components';
import DataPicker from 'components/atoms/DataPicker';

import { format } from 'date-fns';
import { apiEndpoints, sendRequest } from 'API';

import CustomTooltip from './CusomTooltip';
import * as S from './Chart.css';
import HorizontalMenuSwitch from 'components/Shared/HorizontalMenuSwitch/HorizontalMenuSwitch';
import { ExportButton } from './ExportButton';

const visibleData = [
  'xAxisIsVisible',
  'yAxisIsVisible',
  'cartesianGridIsVisible',
  'tooltipIsVisible',
];

const appearanceData = {
  strokeDasharrayGridGaps: 5,
  strokeDasharrayGridLength: 5,
  strokeDasharrayLines: null,
  strokeWidthLine: 5,
  typeLine: 'monotone',
};

const aggBy = {
  month: 'MONTH',
  quarter: 'QUARTER',
  year: 'YEAR',
};

const Chart = ({ elements, title, simple, data: dataProps, category, uId }) => {
  const [currentType, setCurrentType] = useState(aggBy.month);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [data, setData] = useState([]);

  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  let container;

  const getReportFromAPI = async (type, evidenceNumber) => {
    const param = {
      eNr: evidenceNumber || '',
      category: category || '',
      uId: uId || '',
      aggBy: type || currentType,
      dTo: endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd'),
      dFrom:
        startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'),
    };

    const { data } = await sendRequest.get(apiEndpoints.getReport(param));
    setData(data);
  };

  useEffect(() => {
    setWidth(container.getBoundingClientRect().width - 20);
    setHeight(container.getBoundingClientRect().height);
    getReportFromAPI();
  }, []);

  const pickFilter = date => {
    setEndDate(date);
    getReportFromAPI();
  };

  const pickType = async type => {
    setCurrentType(type);
    getReportFromAPI(type);
  };

  const horizontalMenuButtons = [
    { type: aggBy.year, label: 'Rocznie' },
    { type: aggBy.quarter, label: 'Kwartalnie' },
    { type: aggBy.month, label: 'Miesięcznie' },
  ];

  const evidencesOptions =
    data?.evidences?.map(el => ({
      value: el.id,
      label: el.displayName,
    })) || [];

  return (
    <>
      {title.includes('Zużycie') && (
        <ExportButton fileName='Raport' data={data.report} />
      )}
      {!simple && (
        <Select
          styles={{
            ...S.customSelectStyles,
            container: provided => ({
              ...provided,
              maxWidth: '350px',
              minWidth: '350px',
            }),
          }}
          onChange={values => getReportFromAPI(null, values.value)}
          placeholder='Wszystkie numery ewidencyjne'
          options={[{ value: '', label: 'Wszystkie' }, ...evidencesOptions]}
        />
      )}
      <S.ChartsWrapper>
        {!simple && (
          <S.Header>
            <S.ChartTitle>{title}</S.ChartTitle>
            <S.DataPickerWrapper>
              <DataPicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={pickFilter}
                fieldName='chart'
              />
            </S.DataPickerWrapper>
            <S.Menu>
              <HorizontalMenuSwitch
                buttons={horizontalMenuButtons}
                onClick={pickType}
                currentType={currentType}
              />
            </S.Menu>
          </S.Header>
        )}
        <S.WrapperChart
          ref={el => {
            if (!el) return;
            container = el;
          }}
        >
          {width && height && (
            <Charts
              data={dataProps || data.report}
              elements={elements}
              hiddenAppearance
              hiddenVisibles
              hiddenTypeOfChart
              defaultVisibles={visibleData}
              deafultAppearance={appearanceData}
              width={width}
              height={height}
              tooltipContent={CustomTooltip}
            />
          )}
        </S.WrapperChart>
      </S.ChartsWrapper>
    </>
  );
};

export default Chart;
