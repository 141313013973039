import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import LoginePuap from './LoginePuap';
import allActions from 'actions';
import { toast } from 'react-toastify';

const Login = ({ ...rest }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const [isLogin, setIsLogin] = useState(auth.isAuthenticated);
  const [redirectToForm, setRedirectToForm] = useState(false);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const error = new URLSearchParams(search).get('error');
  const newUser = new URLSearchParams(search).get('newUser');

  useEffect(() => {
    if (token) {
      dispatch(allActions.authActions.setToken(token));
    }

    if (error) toast.error(`${error}`);

    if (newUser === 'true' || newUser === true) setRedirectToForm(true);
  }, []);

  useEffect(() => {
    setIsLogin(auth.isAuthenticated);
  }, [auth.isAuthenticated]);

  if (redirectToForm) return <Redirect to='/auth/signup-form' />;

  if (isLogin) {
    return <Redirect to='/' />;
  }

  return <LoginePuap {...rest} />;
};

export default Login;
