import { SET_CONVERSATIONS } from 'actions/communicator.action';

const INITIAL_STATE = {
  conversations: [],
};

const communicatorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONVERSATIONS:
      return { ...state, conversations: action.item };

    default:
      return state;
  }
};

export default communicatorReducer;
