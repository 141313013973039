import React from 'react';
import chroma from 'chroma-js';

import InputUserDetails from 'components/atoms/InputUserDetails';
import theme from 'styles/theme';
import { statusValues, statusNames } from '../../constans';

export const formFieldsUser = isEditable => {
  return [
    {
      as: <InputUserDetails isEditable={isEditable} disabled />,
      name: 'name',
      label: 'Imię',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} disabled />,
      name: 'surname',
      label: 'Nazwisko',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'address',
      label: 'Adres',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'city',
      label: 'Miasto',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: (
        <InputUserDetails
          isEditable={isEditable}
          tooltip={`Numery należy wprowadzać po przecinku. Np: "54321, 4321, 2344"`}
        />
      ),
      name: 'evidence',
      label: 'Numer ewidencyjny',
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} disabled />,
      name: 'id',
      label: 'Identyfikator',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} disabled />,
      name: 'email',
      label: 'E-mail',
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'phone',
      label: 'Telefon',
      type: 'text',
    },
  ];
};

// =============
// ACCOUNT SECTION

export const statusOptions = [
  {
    value: statusValues.ACTIVE,
    label: statusNames.ACTIVE,
    color: theme.ACTIVE,
  },
  {
    value: statusValues.NOT_ACTIVE,
    label: statusNames.NOT_ACTIVE,
    color: theme.NOT_ACTIVE,
  },
  {
    value: statusValues.NOT_VERIFIED,
    label: statusNames.NOT_VERIFIED,
    color: theme.NOT_VERIFIED,
  },
];

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  singleValue: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
};

export const formFieldsAccount = (isEditable, rolesOptions) => [
  {
    as: (
      <InputUserDetails
        isEditable={isEditable}
        styles={colourStyles}
        select
        options={statusOptions}
      />
    ),
    name: 'userStatus',
    label: 'Status konta',
    rules: { required: 'To pole jest wymagane' },
  },
  {
    as: (
      <InputUserDetails isEditable={isEditable} select options={rolesOptions} />
    ),
    name: 'role',
    label: 'rola',
    rules: { required: 'To pole jest wymagane' },
  },
];
