import React, { useEffect, useState } from 'react';
import MainWrapper from 'components/atoms/MainWrapper';
import { useHistory, useParams } from 'react-router-dom';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { menuItems } from './menuItems';
import styled from 'styled-components';

import UserData from 'components/admin/UserDetails/UserData/UserData';
import UserFactures from 'components/admin/UserDetails/UserFactures/UserFactures';
import UserReports from 'components/admin/UserDetails/UserReports/UserReports';
import UserLogs from 'components/admin/UserDetails/UserLogs/UserLogs';
import UserCommunicator from 'components/admin/UserDetails/UserCommunicator/UserCommunicator';
import UserConsumption from 'components/admin/UserDetails/UserConsumption/UserConsumption';
import { apiEndpoints, sendRequest } from 'API';
import Indicator from 'components/atoms/Indicator';

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
  }
`;

const Avatar = styled.div`
  background-color: rgba(0, 4, 40, 0.65);
  color: white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  left: 0px;
  top: 0px;
  text-align: center;
  margin-right: 15px;
  font-weight: bold;

  p {
    font-size: 18px;
    line-height: 40px;
  }
`;

const AppBarStyled = styled(AppBar)`
  box-shadow: none !important;
  max-width: 100vw;

  .MuiTab-root {
    width: auto;
    min-width: auto;
  }

  .MuiTab-root {
    color: ${({ theme }) => theme.titleLight};
  }

  .MuiTab-root.Mui-selected {
    color: ${({ theme }) => theme.primary};
  }
`;

const a11yProps = index => ({
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`,
});

const UserDetails = ({ location }) => {
  const { id } = useParams();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(location?.state?.index || 0);
  const [userData, setUserData] = useState(null);

  const handleChange = (event, newValue) => setCurrentTab(newValue);

  const getUserData = async () => {
    const { data } = await sendRequest.get(
      apiEndpoints.getUserDetailsByAdmin(id),
    );
    setUserData(data);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const renderMenuItems = items =>
    items.map((item, index) => (
      <Tab
        key={item}
        label={item}
        style={{ textTransform: 'capitalize' }}
        {...a11yProps(index)}
      />
    ));

  const renderView = () => {
    switch (currentTab) {
      case 0:
        return <UserData userData={userData} />;
      case 1:
        return <UserFactures />;
      case 2:
        return <UserConsumption />;
      case 3:
        return <UserReports />;
      case 4:
        return <UserCommunicator userData={userData} />;
      case 5:
        return <UserLogs />;

      default:
        break;
    }
  };

  const renderAdminView = () => {
    switch (currentTab) {
      case 0:
        return <UserData userData={userData} />;
      case 1:
        return <UserLogs />;

      default:
        break;
    }
  };

  return (
    <>
      {!userData ? (
        <Indicator />
      ) : (
        <MainWrapper>
          <Header>
            <ArrowBackIosIcon
              onClick={() => history.goBack()}
              style={{ cursor: 'pointer', marginRight: '20px' }}
            />
            <Avatar>
              <p>
                {userData?.name?.slice(0, 1)}
                {userData?.surname?.slice(0, 1)}
              </p>
            </Avatar>
            <h2>{`${userData?.name} ${userData?.surname}`}</h2>
          </Header>
          <AppBarStyled position='static' color='transparent'>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              indicatorColor='primary'
              variant='scrollable'
              scrollButtons='auto'
              aria-label='scrollable auto tabs example'
            >
              {renderMenuItems(
                userData.role === 'Admin' || userData.role === 'Urzędnik'
                  ? menuItems.ADMINS
                  : menuItems.INDIVIDUAL,
              )}
            </Tabs>
          </AppBarStyled>
          {userData.role === 'Admin' || userData.role === 'Urzędnik'
            ? renderAdminView()
            : renderView()}
        </MainWrapper>
      )}
    </>
  );
};

export default UserDetails;
