import MainWrapper from 'components/atoms/MainWrapper';
import React from 'react';
import DeleteAccountView from 'components/Shared/DeleteAccountSection/DeleteAccountView';

const DeleteUser = () => {
  return (
    <MainWrapper>
      <DeleteAccountView userType='admin' />
    </MainWrapper>
  );
};

export default DeleteUser;
