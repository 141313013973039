import React, { useEffect, useState } from 'react';
import { Button } from 'm-web-components';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { apiEndpoints, sendRequest } from 'API';
import Title from 'components/atoms/Title';
import Indicator from 'components/atoms/Indicator';
import MainWrapper from 'components/atoms/MainWrapper';
import PermissionsBookmark from 'components/admin/Permissions/PermissionsBookmark.js';
import CreateAdmin from 'components/admin/Permissions/CreateAdmin';
import AddRoleModal from 'components/admin/Permissions/AddRoleModal';
import { ButtonContainer } from 'components/admin/Permissions/Permissions.css';

import { sections } from 'components/admin/Permissions/permissions.constants';
import { useSelector } from 'react-redux';

const AppBarStyled = styled(AppBar)`
  box-shadow: none !important;
  max-width: 100vw;

  .MuiTab-root {
    width: auto;
    min-width: auto;
  }

  .MuiTab-root {
    color: ${({ theme }) => theme.titleLight};
  }

  .MuiTab-root.Mui-selected {
    color: ${({ theme }) => theme.primary};
  }
`;

const a11yProps = index => ({
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`,
});

const UserDetails = () => {
  const { user } = useSelector(state => state.auth);

  const [currentTab, setCurrentTab] = useState(0);
  const [menuItems, setMenuItems] = useState([]);
  const [roleData, setRoleData] = useState(null);
  const [superRoles, setSuperRoles] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddNewAdminOpen, setIsAddNewAdminOpen] = useState(false);

  useEffect(() => {
    getRoles();
    getRolesSuper();
  }, []);

  useEffect(() => {
    if (menuItems[currentTab]?.roleId !== undefined) {
      getRoleData();
    }
  }, [menuItems, currentTab]);

  const handleChange = (event, newValue) => setCurrentTab(newValue);

  const toggleModal = () => {
    setIsModalOpen(prev => !prev);
  };

  const toggleAddAdmin = () => {
    setIsAddNewAdminOpen(prev => !prev);
  };

  const getRoleData = async () => {
    const { data } = await sendRequest.get(
      apiEndpoints.getRolePermissions(menuItems[currentTab]?.roleId),
    );
    setRoleData(data);
  };

  const getRoles = async () => {
    const { data } = await sendRequest.get(apiEndpoints.getRole);
    setMenuItems(data);
  };

  const getRolesSuper = async () => {
    const { data } = await sendRequest.get(apiEndpoints.getRolesSuper);
    setSuperRoles(data);
  };

  const renderMenuItems = menuItems?.map((item, index) => {
    return (
      <Tab
        key={item.name}
        label={item.name}
        style={{ textTransform: 'capitalize' }}
        {...a11yProps(index)}
      />
    );
  });

  return (
    <>
      {!roleData ? (
        <Indicator />
      ) : (
        <MainWrapper>
          <AddRoleModal
            superRoles={superRoles}
            isModalOpen={isModalOpen}
            toggleModal={toggleModal}
            getRoles={getRoles}
          />
          {isAddNewAdminOpen ? (
            <CreateAdmin toggleAddAdmin={toggleAddAdmin} />
          ) : (
            <>
              <Title>Uprawnienia</Title>
              {user.permissions.includes('ADD_ROLE') && (
                <ButtonContainer>
                  <Button
                    type='button'
                    colorType='primary'
                    padding='5px 30px'
                    margin='auto 10px auto auto'
                    onClick={toggleAddAdmin}
                  >
                    + Dodaj administratora
                  </Button>
                  <Button
                    type='button'
                    colorType='primary'
                    padding='5px 30px'
                    onClick={toggleModal}
                  >
                    + Dodaj nową rolę
                  </Button>
                </ButtonContainer>
              )}
              <AppBarStyled position='static' color='transparent'>
                <Tabs
                  value={currentTab}
                  onChange={handleChange}
                  indicatorColor='primary'
                  variant='scrollable'
                  scrollButtons='auto'
                  aria-label='scrollable auto tabs example'
                >
                  {renderMenuItems}
                </Tabs>
              </AppBarStyled>
              <PermissionsBookmark
                roleId={menuItems[currentTab]?.roleId}
                roleData={roleData}
                sections={sections[menuItems[currentTab]?.baseRole]}
                getRoleData={getRoleData}
                nameRole={menuItems[currentTab]?.name}
                getRoles={getRoles}
              />
            </>
          )}
        </MainWrapper>
      )}
    </>
  );
};

export default UserDetails;
