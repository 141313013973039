import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'm-web-components';

import {
  SettingsSection,
  SettingsSectionHeader,
  ButtonSecondary,
} from 'components/Shared/Settings/Settings.css';

const Section = ({
  title,
  isEditable,
  toggleIsEditable,
  handleSave,
  children,
  style,
  editPermissions,
}) => {
  const { user } = useSelector(state => state.auth);
  return (
    <SettingsSection style={style}>
      <SettingsSectionHeader>
        <h2>{title}</h2>
        {user?.permissions?.includes(editPermissions) && (
          <>
            {isEditable ? (
              <>
                <ButtonSecondary type='button' onClick={toggleIsEditable}>
                  Anuluj
                </ButtonSecondary>
                <Button
                  type='button'
                  colorType='primary'
                  onClick={handleSave}
                  padding='5px 20px'
                >
                  Zapisz
                </Button>
              </>
            ) : (
              <Button
                type='button'
                colorType='primary'
                onClick={toggleIsEditable}
                padding='5px 20px'
              >
                Edytuj
              </Button>
            )}
          </>
        )}
      </SettingsSectionHeader>
      {children}
    </SettingsSection>
  );
};

export default Section;
