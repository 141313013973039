import styled, { css } from 'styled-components';
import { device } from 'styles/devices';

export const FiltersContainer = styled.div`
  position: relative;
  margin-top: 5px;
`;

export const FiltersButtonToggle = styled.button`
  border: 1px solid #f2f2f2;
  box-shadow: 1px 1px 15px rgba(0, 4, 40, 0.03);
  border-radius: 4px;
  background-color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;

  svg {
    margin-left: 10px;
  }
`;

export const FiltersStyled = styled.form`
  z-index: 1;

  display: block;
  position: absolute;
  background-color: white;
  width: 300px;
  padding: 20px;
  border: 1px solid #f2f2f2;
  box-shadow: 2px 2px 20px rgba(0, 4, 40, 0.08);
  border-radius: 4px;

  max-height: ${({ openFilter }) => (openFilter ? 'auto' : 0)};
  /* overflow: hidden; */
  transition: 0.2s;
  transform: ${({ openFilter }) => (openFilter ? 'scale(1)' : 'scale(0)')}
    translateX(-67%);
  opacity: ${({ openFilter }) => (openFilter ? '1' : '0')};

  ${scroll}

  .nice-dates-popover {
    transform: translateX(-20px);
  }

  .nice-dates-grid {
    background-color: white;
    width: 300px;
  }

  .nice-dates-day::before {
    background-color: ${({ theme }) => theme.primary};
    color: white;
  }

  ${({ right }) =>
    right &&
    css`
      transform: ${({ openFilter }) => (openFilter ? 'scale(1)' : 'scale(0)')}
        translateX(${({ title }) => (title === 'Sortuj' ? '-55px' : '-200px')});

      @media ${device.tablet} {
        transform: ${({ openFilter }) => (openFilter ? 'scale(1)' : 'scale(0)')}
          translateX(10px);
      }
    `}

  ${({ title }) =>
    title === 'Sortuj' &&
    css`
      width: 250px;
      padding: 0;

      button {
        border: none;
        background-color: white;
        width: 100%;
        padding: 10px;
        transition: 0.2s;

        cursor: pointer;

        :hover {
          background-color: #e9e9e9;
        }
      }
    `}
`;
