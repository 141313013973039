export const columns = {
  INDIVIDUAL: [
    { title: 'Identyfikator' },
    { title: 'Numer ewidencyjny' },
    { title: 'Imię', fieldName: 'name', sorting: true },
    { title: 'Nazwisko', fieldName: 'surname', sorting: true },
    { title: 'Adres', fieldName: 'address', sorting: true },
    { title: 'Miejscowość', fieldName: 'city', sorting: true },
    { title: 'Status konta', fieldName: 'status', sorting: true },
    { title: '' },
    { title: '' },
  ],
  BUSINESS: [
    { title: 'Identyfikator' },
    { title: 'Numer ewidencyjny' },
    { title: 'Nazwa firmy' },
    { title: 'Imię', fieldName: 'name', sorting: true },
    { title: 'Nazwisko', fieldName: 'surname', sorting: true },
    { title: 'Adres', fieldName: 'address', sorting: true },
    { title: 'Miejscowość', fieldName: 'city', sorting: true },
    { title: 'Status konta', fieldName: 'status', sorting: true },
    { title: '' },
    { title: '' },
  ],
  FUNCTIONARY: [
    { title: 'Identyfikator' },
    { title: 'Imię', fieldName: 'name', sorting: true },
    { title: 'Nazwisko', fieldName: 'surname', sorting: true },
    { title: 'Adres', fieldName: 'address', sorting: true },
    { title: 'Miejscowość', fieldName: 'city', sorting: true },
    { title: 'Status konta', fieldName: 'status', sorting: true },
    { title: '' },
    { title: '' },
  ],
  ADMIN: [
    { title: 'Identyfikator' },
    { title: 'Imię', fieldName: 'name', sorting: true },
    { title: 'Nazwisko', fieldName: 'surname', sorting: true },
    { title: 'Adres', fieldName: 'address', sorting: true },
    { title: 'Miejscowość', fieldName: 'city', sorting: true },
    { title: 'Status konta', fieldName: 'status', sorting: true },
    { title: '' },
    { title: '' },
  ],
};
