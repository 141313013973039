import React from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { Button } from 'm-web-components';
import { modalStyle } from '../Shared/ModalStyles/ModalStyles';
import { apiEndpoints, sendRequest } from 'API';
import {
  DeleteModalContainer,
  ButtonSecondary,
  ButtonsContainer,
} from 'components/ImportedFiles/ImportedFiles.css';

import CloseX from 'assets/icons/close_X.svg';

Modal.setAppElement('#root');

const ModalDeleteFile = ({
  isDeleteModalOpen,
  toggleDeleteModal,
  getValues,
  fileToDelete,
  setFileToDelete,
}) => {
  const handleDeleteFileSubmit = () => {
    const deleteFile = async () => {
      try {
        await sendRequest.delete(apiEndpoints.deleteFile(fileToDelete.id));
        toast.success(`Plik ${fileToDelete.name} został usunięty`);
        getValues();
      } catch (e) {
        toast.error('Wystąpił błąd podczas usuwania pliku');
      }
    };
    deleteFile();
    setFileToDelete({});
    toggleDeleteModal();
  };

  return (
    <Modal
      isOpen={isDeleteModalOpen}
      contentLabel='Usuwanie pliku'
      onRequestClose={toggleDeleteModal}
      style={modalStyle}
    >
      <img src={CloseX} onClick={toggleDeleteModal} />
      <DeleteModalContainer>
        <h2>Czy na pewno chcesz usunąć plik: {fileToDelete.name}?</h2>
        <p>
          Plik zostanie trwale usunięty. Nie będzie możliwości przywrócenia go.
        </p>
        <ButtonsContainer>
          <ButtonSecondary onClick={toggleDeleteModal} type='button'>
            Anuluj
          </ButtonSecondary>
          <Button
            onClick={handleDeleteFileSubmit}
            type='button'
            colorType='primary'
          >
            Usuń
          </Button>
        </ButtonsContainer>
      </DeleteModalContainer>
    </Modal>
  );
};

export default ModalDeleteFile;
