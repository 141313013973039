import React from 'react';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Table from 'components/tables/Table';
import AdminUsersTableRow from 'components/admin/users/AdminUsersTableRow';

const HomeAdminNewUsersTable = ({ dataTable = [], simple }) => {
  const columns = [
    { title: 'Identyfikator' },
    { title: 'Imię' },
    { title: 'Nazwisko' },
    { title: 'Status konta' },
    { title: '' },
  ];

  const rows = dataTable?.map(row => (
    <AdminUsersTableRow key={row.id} row={row} simple />
  ));

  return <Table simple={simple} columns={columns} rows={rows} />;
};

export default HomeAdminNewUsersTable;
