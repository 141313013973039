import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StatusContainer = styled.div`
  display: inline-block;
  border: 1px solid ${({ theme, color }) => theme[color]};
  color: ${({ theme, color }) => theme[color]};
  width: auto;
  border-radius: 4px;
  padding: 2px 5px;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.primaryLight};
  text-decoration: underline;
  transition: 0.3s;

  &:hover {
    text-decoration: underline;
    padding: 0;
    color: ${({ theme }) => theme.light};
  }
`;

export const MenuLink = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px 20px;
`;
