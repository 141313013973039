import {
  SET_CURRENT_PAGE_CONSUMPTIONS,
  SET_EVIDENCES_CONSUMPTIONS,
} from 'actions/consumption.action';

const INITIAL_STATE = {
  result: [],
  evidences: [],
};

const consumptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE_CONSUMPTIONS:
      return { ...state, result: action.item };

    case SET_EVIDENCES_CONSUMPTIONS:
      return { ...state, evidences: action.item };

    default:
      return state;
  }
};

export default consumptionReducer;
