import React from 'react';

import * as S from './Home.css';

const SingleTail = ({ data, title, admin }) => {
  return (
    <S.WrapperTail>
      <S.Title>{title}</S.Title>
      <S.Value>
        {data}
        {!admin && <sup>2</sup>}
      </S.Value>
    </S.WrapperTail>
  );
};

export default SingleTail;
