import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import allActions from 'actions';
import { useDispatch, useSelector } from 'react-redux';

import InvoicesTable from 'components/Invoices/InvoicesTable';

const UserFactures = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { result: dataTable } = useSelector(state => state.factures);

  const getValues = (type, newValue, category = 'admin/') => {
    dispatch(
      allActions.facturesActions.facturesGetAction(type, newValue, category),
    );
  };

  useEffect(() => {
    getValues('factures', { uId: id }, 'admin/');
  }, []);

  return (
    <InvoicesTable
      getValues={getValues}
      dataTable={dataTable}
      category='admin/'
    />
  );
};

export default UserFactures;
