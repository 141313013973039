import React from 'react';
import { NavLink, Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  SideMenuItem,
  SideMenuItemsWrapper,
  SideMenuSeparator,
} from 'layouts/MainLayout/MainLayout.css';

import {
  userRoutesSet,
  adminRoutesSet,
  userSecondaryRoutesSet,
  adminSecondaryRoutesSet,
  adminManagementRoutesSet,
} from './SideMenuRoutes';
import Indicator from 'components/atoms/Indicator';

const SideMenuLinks = ({ closeHamburger }) => {
  const { pathname } = useLocation();
  const { user } = useSelector(state => state.auth);

  const renderMenuItems = items =>
    items
      .filter(el => {
        const found = el.permissions.some(r => user.permissions?.includes(r));
        if (found) return el;
      })
      .map(el => (
        <SideMenuItem key={el.text} onClick={closeHamburger}>
          <NavLink
            to={el.to}
            className={pathname === el.to ? 'active' : ''}
            exact
          >
            <img src={el.src} alt={el.alt} />
            {el.text}
          </NavLink>
        </SideMenuItem>
      ));

  return (
    <>
      {!user ? (
        <Indicator />
      ) : (
        <SideMenuItemsWrapper>
          <Switch>
            <Route path='/(user)/'>{renderMenuItems(userRoutesSet)}</Route>

            <Route path='/(admin)/'>
              {renderMenuItems(
                adminRoutesSet(
                  user?.userDetails?.role === 'Admin'
                    ? 'Administratora'
                    : 'Urzędnika',
                ),
              )}
            </Route>
          </Switch>

          <SideMenuSeparator>OPŁATY</SideMenuSeparator>
          <Switch>
            <Route path='/(user)/'>
              {renderMenuItems(userSecondaryRoutesSet)}
            </Route>

            <Route path='/(admin)/'>
              {renderMenuItems(adminSecondaryRoutesSet)}
            </Route>
          </Switch>

          <Switch>
            <Route path='/(admin)/'>
              <SideMenuSeparator>ZARZĄDZANIE</SideMenuSeparator>
              {renderMenuItems(adminManagementRoutesSet)}
            </Route>
          </Switch>
        </SideMenuItemsWrapper>
      )}
    </>
  );
};

export default SideMenuLinks;
