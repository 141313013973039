import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Indicator from 'components/atoms/Indicator';
import CommunicatorComponent from 'Communicator/CommunicatorComponent';

import { apiEndpoints, sendRequest, sendRequestBlob } from 'API';
import { role } from 'auth/auth';
import allActions from 'actions';

const CommunicatorWrapper = styled.div`
  background-color: #ccc;
  color: #1d1c29;
  width: 100%;
  height: 100%;
  max-height: 100%;
`;

const Communicator = ({ userData }) => {
  const [isNewsletter, setIsNewsletter] = useState(false);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [receiverOptions, setReceiverOptions] = useState([]);
  const [attachmentInfo, setAttachmentInfo] = useState({});
  const [sendOptions, setSendOptions] = useState({
    userId: null,
    categoryId: null,
    file: [],
  });

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const { conversations } = useSelector(state => state.communicator);
  const topic = useSelector(state => state.topic);
  const { currentConversation } = useSelector(
    state => state.currentConversation,
  );

  /* Attachment data */

  const getAttachmentInfo = async () => {
    try {
      const { data } = await sendRequest.get(apiEndpoints.getAttachmentInfo);
      setAttachmentInfo(data);
    } catch (e) {
      toast.error('Wystąpił problem z pobieraniem informacji z serwera.');
    }
  };

  /* Fetching conversations data */

  const getData = () => {
    dispatch(
      allActions.communicatorActions.conversationsGetAction(
        userData && { uId: userData.id },
      ),
    );
  };

  const getCurrentConversation = id => {
    if (conversations !== undefined) {
      dispatch(
        allActions.currentConversationActions.getCurrentConversationAction(id),
      );
    }
  };

  const getConversation = selected => {
    if (selected === 'Newsletter') {
      setIsNewsletter(true);
    } else if (selected !== null && selected !== 0) {
      const index = conversations[selected - 1]?.conversationId;
      getCurrentConversation(index);
      setIsNewsletter(false);
    } else {
      getCurrentConversation(null);
      setIsNewsletter(false);
    }
  };

  const getAttachment = (id, fileName) => {
    const get = async () => {
      const { data } = await sendRequestBlob.get(
        apiEndpoints.getAttachment(id),
      );
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/octet-stream',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        link.parentNode.removeChild(link);
      }, 100);
    };
    get();
  };

  const setNewsletterMessages = () => {
    dispatch(
      allActions.currentConversationActions.getCurrentConversationAction(0),
    );
  };

  /* Fetching receivers data */

  const getUsers = async () => {
    if (userData) {
      setReceiverOptions([
        { name: `${userData.name} ${userData.surname}`, id: userData.id },
      ]);
    } else {
      const { data } = await sendRequest.get(apiEndpoints.getReceivers);
      setReceiverOptions(data);
    }
  };

  /* Fetching and updating topic data */

  const getTopics = async () => {
    const { data } = await sendRequest.get(apiEndpoints.getCategory);
    dispatch(allActions.chatTopicActions.setTopic(data));
  };

  const updateTopic = async topic => {
    try {
      await sendRequest.put(apiEndpoints.putUpdateCategory(topic.id), {
        name: topic.name,
      });
      getTopics();
    } catch (e) {
      toast.error(`Nie udało się zaktualizować tematu: ${topic.name}.
       ${e.response?.data}`);
    }
  };

  const addNewTopic = async topic => {
    try {
      await sendRequest.post(apiEndpoints.postNewCategory, {
        name: topic.name,
      });
      getTopics();
    } catch (e) {
      toast.error(
        `Nie udało się dodać nowego tematu: ${topic.name}. ${e.response?.data}`,
      );
    }
  };

  const handleSaveTopics = topics => {
    topics.forEach(topic => {
      if (topic.id === null) {
        addNewTopic(topic);
      } else {
        updateTopic(topic);
      }
    });
  };

  const handleDeleteTopic = async topicId => {
    try {
      await sendRequest.put(apiEndpoints.putDeactivateCategory(topicId));
      toast.success('Temat został usunięty.');
      getTopics();
    } catch (e) {
      toast.error('Nie udało się usunąć tematu.');
    }
  };

  /* Sending messages */

  const handleSendClick = messageText => {
    if (messageText === '' || messageText === null) {
      toast.info('Proszę podać treść wiadomości');
    } else {
      sendMessage(currentConversation.conversationId, messageText);
    }
  };

  const handleNewConversationMessage = e => {
    if (
      e === '' ||
      sendOptions.userId === null ||
      sendOptions.categoryId === null
    ) {
      toast.info('Proszę podać odbiorcę, temat i treść wiadomości');
    } else {
      const createNewConversation = async () => {
        const { data } = await sendRequest.post(
          apiEndpoints.postNewChat,
          sendOptions,
        );

        sendMessage(data.conversationId, e);
      };
      createNewConversation();
    }
  };

  const sendMessage = (conversationId, content) => {
    const formData = new FormData();
    formData.append('content', content);
    sendOptions.file?.forEach(file => {
      formData.append('file', file);
    });

    const sendNewMessage = async () => {
      try {
        setIsMessageSending(true);
        let id = conversationId;
        if (isNewsletter) {
          id = 0;
        }
        await sendRequest.put(apiEndpoints.putSendMessage(id), formData);
        getData();
      } catch (e) {
        toast.error('Nie udało się wysłać wiadomości.');
      } finally {
        setIsMessageSending(false);
      }
    };
    sendNewMessage();
  };

  useEffect(() => {
    getAttachmentInfo();

    getTopics();

    getUsers();

    getData();

    // clear current message
    return () => {
      getCurrentConversation(null);
      dispatch(
        allActions.communicatorActions.conversationsGetAction({
          clear: 'clear',
        }),
      );
    };
  }, []);

  return (
    <>
      {!topic || isMessageSending ? (
        <Indicator />
      ) : (
        <CommunicatorWrapper userData={userData}>
          <CommunicatorComponent
            handleSendClick={handleSendClick}
            topicOptions={topic}
            currentConversation={currentConversation}
            getConversation={getConversation}
            handleNewConversationMessage={handleNewConversationMessage}
            receiverOptions={receiverOptions}
            setSendOptions={setSendOptions}
            getAttachment={getAttachment}
            isAdmin={auth.user?.userDetails.role !== role.user}
            handleSaveTopics={handleSaveTopics}
            handleDeleteTopic={handleDeleteTopic}
            setNewsletterMessages={setNewsletterMessages}
            attachmentInfo={attachmentInfo}
          />
        </CommunicatorWrapper>
      )}
    </>
  );
};

export default Communicator;
