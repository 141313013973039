import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SignupPuap from './SignupPuap';

const Signup = () => {
  const auth = useSelector(state => state.auth);

  if (auth.isAuthenticated) {
    return <Redirect to='/' />;
  }

  return <SignupPuap />;
};

export default Signup;
