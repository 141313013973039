import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import theme from 'styles/theme';

import { Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Table from 'components/tables/Table';
import { usersTypes } from 'components/admin/constans';

const systemLogsFilters = [
  {
    type: 'dataPicker',
    name: 'UploadDate',
    nameTable: 'systemLogs',
    label: 'Data wydarzenia',
  },
  {
    type: 'list',
    name: 'userTypes',
    nameTable: 'systemLogs',
    label: 'Użytkownik',
    checkboxes: [
      { id: usersTypes.USER, name: 'Klient' },
      { id: usersTypes.FUNCTIONARY, name: 'Urzędnik' },
      { id: usersTypes.ADMINS, name: 'Admin' },
    ],
  },
  {
    type: 'list',
    name: 'logTypes',
    nameTable: 'systemLogs',
    label: 'Temat',
    checkboxes: [
      { name: 'Edycja', id: 'EDIT' },
      { name: 'Informacja', id: 'INFO' },
      { name: 'Usuwanie', id: 'DELETE' },
    ],
  },
];

const typeNames = {
  INFO: 'Informacja',
  DELETE: 'Usuwanie',
  EDIT: 'Edycja',
};

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.primaryLight};
  text-decoration: underline;
  transition: 0.3s;
  padding: 5px 20px;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.light};
  }
`;

const LogsTable = ({ dataTable = [], getValues, category }) => {
  const columns = [
    { title: 'Identyfikator' },
    { title: 'Data i godzina', fieldName: 'date', sorting: true },
    { title: 'Użytkownik' },
    { title: 'Temat' },
    { title: 'Wydarzenie' },
  ];

  const rows = dataTable?.map(row => (
    <Tr key={row.id}>
      <StyledLink to={`/admin/users/${row.userId}`}>{row.userId}</StyledLink>
      <Td>{row.date}</Td>
      <Td>{row.role}</Td>
      <Td style={{ display: 'flex', alignItem: 'center' }}>
        {row.type === 'EDIT' ? (
          <ReportProblemOutlinedIcon
            style={{ color: theme[row.type], marginRight: '10px' }}
          />
        ) : (
          <InfoOutlinedIcon
            style={{ color: theme[row.type], marginRight: '10px' }}
          />
        )}
        {typeNames[row.type]}
      </Td>
      <Td>{row.event}</Td>
    </Tr>
  ));

  return (
    <Table
      columns={columns}
      rows={rows}
      type='logs'
      getValues={getValues}
      searchPlaceholder='Wyszukaj logi'
      filtersTable={systemLogsFilters}
      category={category}
    />
  );
};

export default LogsTable;
