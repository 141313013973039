import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Indicator from 'components/atoms/Indicator';
import allActions from 'actions';

import { role } from 'auth/auth';
import { routes } from 'routes';

const Home = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(allActions.authActions.getCurrentUserAction());
  }, []);

  if (!user) {
    return <Indicator bacgroundColor='white' />;
  }

  return (
    <Redirect
      to={
        user.userDetails.role === role.user
          ? routes.user.home
          : routes.admin.home
      }
    />
  );
};
export default Home;
