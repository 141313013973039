export const SET_TOPIC = 'SET_TOPIC';

const setTopic = item => ({
  type: SET_TOPIC,
  item,
});

export default {
  setTopic,
};
