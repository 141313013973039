import React from 'react';

import InputUserDetails from 'components/atoms/InputUserDetails';

export const eBOKDataInputs = isEditable => {
  return [
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'companyName',
      label: 'Nazwa firmy',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'nip',
      label: 'NIP',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'address',
      label: 'Adres',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'city',
      label: 'Miejscowość',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'bankAccountNumber',
      label: 'Numer konta',
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'paymentDeadline',
      label: 'Termin płatności (dni)',
      type: 'number',
    },
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'nameAndSurname',
      label: 'Imię i nazwisko',
      type: 'text',
    },
  ];
};

export const pkwiuDataInputs = isEditable => {
  return [
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'water',
      label: 'Woda',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'sewage',
      label: 'Ścieki',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'subscriptionPayment',
      label: 'Opłata abonamentowa',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'channelSubscriptionPayment',
      label: 'Opłata kanalizacyjna',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
  ];
};
