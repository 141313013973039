import React from 'react';
import Modal from 'react-modal';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Button } from 'm-web-components';
import { baseStyle, modalStyle } from '../Shared/ModalStyles/ModalStyles';
import { apiEndpoints, sendRequestFormData } from 'API';

import CloseX from 'assets/icons/close_X.svg';

import { ErrorMessage } from 'components/ImportedFiles/ImportedFiles.css';

Modal.setAppElement('#root');

const ModalAddFile = ({ isModalOpen, toggleModal, getValues }) => {
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept: '.xml',
  });

  const handleAddFileSubmit = () => {
    const formData = new FormData();
    formData.append('test', 'test');
    acceptedFiles?.forEach(file => {
      const blob = new Blob([file], { type: 'application/xml' });
      formData.append('file', blob, file.name);
    });

    const addFiles = async () => {
      try {
        await sendRequestFormData.post(apiEndpoints.postAddFile, formData);
        toast.success(`Pliki zostały dodane poprawnie`);
      } catch (e) {
        toast.error(
          `Wystąpił błąd podczas dodawania pliku. 
              Upewnij się, czy podany plik jest poprawny, bądź nie został już wcześniej dodany.`,
        );
      }
      getValues();
    };
    addFiles();
    toggleModal();
  };

  const acceptedFilesItems = acceptedFiles.map(file => (
    <li key={file.path}>{file.path}</li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path}
      {errors.map(e => (
        <ErrorMessage key={e.code}>
          {e.code === 'file-invalid-type' ? 'Błędny format pliku.' : e.code}
        </ErrorMessage>
      ))}
    </li>
  ));

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel='Załącznik'
      onRequestClose={toggleModal}
      style={modalStyle}
    >
      <img src={CloseX} onClick={toggleModal} />
      <div {...getRootProps({ style: baseStyle })}>
        <input {...getInputProps()} />
        <p>(Naciśnij, aby wybrać pliki)</p>
      </div>
      <br />
      <aside>
        <h4>Zaakceptowane pliki:</h4>
        <ul>{acceptedFilesItems}</ul>
        <h4>Odrzucone pliki:</h4>
        <ul>{fileRejectionItems}</ul>
      </aside>
      <br />
      <Button
        onClick={handleAddFileSubmit}
        type='button'
        colorType='primary'
        margin='auto'
      >
        Dodaj zaakceptowane pliki
      </Button>
    </Modal>
  );
};

export default ModalAddFile;
