import { combineReducers } from 'redux';
import authReducer from './auth.reducer';
import paginationReducer from './pagination.reducer';
import facturesReducer from './factures.reducer';
import communicatorReducer from './communicator.reducer';
import currentConversation from './currentConversation.reducer';
import filtersTableReducer from './filtersTable.reducer';
import usersReducer from './users.reducer';
import filesReducer from './files.reducer';
import topicReducer from './chatTopic.reducer';
import consumptionReducer from './consumption.reducer';
import logsReducer from './logs.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  pagination: paginationReducer,
  factures: facturesReducer,
  communicator: communicatorReducer,
  currentConversation: currentConversation,
  filtersTable: filtersTableReducer,
  users: usersReducer,
  files: filesReducer,
  topic: topicReducer,
  consumption: consumptionReducer,
  logs: logsReducer,
});

export default rootReducer;
