import React, { useEffect, useState } from 'react';
import { Form } from 'm-web-components';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import allActions from 'actions';
import { Input } from 'components/atoms/Input';

const LoginAdmin = () => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const [isLogin, setIsLogin] = useState(auth.isAuthenticated);

  useEffect(() => {
    setIsLogin(auth.isAuthenticated);
  }, [auth.isAuthenticated]);

  const handleSubmit = async data => {
    dispatch(allActions.authActions.loginAction(data));
  };

  if (isLogin) {
    return <Redirect to='/' />;
  }

  const formFields = [
    {
      as: <Input variant='outlined' />,
      name: 'username',
      label: 'Login',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <Input variant='outlined' />,
      name: 'password',
      label: 'Hasło',
      rules: { required: 'To pole jest wymagane' },
      type: 'password',
    },
  ];

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        formFields={formFields}
        labelSubmit='Zaloguj'
        center
        buttonProps={{
          colorType: 'loginAdmin',
          padding: '10px 50px',
        }}
      />
    </>
  );
};

export default LoginAdmin;
