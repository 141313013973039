import React from 'react';
import { useDispatch } from 'react-redux';
import { Search } from 'm-web-components';

import SearchIcon from '@material-ui/icons/Search';
import allActions from 'actions';
import * as S from './Table.css';
import styled from 'styled-components';

const Button = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const SearchTable = ({ searchPlaceholder, type, category }) => {
  const dispatch = useDispatch();

  const actionsName = `${type}Actions`;
  const getActionName = `${type}GetAction`;

  const onSubmitSearch = ({ search }) => {
    const data = { chIn: search };

    dispatch(allActions[actionsName][getActionName](type, data, category));
  };

  return (
    <S.SearchWrapper>
      <Search
        searchSubmit={onSubmitSearch}
        paddingInput='5px 10px'
        maxWidth='100%'
        buttonComponent={
          <Button type='submit'>
            <SearchIcon />
          </Button>
        }
        placeholder={searchPlaceholder}
      />
    </S.SearchWrapper>
  );
};

export default SearchTable;
