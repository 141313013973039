import styled from 'styled-components';
import { device } from 'styles/devices';
import theme from 'styles/theme';
import { Button } from 'm-web-components';

export const SettingsTitle = styled.h1`
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 20px;
`;

export const SettingsSection = styled.section`
  width: 100%;
  background-color: ${theme.settingsSection};
  box-shadow: 0px 0px 10px ${theme.shadowColor};
  padding-bottom: 40px;
  margin-bottom: 25px;
`;

export const SettingsDeleteSection = styled(SettingsSection)`
  padding-left: 40px;
  padding-right: 40px;

  @media ${device.desktop} {
    width: 50%;
  }
`;

export const ButtonSecondary = styled(Button)`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.primary};
  margin-left: auto;
  border-radius: 5px;
`;

export const ButtonDelete = styled(Button)`
  background-color: transparent;
  color: ${({ theme }) => theme.primary};
  margin-left: 25px;
  border-radius: 5px;

  &:hover {
    background-color: red;
    color: white;
  }
`;

export const ButtonCancel = styled(Button)`
  width: ${({ small }) => !small && '169px'};
  height: ${({ small }) => !small && '38px'};
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.primary};
  margin: auto;
  padding: ${({ small }) => small && '5px 25px'};
`;

export const SettingsSectionHeader = styled.div`
  width: 100%;
  height: 67px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 5px 5px -3px ${theme.shadowColor};

  h2 {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: auto;
    margin-top: auto;
  }

  > div {
    width: 100%;
    display: flex;
  }

  @media ${device.tablet} {
    padding: 15px 15px 15px 25px;
  }
`;

export const DeleteSectionHeader = styled(SettingsSectionHeader)`
  padding-left: 0px;

  img {
    max-width: 24px;
    margin-right: 15px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const SettinsSubsectionTitle = styled.h3`
  font-weight: 700;
  font-size: 18px;
  margin-left: 15px;

  @media ${device.tablet} {
    margin-left: 25px;
  }
`;

export const UserDataInfo = styled.div`
  margin-left: 15px;
  margin-right: 25px;
  width: 90%;
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: ${theme.settingsInfo};
  color: ${theme.textSecondaryColor};

  > img {
    margin-right: 10px;
  }

  @media ${device.tablet} {
    width: 70%;
    margin-left: 25px;
  }
`;

export const UserData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 15px;
  align-items: center;
  padding-right: 25px;

  &:last-child {
    margin-bottom: 25px;
  }

  h4 {
    height: 36px;
    min-width: 200px;
    color: #888794;
    font-size: 16px;
    margin: auto auto auto 0px;
    margin-left: 0px;
    padding: 10px 10px 10px 0px;
    text-transform: uppercase;

    @media ${device.tablet} {
      margin: auto 50px auto 0px;
    }
  }

  label {
    height: 36px;
    width: 100%;
    padding: 10px;
    margin-left: 0px;
    margin-right: auto;
    font-size: 16px;
    border-radius: 5px;

    background-color: ${({ isEditMode }) => isEditMode && theme.settingsLabel};
    color: ${({ isEditMode }) => isEditMode && theme.textSecondaryColor};

    @media ${device.tablet} {
      width: 70%;
      max-width: 360px;
    }
  }

  input {
    height: 36px;
    width: 100%;
    padding: 10px;
    margin-left: 0px;
    margin-right: auto;
    font-size: 16px;

    border: ${({ isEditMode }) => isEditMode === false && 'none'};

    &::placeholder {
      color: black;
    }

    @media ${device.tablet} {
      width: 70%;
      max-width: 360px;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;
    margin-left: 25px;
  }
`;

export const NotificationData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 15px;
  align-items: center;
  padding-right: 25px;

  h4 {
    height: 36px;
    min-width: 30%;
    font-size: 16px;
    font-weight: 400;
    margin: auto auto auto 0px;
    margin-left: 0px;
    padding: 10px 10px 10px 0px;

    @media ${device.tablet} {
      max-width: 30%;
      margin: auto 0px auto 0px;
    }
  }

  @media ${device.tablet} {
    margin-left: 25px;
  }
`;

export const DeleteAccountDescription = styled.div`
  width: 100%;
  padding: 25px;

  > h3 {
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
  }

  > p {
    margin: 0px;
    font-size: 16px;
    color: ${theme.textSecondaryColor};
  }
`;

export const CodeLabel = styled.div`
  max-width: 300px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  font-weight: 600;
  margin: auto;
  border-radius: 5px;
  border: 1px solid lightgray;

  @media ${device.desktop} {
    max-width: 380px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;
