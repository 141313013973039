import React from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'm-web-components';
import styled from 'styled-components';
import allActions from 'actions';

import SingleFilter from 'components/tables/Filters/SingleFilter';
import { formatDate } from 'helpers/newDateFormat';

const ButtonStyled = styled(Button)`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.primary};
  margin-left: auto;
  border-radius: 5px;
`;

const FiltersComunicator = ({ topicOptions }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const filtersValues = useSelector(
    state => state.filtersTable.userCommunicator,
  );

  const clearFilters = () => {
    dispatch(
      allActions.filtersTableActions.clearUserFiltersTable('userCommunicator'),
    );

    const params = {
      dFrom: '',
      dTo: '',
      ids: [],
      uId: id,
    };

    dispatch(allActions.communicatorActions.conversationsGetAction(params));
  };

  const onSubmit = () => {
    const params = {
      dFrom: filtersValues.dFrom ? formatDate(filtersValues.dFrom) : '',
      dTo: filtersValues.dTo ? formatDate(filtersValues.dTo) : '',
      ids: filtersValues.ids.length ? filtersValues.ids : '',
      uId: id,
    };

    dispatch(allActions.communicatorActions.conversationsGetAction(params));
  };

  const filtersTable = [
    {
      type: 'dataPicker',
      name: 'SendDate',
      nameTable: 'userCommunicator',
      label: 'Data wysłania',
    },
    {
      type: 'list',
      name: 'ids',
      nameTable: 'userCommunicator',
      label: 'Temat sprawy:',
      checkboxes: topicOptions,
    },
  ];

  return (
    <>
      {filtersTable.map(el => (
        <SingleFilter
          key={el.name}
          filterType={el.type}
          name={el.name}
          nameTable={el.nameTable}
          label={el.label}
          checkboxes={el.checkboxes}
        />
      ))}

      <div style={{ display: 'flex', marginTop: '20px' }}>
        <ButtonStyled
          type='button'
          onClick={clearFilters}
          margin='0'
          padding='5px 20px'
        >
          Wyczyść filtry
        </ButtonStyled>
        <Button
          type='button'
          colorType='primary'
          onClick={onSubmit}
          padding='5px 20px'
          margin='0'
        >
          Filtruj
        </Button>
      </div>
    </>
  );
};

export default FiltersComunicator;
