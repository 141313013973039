import paginationActions from 'actions/pagination.action';

export const SET_CURRENT_PAGE_FILES = 'SET_CURRENT_PAGE_FILES';

const setCurrentPageImportedFiles = item => ({
  type: SET_CURRENT_PAGE_FILES,
  item,
});

const filesGetAction = (type = 'files', newValue) => async dispatch => {
  const { content } = await dispatch(
    paginationActions.getResultsAction(type, newValue),
  );
  dispatch(setCurrentPageImportedFiles(content));
};

export default {
  setCurrentPageImportedFiles,
  filesGetAction,
};
