import React, { useState } from 'react';
import { Tr, Td } from 'react-super-responsive-table';
import styled from 'styled-components';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import * as S from './AdminUsersTable.css';

import { statusNames } from '../constans';

const Button = styled.button`
  color: ${({ theme }) => theme.primaryLight};
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  :hover {
    transform: scale(1.05);
  }

  svg {
    margin-right: 10px;
  }
`;

const AdminUsersTableRow = ({ row, simple, admin }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => setAnchorEl(null);

  const url = index => ({
    pathname: `/admin/users/${row.id}`,
    state: { index },
  });

  return (
    <Tr key={row.id}>
      <Td>
        <S.StyledLink to={`/admin/users/${row.id}`}>{row.id}</S.StyledLink>
      </Td>
      {!simple && !admin && <Td>{row.identifier?.replaceAll(',', ', ')}</Td>}
      {row.companyName && !simple && <Td>{row.companyName}</Td>}
      <Td>{row.name}</Td>
      <Td>{row.surname}</Td>
      {!simple && <Td>{row.adress}</Td>}
      {!simple && <Td>{row.city}</Td>}
      <Td>
        <S.StatusContainer color={row.status}>
          {statusNames[row.status]}
        </S.StatusContainer>{' '}
      </Td>
      <Td style={{ width: '50px' }}>
        <Button
          type='button'
          aria-controls='simple-menu'
          aria-haspopup='true'
          onClick={e => setAnchorEl(e.currentTarget)}
        >
          <MoreVertIcon style={{ color: '#888794' }} />
        </Button>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {row.status === 'NOT_VERIFIED' ? (
            <MenuItem style={{ padding: 0 }}>
              <S.MenuLink to={url(0)}>Zweryfikuj</S.MenuLink>
            </MenuItem>
          ) : (
            <>
              <MenuItem style={{ padding: 0 }}>
                <S.MenuLink to={url(0)}>Edytuj profil</S.MenuLink>
              </MenuItem>
              <MenuItem style={{ padding: 0 }}>
                <S.MenuLink to={url(1)}>Zobacz faktury</S.MenuLink>
              </MenuItem>
              <MenuItem style={{ padding: 0 }}>
                <S.MenuLink to={url(4)}>Napisz wiadomość</S.MenuLink>
              </MenuItem>
            </>
          )}
        </Menu>
      </Td>
    </Tr>
  );
};

export default AdminUsersTableRow;
