import React, { useState } from 'react';
import Modal from 'react-modal';

import { Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Table from 'components/tables/Table';

import Facture from 'components/Invoices/Facture/Facture';
import Indicator from 'components/atoms/Indicator';

import GetAppIcon from '@material-ui/icons/GetApp';
import styled from 'styled-components';

import { apiEndpoints, sendRequest } from 'API';

const CustomLink = styled.a`
  text-decoration: underline;
  color: blue;

  &:hover {
    cursor: pointer;
    color: darkblue;
  }
`;

const Button = styled.button`
  color: ${({ theme }) => theme.primaryLight};
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  :hover {
    transform: scale(1.05);
  }

  svg {
    margin-right: 10px;
  }
`;

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-33%, -50%)',
    maxWidth: '90%',
    maxHeight: '95%',
    position: 'relative',
    overlay: { zIndex: 1000 },
  },
};

const invoiceFiltersTable = [
  {
    type: 'dataPicker',
    name: 'CreationDate',
    nameTable: 'userFactures',
    label: 'Data wystawienia',
  },
  {
    type: 'dataPicker',
    name: 'PaymentDate',
    nameTable: 'userFactures',
    label: 'Data płatności',
  },
];

const InvoicesTable = ({
  dataTable = [],
  getValues,
  simple,
  admin,
  category,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState(null);

  const columns = [
    { title: 'Numer faktury', fieldName: 'factureNumber', sorting: true },
    { title: 'Identyfikator' },
    { title: 'Numer ewidencyjny' },
    { title: 'Typ faktury' },
    { title: 'Data wystawienia', fieldName: 'creationDate', sorting: true },
    { title: 'Termin płatności' },
    { title: 'Wartość netto (zł)' },
    { title: 'Wartość brutto (zł)' },
    { title: simple ? '' : 'Plik do pobrania' },
  ];

  if (!admin) columns.splice(1, 1);

  const rows = dataTable?.map(row => (
    <Tr key={row.id}>
      <Td>{row.factureNumber}</Td>
      {admin && (
        <Td>
          <CustomLink href={`/admin/users/${row.userId}`}>
            {row.userId}
          </CustomLink>
        </Td>
      )}
      <Td>{row.identifier}</Td>
      <Td>{row.factureType}</Td>
      <Td>{row.creationDate}</Td>
      <Td>{row.paymentDeadLine}</Td>
      <Td>{row.chargeNetto}</Td>
      <Td>{row.chargeBrutto}</Td>
      <Td>
        <Button type='button' onClick={() => showInvoice(row.id)}>
          <GetAppIcon /> Pobierz
        </Button>
      </Td>
    </Tr>
  ));

  const toggleModal = () => {
    setIsModalOpen(prev => !prev);
  };

  const showInvoice = async invoiceId => {
    const { data } = await sendRequest.get(apiEndpoints.facture(invoiceId));
    setCurrentInvoice(data);
    toggleModal();
  };

  return (
    <>
      <Table
        columns={columns}
        rows={rows}
        type='factures'
        getValues={getValues}
        searchPlaceholder='Szukaj numer ewidencyjny lub numer faktury'
        simple={simple}
        filtersTable={invoiceFiltersTable}
        category={category}
        evidences
      />
      <div style={{ position: 'relative', zIndex: 99999999 }}>
        <Modal
          isOpen={isModalOpen}
          contentLabel='Faktura'
          onRequestClose={toggleModal}
          style={modalStyle}
        >
          <p onClick={toggleModal}>Zamknij</p>
          {!currentInvoice ? <Indicator /> : <Facture {...currentInvoice} />}
        </Modal>
      </div>
    </>
  );
};

export default InvoicesTable;
