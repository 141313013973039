import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Table from 'components/tables/Table';

import AdminUsersTableRow from './AdminUsersTableRow';

import { columns } from './columns';
import { usersTypes, usersFiltersTable } from '../constans';
import { role } from 'auth/auth';

const AdminUsersTable = ({ dataTable, getValues, simple }) => {
  const { user } = useSelector(state => state.auth);
  const [userTypes, setUserTypes] = useState(usersTypes.INDIVIDUAL);

  const changeUsersType = usersType => {
    getValues('users', { userTypes: usersType }, 'admin/');
    setUserTypes(usersType);
  };

  const horizontalMenuAdmin = {
    curentType: userTypes,
    setCurrentType: changeUsersType,
    horizontalMenuButtons: [
      { type: usersTypes.INDIVIDUAL, label: 'Klienci indywidualni' },
      { type: usersTypes.BUSINESS, label: 'Klienci biznesowi' },
      { type: usersTypes.FUNCTIONARY, label: 'Urzędnicy' },
      { type: usersTypes.ADMINS, label: 'Administratorzy' },
    ],
  };

  const horizontalMenu = {
    curentType: userTypes,
    setCurrentType: changeUsersType,
    horizontalMenuButtons: [
      { type: usersTypes.INDIVIDUAL, label: 'Klienci indywidualni' },
      { type: usersTypes.BUSINESS, label: 'Klienci biznesowi' },
    ],
  };

  const rows = dataTable?.map(row => (
    <AdminUsersTableRow
      key={row.id}
      row={row}
      admin={
        userTypes === usersTypes.FUNCTIONARY || userTypes === usersTypes.ADMINS
      }
    />
  ));

  return (
    <Table
      type='users'
      columns={columns[userTypes]}
      rows={rows}
      getValues={getValues}
      searchPlaceholder='Wyszukaj klienta'
      simple={simple}
      horizontalMenu={
        user.userDetails.role === role.admin
          ? horizontalMenuAdmin
          : horizontalMenu
      }
      filtersTable={usersFiltersTable}
      category='admin/'
    />
  );
};

export default AdminUsersTable;
