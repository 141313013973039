import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { SettingsTitle } from 'components/Shared/Settings/Settings.css';
import MainWrapper from 'components/atoms/MainWrapper';
import AccountSection from 'components/UserSettings/AccountSection';
import NotificationsSection from 'components/UserSettings/NotifiactionsSection';
import NewsletterSection from 'components/UserSettings/NewsletterSection';
import DeleteAccountSection from 'components/Shared/DeleteAccountSection/DeleteAccountSection';
import DeleteAccountView from 'components/Shared/DeleteAccountSection/DeleteAccountView';

const UserSettings = () => {
  const [deleteView, setDeleteView] = useState(false);

  const auth = useSelector(state => state.auth);

  const swapDeleteView = () => {
    setDeleteView(prev => !prev);
  };

  return (
    <MainWrapper>
      {deleteView ? (
        <DeleteAccountView swapDeleteView={swapDeleteView} />
      ) : (
        <>
          <SettingsTitle>Ustawienia</SettingsTitle>
          <AccountSection userData={auth.user} />
          <NotificationsSection user={auth.user} />
          <NewsletterSection user={auth.user} />
          <DeleteAccountSection swapDeleteView={swapDeleteView} />
        </>
      )}
    </MainWrapper>
  );
};

export default UserSettings;
