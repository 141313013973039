import styled from 'styled-components';
import { device } from 'styles/devices';

export const ChartsWrapper = styled.div`
  width: 100%;
  background-color: white;
  padding: 20px;
  margin: 10px 0 30px;
  box-shadow: 2px 2px 25px rgba(20, 35, 87, 0.05);
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 2px 10px rgba(0, 4, 40, 0.04);

  @media ${device.desktop} {
    flex-direction: row;
    align-items: center;
  }
`;

export const DataPickerWrapper = styled.div`
  margin: 15px 0;
  margin-left: auto;

  @media ${device.desktop} {
    margin: 0;
    margin-left: auto;
  }
`;

export const ChartTitle = styled.h2`
  font-weight: 700;
`;

export const Menu = styled.nav`
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  height: 32px;
  margin-left: auto;

  @media ${device.desktop} {
    margin: 0 0 0 10px;
  }
`;

export const WrapperChart = styled.div`
  width: 100%;
  height: 250px;
  margin: 30px -30px;

  @media ${device.tablet} {
    height: 400px;
  }
`;

export const customSelectStyles = {
  container: provided => ({
    ...provided,
    maxWidth: '150px',
  }),
  control: provided => ({
    ...provided,
    minWidth: '150px',
    border: '1px solid #F2F2F2',
    height: '30px',
    borderRadius: '4px',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : '#000428',
    backgroundColor: state.isSelected ? '#000428' : 'white',
    padding: 5,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  menu: provided => ({
    ...provided,
    marginTop: '0px',
  }),
};
