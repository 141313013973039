import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const CheckboxStyle = withStyles(() => ({
  root: {
    '&$checked': {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '& .MuiIconButton-label:after': {
        content: '""',
        left: 4,
        top: 4,
        height: 14,
        width: 14,
        position: 'absolute',
        zIndex: -1,
      },
    },
  },
  checked: {},
  disabled: {},
}))(Checkbox);

const CustomCheckbox = styled(CheckboxStyle)`
  .MuiIconButton-label:after {
    background-color: ${({ bgColor }) => bgColor};
  }
`;

export default CustomCheckbox;
