import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { NotificationData } from 'components/Shared/Settings/Settings.css';
import Section from 'components/Shared/Settings/Section';
import { apiEndpoints, sendRequest } from 'API';
import { permissions } from './permissions.constants';
import CustomCheckbox from 'components/Shared/CustomCheckbox/CustomCheckbox.css';

const PermissionsSection = ({ title, category, data, roleId, getRoleData }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [sectionData, setSectionData] = useState(
    permissions[`${category.value}${category.helper}`],
  );
  const [dataToRender, setDataToRender] = useState([]);

  useEffect(() => {
    setSectionData(permissions[`${category.value}${category.helper}`]);
  }, [category.helper]);

  useEffect(() => {
    data.forEach(el => {
      // eslint-disable-next-line no-prototype-builtins
      if (sectionData.hasOwnProperty(el.name)) {
        setSectionData(prev => ({
          ...prev,
          [el.name]: { ...prev[el.name], status: el.status },
        }));
      }
    });
  }, [data]);

  useEffect(() => {
    const helpArray = Object.keys(sectionData)?.map(el => sectionData[el]);

    setDataToRender(helpArray);
  }, [sectionData]);

  const toggleEditable = () => {
    setIsEditable(prev => !prev);
    getRoleData();
  };

  const handleSave = async () => {
    try {
      let body = {};

      dataToRender.forEach(el => {
        body[el.name] = el.status;
      });

      await sendRequest.put(
        apiEndpoints.putUpdatePermissions(roleId, category.value),
        body,
      );
      toast.success('Dane zostały zapisane');
    } catch (e) {
      toast.error('Nie udało się zapisać danych');
    }
    toggleEditable();
  };

  const handleSwitchChange = e => {
    setSectionData(prev => ({
      ...prev,
      [e.target.name]: {
        ...prev[e.target.name],
        status: !prev[e.target.name].status,
      },
    }));
  };

  const mapOptions = dataToRender?.map((el, i) => (
    <NotificationData key={`${category.value}_${i}`}>
      <h4>{el.text}</h4>
      <CustomCheckbox
        disabled={!isEditable}
        checked={el.status}
        onClick={handleSwitchChange}
        name={el.name}
        bgColor={`${isEditable ? 'white' : 'black'}`}
        style={{
          color: `${isEditable ? '#1B69F2' : 'white'}`,
          padding: '0px',
        }}
      />
    </NotificationData>
  ));

  return (
    <Section
      editPermissions='EDIT_USER_ROLE'
      title={title}
      isEditable={isEditable}
      toggleIsEditable={toggleEditable}
      handleSave={handleSave}
    >
      {mapOptions}
    </Section>
  );
};

export default PermissionsSection;
