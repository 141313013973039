import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'actions';

import ConsumptionTable from 'components/Consumption/ConsumptionTable';

const UserConsumption = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { result } = useSelector(state => state.consumption);

  const getValues = (type, newValue) => {
    dispatch(
      allActions.consumptionActions.consumptionGetAction(
        type,
        newValue,
        'admin/',
      ),
    );
  };

  useEffect(() => {
    getValues('consumption', { uId: id }, 'admin/');
  }, []);

  return (
    <ConsumptionTable
      dataTable={result}
      getValues={getValues}
      category='admin/'
    />
  );
};

export default UserConsumption;
