import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Sling from 'hamburger-react';

import theme from 'styles/theme';

// import { ReactComponent as BigText } from 'assets/icons/bigText.svg';
// import { ReactComponent as SmallText } from 'assets/icons/smallText.svg';

import SideMenuLinks from 'components/SideMenuLinks/SideMenuLinks';
import {
  MainWrapper,
  ContentWrapper,
  SideMenuWrapper,
  Header,
  NotificationsWrapper,
  HeaderProfileInfo,
  Avatar,
  UserName,
} from './MainLayout.css';
import HeaderMenu from './HeaderMenu';
import Notification from 'components/Shared/Natifications/Notification';
import MayerynLogo from 'components/atoms/MayerynLogo';

const MainLayout = ({
  children,
  // changeZoom
}) => {
  const auth = useSelector(state => state.auth);
  const [isHamburgerOpen, setIsHamburgeOpen] = useState(false);

  const currentUser = {
    id: auth.user?.userDetails?.id || '222',
    firstName: auth.user?.userDetails?.name,
    lastName: auth.user?.userDetails?.surname,
  };

  const closeHamburger = () => {
    setIsHamburgeOpen(false);
  };

  return (
    <MainWrapper>
      <SideMenuWrapper active={isHamburgerOpen}>
        <SideMenuLinks closeHamburger={closeHamburger} />
        <MayerynLogo left />
      </SideMenuWrapper>
      <ContentWrapper>
        <Header>
          <Sling
            toggled={isHamburgerOpen}
            toggle={setIsHamburgeOpen}
            color={isHamburgerOpen ? 'white' : theme.mainColor}
          />
          <NotificationsWrapper>
            {/* <BigText
              onClick={() => changeZoom(0.1)}
              style={{ cursor: 'pointer' }}
            />
            <SmallText
              onClick={() => changeZoom(-0.1)}
              style={{ cursor: 'pointer' }}
            /> */}
            <Notification role={auth.user?.userDetails?.role} />
          </NotificationsWrapper>
          <HeaderProfileInfo>
            <Avatar>
              <p>
                {currentUser?.firstName?.slice(0, 1)}
                {currentUser?.lastName?.slice(0, 1)}
              </p>
            </Avatar>
            <UserName>
              <h2>{`${currentUser.firstName} ${currentUser.lastName}`}</h2>
              <h3>ID: {currentUser.id}</h3>
            </UserName>
            <HeaderMenu />
          </HeaderProfileInfo>
        </Header>
        {children}
      </ContentWrapper>
    </MainWrapper>
  );
};

export default MainLayout;
