import styled, { css } from 'styled-components';
import { device } from 'styles/devices';
import { scroll } from 'styles/mixins';

export const FiltersContainer = styled.div`
  position: relative;
  margin-top: 5px;
  margin-left: 50px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 0;
  }

  h4 {
    margin: 0;
    color: ${({ theme }) => theme.titleLight};
  }
`;

export const FiltersButtonToggle = styled.button`
  border: none;
  background-color: transparent;
`;

export const FiltersStyled = styled.form`
  z-index: 1;
  display: block;
  position: absolute;
  background-color: white;
  width: 350px;
  border: 1px solid #f2f2f2;
  box-shadow: 2px 2px 20px rgba(0, 4, 40, 0.08);
  border-radius: 4px;

  overflow-y: auto;
  max-height: 50vh;
  ${scroll}

  /* overflow: hidden; */
  transition: 0.2s;
  transform: ${({ openNotification }) =>
      openNotification ? 'scale(1)' : 'scale(0)'}
    translateX(-67%);
  opacity: ${({ openNotification }) => (openNotification ? '1' : '0')};

  ${scroll}

  .nice-dates-popover {
    transform: translateX(-20px);
  }

  .nice-dates-grid {
    background-color: white;
    width: 300px;
  }

  .nice-dates-day::before {
    background-color: ${({ theme }) => theme.primary};
    color: white;
  }

  ${({ right }) =>
    right &&
    css`
      transform: ${({ openNotification }) =>
          openNotification ? 'scale(1)' : 'scale(0)'}
        translateX(${({ title }) => (title === 'Sortuj' ? '-55px' : '-200px')});

      @media ${device.tablet} {
        transform: ${({ openNotification }) =>
            openNotification ? 'scale(1)' : 'scale(0)'}
          translateX(10px);
      }
    `}

  ${({ title }) =>
    title === 'Sortuj' &&
    css`
      width: 250px;
      padding: 0;

      button {
        border: none;
        background-color: white;
        width: 100%;
        padding: 10px;
        transition: 0.2s;

        cursor: pointer;

        :hover {
          background-color: #e9e9e9;
        }
      }
    `}
`;

export const SingleNotification = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  transition: 0.3s;

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const Info = styled.div`
  margin-left: 20px;

  p {
    margin: 0;
  }
`;

export const Date = styled.p`
  color: #8590a0;
`;

export const ButtonRemove = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.primaryLight};
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s;

  :hover {
    transform: scale(1.05);
  }
`;

export const NoNotification = styled.p`
  color: ${({ theme }) => theme.titleLight};
  padding: 20px;
`;
