import styled from 'styled-components';
import { device } from 'styles/devices';
import theme from 'styles/theme';

export const MainWrapper = styled.div`
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  flex-direction: row;
  background-color: ${theme.lightColor};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SideMenuWrapper = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 3;
  padding-top: 10vh;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: ${theme.mainColor};

  transition: transform 0.5s;
  transform: translateX(${({ active }) => (active === true ? '0' : '-100%')});

  @media ${device.tablet} {
    padding-top: 15vh;
    display: flex;
    transform: translateX(0);
    margin-top: 0px;
    width: 250px;
    min-width: 250px;
  }
`;

export const Header = styled.header`
  display: flex;
  height: 77px;
  align-items: right;
  background-color: ${theme.lightColor};
  box-shadow: 0px 2px 3px lightgrey;

  > div:first-child {
    margin: auto auto auto 20px;
    z-index: 3;
    @media ${device.tablet} {
      display: none;
    }
  }
`;

export const SideMenuItemsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SideMenuItem = styled.li`
  height: 54px;
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: row;

  a {
    height: 100%;
    width: 100%;
    text-decoration: none;
    display: block;
    font-weight: 600;
    transition: 0.5s;
    padding-left: 30px;
    display: flex;
    align-items: center;
    color: ${theme.fontColor};
    background-color: ${theme.mainColor};
    :hover {
      background-color: ${theme.secondaryColor};
    }

    &.active {
      font-weight: 700;
      color: white;
      background-color: ${theme.secondaryColor};
      border-left: 5px solid ${theme.marginColor};
    }

    img {
      width: 18px;
      height: 18px;
      margin-right: 20px;
    }
  }
`;

export const SideMenuSeparator = styled.h1`
  color: ${theme.fontColor};
  margin-top: 5vh;
  font-size: 14px;
  padding-left: 30px;
`;

export const NotificationsWrapper = styled.div`
  margin: auto 25px auto auto;
  position: relative;
  cursor: pointer;

  display: flex;
  align-items: center;
`;

export const HeaderProfileInfo = styled.div`
  display: flex;
  margin: auto 45px auto 0px;
  flex-direction: row;

  > img:first-child {
    width: 35px;
    height: 35px;
    background: grey;
    border-radius: 50%;
    margin-right: 10px;
    @media ${device.tablet} {
      width: 40px;
      height: 40px;
    }
  }

  div {
    @media ${device.tablet} {
      h2 {
        font-size: 16px;
        font-weight: 600;
        margin: 0px;
        padding: 0px;
      }

      h3 {
        font-size: 14px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
        color: ${theme.fontColor};
      }
    }
  }
`;

export const UserName = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

export const HeaderArrowDown = styled.img`
  cursor: pointer;
  margin: auto 0px auto 5px;
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  @media ${device.tablet} {
    margin: auto 0px auto 20px;
  }
`;

export const Avatar = styled.div`
  background-color: rgba(0, 4, 40, 0.65);
  color: white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  left: 0px;
  top: 0px;
  text-align: center;
  margin-right: 15px;
  font-weight: bold;

  p {
    font-size: 18px;
    line-height: 40px;
  }
`;
