import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'm-web-components';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { apiEndpoints, sendRequest } from 'API';
import Indicator from 'components/atoms/Indicator';
import UserDataSection from '../../UserDetails/UserData/UserDataSection';
import { eBOKDataInputs, pkwiuDataInputs } from './eBOKDataInputs';
import RegulationSection from './RegulationSection';
import Section from 'components/Shared/Settings/Section';

import {
  UserData,
  SettinsSubsectionTitle,
} from 'components/Shared/Settings/Settings.css';
import { toast } from 'react-toastify';

const fileFormats = ['pdf', 'docs', 'png'];

const SystemSettings = ({ userData }) => {
  const { user } = useSelector(state => state.auth);
  const [isEditableUserData, setIsEditableUserData] = useState(false);
  const [isEditablePkwiu, setIsEditablePkwiu] = useState(false);
  const [isEditRegulation, setIsEditRegulation] = useState(false);
  const [isEditFiles, setIsEditFiles] = useState(false);

  const [extensions, setExtensions] = useState(
    userData.adminSettings?.ebokSettings?.regulationExtensions,
  );
  const [attachmentSize, setAttachmentSize] = useState(
    userData.adminSettings?.ebokSettings?.attachmentSize,
  );

  const toggleEditRegulation = () => {
    setIsEditRegulation(prev => !prev);
  };

  const toggleEditFiles = () => {
    setIsEditFiles(prev => !prev);
  };

  const handleSave = async values => {
    try {
      await sendRequest.put(apiEndpoints.putUpdateFactureData, values);
      toast.success('Dane zostały zapisane');
    } catch (e) {
      toast.error('Wystąpił błąd podczas zapisywania danych');
    }

    setIsEditableUserData(false);
  };

  const handleSavePkwiu = async values => {
    try {
      await sendRequest.put(apiEndpoints.updatePkwiu, values);
      toast.success('Dane zostały zapisane');
    } catch (e) {
      toast.error('Wystąpił błąd podczas zapisywania danych');
    }

    setIsEditablePkwiu(false);
  };

  const handleSaveRegulation = () => {
    setIsEditRegulation(false);
  };

  const handleSaveFiles = () => {
    const updateExtensions = async () => {
      try {
        const uppercasedExt = extensions.map(ext => ext.toUpperCase());
        await sendRequest.put(
          apiEndpoints.putUpdateExtensions({
            attachmentExtensions: uppercasedExt,
          }),
        );
        toast.success('Format załączników został zaktualizowany');
      } catch (e) {
        toast.error(
          'Wystąpił błąd podczas aktualizowania formatu załączników.',
        );
      }
    };

    const updateAttachmentSize = async () => {
      try {
        await sendRequest.put(
          apiEndpoints.putUpdateAttachmentSize(attachmentSize),
        );
        toast.success('Rozmiar załączników został zaktualizowany');
      } catch (e) {
        toast.error(
          'Wystąpił błąd podczas aktualizowania rozmiaru załączników.',
        );
      }
    };

    updateExtensions();
    updateAttachmentSize();
    setIsEditFiles(false);
  };

  const handleChangeCheckbox = ext => {
    if (extensions.includes(ext)) {
      setExtensions(prev => prev.filter(extension => extension !== ext));
    } else {
      setExtensions(prev => [...prev, ext]);
    }
  };

  const handleSizeChange = e => {
    setAttachmentSize(e.target.value);
  };

  const buttonFormProps = isEditable => ({
    colorType: 'primary',
    padding: '5px 20px',
    margin: '0',
    style: {
      display: `${isEditable ? 'block' : 'none'}`,
      position: 'absolute',
      top: '15px',
      right: '15px',
    },
  });

  return (
    <>
      {!userData ? (
        <Indicator />
      ) : (
        <>
          <UserDataSection
            title='Dane użytkownika'
            status={isEditableUserData}
            setStatus={setIsEditableUserData}
            isPermission={user.permissions.includes('EDIT_MY_DETAILS')}
          >
            <Form
              defaultValues={userData.adminSettings?.ebokSettings}
              maxWidth='500px'
              onSubmit={handleSave}
              formFields={eBOKDataInputs(isEditableUserData)}
              labelSubmit='Zapisz'
              center
              buttonProps={buttonFormProps(isEditableUserData)}
            />
          </UserDataSection>

          <UserDataSection
            title='Pkwiu'
            status={isEditablePkwiu}
            setStatus={setIsEditablePkwiu}
            isPermission={user.permissions.includes('EDIT_MY_DETAILS')}
          >
            <Form
              defaultValues={userData.adminSettings?.ebokSettings?.pkwiu}
              maxWidth='500px'
              onSubmit={handleSavePkwiu}
              formFields={pkwiuDataInputs(isEditablePkwiu)}
              labelSubmit='Zapisz'
              center
              buttonProps={buttonFormProps(isEditablePkwiu)}
            />
          </UserDataSection>

          <RegulationSection
            isEditRegulation={isEditRegulation}
            toggleEditRegulation={toggleEditRegulation}
            handleSaveRegulation={handleSaveRegulation}
            regulationName={
              userData.adminSettings?.ebokSettings?.regulationName
            }
          />

          <Section
            editPermissions='EDIT_MY_DETAILS'
            title='Pliki'
            isEditable={isEditFiles}
            toggleIsEditable={toggleEditFiles}
            handleSave={handleSaveFiles}
          >
            <SettinsSubsectionTitle>
              Załączniki w wiadomościach
            </SettinsSubsectionTitle>
            <UserData>
              <h4 style={{ marginTop: 0 }}>format</h4>
              <FormGroup>
                {fileFormats.map((el, id) => (
                  <FormControlLabel
                    key={el}
                    control={
                      <Checkbox
                        disabled={!isEditFiles}
                        checked={extensions.includes(el)}
                        onClick={() => handleChangeCheckbox(el)}
                        name={el}
                        value={id}
                        style={{
                          color: `${isEditFiles ? '#1B69F2' : 'grey'}`,
                          padding: '5px 5px 5px 0px',
                          transform: 'translateX(-10px)',
                        }}
                      />
                    }
                    label={el}
                  />
                ))}
              </FormGroup>
            </UserData>
            <UserData>
              <h4>Rozmiar (MB)</h4>
              <input
                type='number'
                disabled={!isEditFiles}
                value={attachmentSize}
                onChange={handleSizeChange}
              />
            </UserData>
          </Section>
        </>
      )}
    </>
  );
};

export default SystemSettings;
