import React from 'react';
import parse from 'html-react-parser';
import {
  Avatar,
  MessageContent,
  MessageTile,
  AttachmentsWrapper,
  AttachmentsHeader,
  MessageTimeText,
} from './Communicator.css';

import { convertDate } from './atoms/DateConverter';

const Message = ({ message, getAttachment, currentUser }) => {
  return (
    <MessageTile isCurrentUser={message.isFromLoggedUser}>
      <div>
        <div>
          <Avatar>
            <p>
              {currentUser.userDetails.name.slice(0, 1)}
              {currentUser.userDetails.surname.slice(0, 1)}
            </p>
          </Avatar>
          <MessageContent isCurrentUser={message.isFromLoggedUser}>
            {parse(message.content)}
          </MessageContent>
        </div>
        <AttachmentsWrapper isCurrentUser={message.isFromLoggedUser}>
          {message.attachments.length !== 0 && (
            <AttachmentsHeader>Załączniki do pobrania:</AttachmentsHeader>
          )}
          {message.attachments.map(att => (
            <button
              key={att.name}
              type='button'
              onClick={() => getAttachment(att.id, att.name)}
            >
              {att.name}
            </button>
          ))}
        </AttachmentsWrapper>

        <MessageTimeText isCurrentUser={message.isFromLoggedUser}>
          Wysłana {convertDate(message.dateSent)}
        </MessageTimeText>
      </div>
      <br />
    </MessageTile>
  );
};

export default Message;
