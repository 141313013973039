import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import allActions from 'actions';

import theme from 'styles/theme';
import GlobalStyle from 'styles/GlobalStyle';

import { routes } from 'routes';
import Home from 'pages/Home/Home';
import Login from 'pages/Login/Login';
import LoginAdmin from 'pages/Login/LoginAdmin';
import Signup from 'pages/SignUp/Signup';
import Invoices from 'pages/Invoices/Invoices';
import Reports from 'pages/User/Reports/Reports';
import SignupForm from 'pages/SignUp/SignupForm';
import HomeUser from 'pages/User/HomeUser/HomeUser';
import CommunicatorPage from 'pages/Communicator/CommunicatorPage';
import UserSettings from 'pages/User/UserSettings/UserSettings';
import AdminSettings from 'pages/admin/AdminSettings/AdminSettings';
import ImportedFiles from 'pages/admin/ImportedFiles/ImportedFiles';
import AuthError404 from 'pages/Errors/AuthError404';
import Consumption from 'pages/User/Consumption/Consumption';
import ConsumptionAdmin from 'pages/admin/Consumption/Consumption';
import HomeAdmin from 'pages/admin/HomeAdmin/HomeAdmin';
import DeleteUser from 'pages/admin/Users/DeleteUser';
import AdminUsers from 'pages/admin/Users/AdminUsers';
import Error404 from 'pages/Errors/Error404';
import UserDetails from 'pages/admin/UserDetails/UserDetails';
import Permissions from 'pages/admin/Permissions/Permissions';
import DeactivatingAccount from 'pages/Logout/DeactivatingAccount';

// import LoginRedirectHandler from 'auth/LoginRedirectHandler';
// import SignupRedirectHandler from 'auth/SignupRedirectHandler';
// import OAuth2RedirectHandler from 'auth/OAuth2RedirectHandler';

import AuthLayout from 'layouts/AuthLayout';
import MainLayout from 'layouts/MainLayout/MainLayout';

import UserRoute from 'auth/UserRoute';
import AdminRoute from 'auth/AdminRoute';
import Logs from 'pages/admin/Logs/Logs';

// example styles in styled component
const Wrapper = styled.div`
  min-height: 100vh;
  zoom: ${({ zoom }) => zoom};
  /* filter: contrast(100%) invert(100%) brightness(1.1) sepia(0.4); */
`;

function App() {
  const [zoom, setZoom] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allActions.authActions.getCurrentUserAction());
  }, []);

  const changeZoom = value => {
    if (value > 0 && zoom > 1.5) return;
    if (value < 0 && zoom < 1) return;

    setZoom(prev => prev + value);
  };
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Wrapper className='App' zoom={zoom}>
        <Router>
          <Switch>
            <Route path='/auth/:path?' exact>
              <AuthLayout changeZoom={changeZoom}>
                <Switch>
                  <Route exact path={routes.login} component={Login} />
                  <Route
                    exact
                    path={routes.loginAdmin}
                    component={LoginAdmin}
                  />
                  <Route exact path={routes.signup} component={Signup} />
                  <Route
                    exact
                    path={routes.signupForm}
                    component={SignupForm}
                  />
                  <Route
                    exact
                    path={routes.deactivation}
                    component={DeactivatingAccount}
                  />
                  <Route component={AuthError404} />
                </Switch>
              </AuthLayout>
            </Route>

            <Route path='/(|user|admin)/:path?/:id?/:userId?' exact>
              <MainLayout changeZoom={changeZoom}>
                <Route exact path={routes.home} component={Home} />
                <Switch>
                  <UserRoute
                    exact
                    path={routes.user.home}
                    component={HomeUser}
                    requiredPermission='SYSTEM_LOGIN'
                  />
                  <UserRoute
                    exact
                    path={routes.user.invoices}
                    component={Invoices}
                    requiredPermission='SYSTEM_LOGIN'
                  />
                  <UserRoute
                    exact
                    path={routes.user.communicator}
                    component={CommunicatorPage}
                    requiredPermission='SHOW_SEND_MESSAGES'
                  />
                  <UserRoute
                    exact
                    path={routes.user.reports}
                    component={Reports}
                    requiredPermission='SYSTEM_LOGIN'
                  />
                  <UserRoute
                    exact
                    path={routes.user.settings}
                    component={UserSettings}
                    requiredPermission='SHOW_MY_DETAILS'
                  />
                  <UserRoute
                    exact
                    path={routes.user.consumption}
                    component={Consumption}
                    requiredPermission='SYSTEM_LOGIN'
                  />

                  {/*admin routes*/}

                  <AdminRoute
                    exact
                    path={routes.admin.home}
                    component={HomeAdmin}
                    requiredPermission='SYSTEM_LOGIN'
                  />
                  <AdminRoute
                    exact
                    path={routes.admin.deleteUser}
                    component={DeleteUser}
                    requiredPermission='EDIT_USER_ROLE'
                  />

                  <AdminRoute
                    exact
                    path={routes.admin.users}
                    component={AdminUsers}
                    requiredPermission='SHOW_OTHERS_DETAILS'
                  />
                  <AdminRoute
                    exact
                    path={routes.admin.importedFiles}
                    component={ImportedFiles}
                    requiredPermission='SYSTEM_LOGIN'
                  />
                  <AdminRoute
                    exact
                    path={routes.admin.consumption}
                    component={ConsumptionAdmin}
                    requiredPermission='SYSTEM_LOGIN'
                  />
                  <AdminRoute
                    exact
                    path={routes.admin.logs}
                    component={Logs}
                    requiredPermission='SHOW_LOGS'
                  />
                  <AdminRoute
                    exact
                    path={routes.admin.invoices}
                    component={Invoices}
                    requiredPermission='SYSTEM_LOGIN'
                  />
                  <AdminRoute
                    exact
                    path={routes.admin.usersDetails}
                    component={UserDetails}
                    requiredPermission='SHOW_OTHERS_DETAILS'
                  />
                  <AdminRoute
                    exact
                    path={routes.admin.communicator}
                    component={CommunicatorPage}
                    requiredPermission='SHOW_SEND_MESSAGES'
                  />
                  <AdminRoute
                    exact
                    path={routes.admin.permissions}
                    component={Permissions}
                    requiredPermission='SHOW_PERMISSIONS'
                  />

                  <AdminRoute
                    exact
                    path={routes.admin.settings}
                    component={AdminSettings}
                    requiredPermission='SHOW_MY_DETAILS'
                  />

                  <Route component={Error404} />
                </Switch>
              </MainLayout>
            </Route>
            <AuthLayout>
              <Route component={AuthError404} />
            </AuthLayout>
            {/* <Route
                exact
                path={routes.loginRedirect}
                component={LoginRedirectHandler}
              />
              <Route
                exact
                path={routes.signupRedirect}
                component={SignupRedirectHandler}
              /> */}
          </Switch>
        </Router>
        <ToastContainer />
      </Wrapper>
    </ThemeProvider>
  );
}

export default App;
