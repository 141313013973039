import React from 'react';
import { Link } from 'react-router-dom';

import MainWrapper from 'components/atoms/MainWrapper';
import SingleTail from 'components/Home/SingleTile';

import Title from 'components/atoms/Title';
import * as S from 'components/Home/Home.css';

import Chart from 'components/Chart/Chart';
import InvoicesTable from 'components/Invoices/InvoicesTable';
import { routes } from 'routes';
import { useSelector } from 'react-redux';

const elementsFirstChart = [{ id: 1, dataKey: 'usage', fill: '#1B69F2' }];

const HomeUser = () => {
  const { user } = useSelector(state => state.auth);

  const { statistics, report: dataChart, factures = [] } = user;

  return (
    <MainWrapper>
      <Title>Panel klienta</Title>
      <S.Header>
        <SingleTail data={statistics?.fullUsage} title='Całkowite zurzycie' />
        <SingleTail
          data={statistics?.thisYear}
          title='Zużycie w aktualnym roku'
        />
        <SingleTail
          data={statistics?.lastYear}
          title='Zużycie w poprzednim roku'
        />
      </S.Header>

      <h2>Miesięczny raport zużycia wody</h2>
      <Chart
        data={dataChart}
        elements={elementsFirstChart}
        title='Raport zużycia wody'
        noDataPicker
        simple
      />

      <h2>Najnowsze faktury</h2>
      {factures.length > 0 && (
        <S.TableWrapper>
          <InvoicesTable dataTable={factures} simple />
          <Link to={routes.user.invoices}>Zobacz wszystkie</Link>
        </S.TableWrapper>
      )}
    </MainWrapper>
  );
};

export default HomeUser;
