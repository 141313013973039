import styled from 'styled-components';
import { device } from 'styles/devices';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto 0px auto auto;
`;

export const TitleInput = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;

  h2 {
    padding: 10px 20px;
    margin: 0;
    margin-right: 20px;
  }

  input {
    display: block;
    margin: 0;
    padding: 10px 20px;
    font-size: 24px;
    font-weight: 600;
    border: 1px solid #f2f2f2;
    box-shadow: 1px 1px 15px rgba(0, 4, 40, 0.03);
    max-width: 210px;
    margin-right: 10px;

    @media ${device.mobileM} {
      max-width: 270px;
    }

    @media ${device.mobileL} {
      max-width: 300px;
      margin-right: 20px;
    }
    @media ${device.tablet} {
      max-width: 350px;
    }
  }

  svg {
    opacity: 0.2;
    transition: 0.3s;
    cursor: pointer;
  }

  :hover > svg {
    opacity: 1;
  }
`;
