import allActions from 'actions';
import LogsTable from 'components/admin/Logs/LogsTable';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

const UserLogs = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { result: dataTable } = useSelector(state => state.logs);

  const getValues = (type, newValue, category) => {
    dispatch(allActions.logsActions.logsGetAction(type, newValue, category));
  };

  useEffect(() => {
    getValues('logs', { uId: id }, 'admin/');
  }, []);

  return (
    <LogsTable getValues={getValues} dataTable={dataTable} category='admin/' />
  );
};

export default UserLogs;
