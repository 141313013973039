import React from 'react';
import MainWrapper from 'components/atoms/MainWrapper';
import Communicator from 'components/Communicator/Communicator';

const CommunicatorPage = () => {
  return (
    <MainWrapper>
      <Communicator />
    </MainWrapper>
  );
};

export default CommunicatorPage;
