import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';

import Title from 'components/atoms/Title';
import Indicator from 'components/atoms/Indicator';
import MainWrapper from 'components/atoms/MainWrapper';
import DeleteAccountView from 'components/Shared/DeleteAccountSection/DeleteAccountView';
import AccountSettings from 'components/admin/AdminSettings/Account/AccountSettings';
import SystemSettings from 'components/admin/AdminSettings/eBOK/SystemSettings';
import { role } from 'auth/auth';

const AppBarStyled = styled(AppBar)`
  box-shadow: none !important;
  max-width: 100vw;

  .MuiTab-root {
    width: auto;
    min-width: auto;
  }

  .MuiTab-root {
    color: ${({ theme }) => theme.titleLight};
  }

  .MuiTab-root.Mui-selected {
    color: ${({ theme }) => theme.primary};
  }
`;

const a11yProps = index => ({
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`,
});

const bookmarkItems = ['Ustawienia konta', 'Ustawienia systemu eBOK'];

const AdminSettings = () => {
  const [deleteView, setDeleteView] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const { user } = useSelector(state => state.auth);

  const handleChangeTab = (event, newValue) => setCurrentTab(newValue);

  const swapDeleteView = () => {
    setDeleteView(prev => !prev);
  };

  const renderMenuItems = bookmarkItems.map((item, index) => (
    <Tab
      key={item}
      label={item}
      style={{ textTransform: 'capitalize' }}
      {...a11yProps(index)}
    />
  ));

  const renderView = () => {
    switch (currentTab) {
      case 0:
        return (
          <AccountSettings
            swapDeleteView={swapDeleteView}
            userData={user}
            admin
          />
        );
      case 1:
        return <SystemSettings userData={user} />;

      default:
        break;
    }
  };

  return (
    <>
      {!user ? (
        <Indicator />
      ) : (
        <MainWrapper>
          {deleteView ? (
            <DeleteAccountView swapDeleteView={swapDeleteView} />
          ) : user.userDetails.role === role.functionary ? (
            <>
              <Title>Ustawienia</Title>
              <AccountSettings
                swapDeleteView={swapDeleteView}
                userData={user}
              />
            </>
          ) : (
            <>
              <Title>Ustawienia</Title>
              <AppBarStyled position='static' color='transparent'>
                <Tabs
                  value={currentTab}
                  onChange={handleChangeTab}
                  indicatorColor='primary'
                  variant='scrollable'
                  scrollButtons='auto'
                  aria-label='scrollable auto tabs example'
                >
                  {renderMenuItems}
                </Tabs>
                {renderView()}
              </AppBarStyled>
            </>
          )}
        </MainWrapper>
      )}
    </>
  );
};

export default AdminSettings;
