import paginationActions from 'actions/pagination.action';

export const SET_CURRENT_PAGE_LOGS = 'SET_CURRENT_PAGE_LOGS';

const setCurrentPageLogs = item => ({
  type: SET_CURRENT_PAGE_LOGS,
  item,
});

const logsGetAction = (
  type = 'factures',
  newValue,
  category,
) => async dispatch => {
  const { content } = await dispatch(
    paginationActions.getResultsAction(type, newValue, category),
  );
  dispatch(setCurrentPageLogs(content));
};

export default {
  setCurrentPageLogs,
  logsGetAction,
};
