import React from 'react';
import styled from 'styled-components';

import { Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Table from 'components/tables/Table';

const CustomLink = styled.a`
  text-decoration: underline;
  color: blue;

  &:hover {
    cursor: pointer;
    color: darkblue;
  }
`;

const filesFiltersTable = [
  {
    type: 'dataPicker',
    name: 'CreationDate',
    nameTable: 'consumption',
    label: 'Data naliczenia',
  },
];

const ConsumptionTable = ({ dataTable = [], getValues, category, isAdmin }) => {
  const adminColumns = [
    { title: 'Nazwa naliczenia' },
    { title: 'Identyfikator' },
    { title: 'Numer ewidencyjny' },
    { title: 'Jednostka miary' },
    { title: 'Wartość', fieldName: 'charge', sorting: true },
    { title: 'Data naliczenia', fieldName: 'creationDate', sorting: true },
  ];

  const columns = [
    { title: 'Nazwa naliczenia' },
    { title: 'Numer ewidencyjny' },
    { title: 'Jednostka miary' },
    { title: 'Wartość', fieldName: 'charge', sorting: true },
    { title: 'Data naliczenia', fieldName: 'creationDate', sorting: true },
  ];

  const rows = dataTable?.map(row => (
    <Tr key={row.id}>
      <Td>{row.consumptionName}</Td>
      {isAdmin && (
        <Td>
          <CustomLink href={`/admin/users/${row.userId}`}>
            {row.userId}
          </CustomLink>
        </Td>
      )}
      <Td>{row.evidenceNumber}</Td>
      <Td>{row.unit}</Td>
      <Td>{row.charge}</Td>
      <Td>{row.creationDate}</Td>
    </Tr>
  ));

  return (
    <Table
      columns={isAdmin ? adminColumns : columns}
      rows={rows}
      type='consumption'
      getValues={getValues}
      searchPlaceholder='Szukaj nazwę naliczenia lub numer ewidencyjny'
      filtersTable={filesFiltersTable}
      category={category}
      evidences
    />
  );
};

export default ConsumptionTable;
