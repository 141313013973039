import { apiEndpoints, sendRequest } from 'API';
import { toast } from 'react-toastify';

export const SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION';

const setCurrentConversation = item => ({
  type: SET_CURRENT_CONVERSATION,
  item,
});

// eslint-disable-next-line consistent-return
const fetchResults = async id => {
  try {
    const { data } = await sendRequest.get(apiEndpoints.getConversation(id));

    return data;
  } catch (e) {
    toast.error(
      'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z dostawcą oprogramowania',
    );
  }
};

// eslint-disable-next-line consistent-return
const getCurrentConversationAction = id => async dispatch => {
  let { data } = { data: null };

  if (id !== null && id !== undefined) {
    data = await fetchResults(id);
  }

  dispatch(setCurrentConversation(data));
};

export default { getCurrentConversationAction };
