import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainWrapper from 'components/atoms/MainWrapper';
import AdminUsersTable from 'components/admin/users/AdminUsersTable';
import Title from 'components/atoms/Title';

import allActions from 'actions';
import { usersTypes } from 'components/admin/constans';

const AdminUsers = () => {
  const dispatch = useDispatch();

  const { result: dataTable } = useSelector(state => state.users);
  const getValues = (type, newValue, category) => {
    dispatch(allActions.usersActions.usersGetAction(type, newValue, category));
  };

  useEffect(() => {
    getValues('users', { userTypes: usersTypes.INDIVIDUAL }, 'admin/');
  }, []);

  return (
    <MainWrapper>
      <Title>Użytkownicy</Title>
      <AdminUsersTable getValues={getValues} dataTable={dataTable} />
    </MainWrapper>
  );
};

export default AdminUsers;
