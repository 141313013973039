import allActions from 'actions';
import { useParams } from 'react-router';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SearchIcon from '@material-ui/icons/Search';

import {
  ConversationsNavigatorWrapper,
  ConversationNavigatorMenu,
  ConversationsList,
  NewMessageButtonContainer,
  Button,
  FiltersAndSortContainer,
  SearchWrapper,
  customSelectStyles,
} from './Communicator.css';
import {
  Avatar,
  ConversationTile,
  SneakPeak,
  ButtonSearch,
} from './Communicator.css';
import Conversation from './Conversation';
import Filter from 'components/Shared/FilterContrainer/FiltersContainer';
import FiltersComunicator from './FiltersComunicator';
import { Select } from 'm-web-components';

const threadOptions = [
  { label: 'Wszystkie wiadomości', value: 'ALL' },
  { label: 'Bez odpowiedzi', value: 'WITHOUT_RESPONSE' },
  { label: 'Z odpowiedzią', value: 'WITH_RESPONSE' },
];

const sortOptions = [
  {
    label: 'Od najnowszej do najstarszej',
    value: 'DESC',
  },
  { label: 'Od najstarszej do najnowszej', value: 'ASC' },
];

const ConversationNavigator = ({
  setSelectedThread,
  selected,
  setIsNavigatorOpen,
  isNavigatorOpen,
  toggleNavigator,
  topicOptions,
  handleNewsletter,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { id } = useParams();

  const [newConversation, setNewConversation] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { conversations } = useSelector(state => state.communicator);

  const handleNewConversation = () => {
    setNewConversation(prev => !prev);
    setSelectedThread(prev => (prev === 0 ? null : 0));
  };

  const handleNewConversationTile = () => {
    setSelectedThread(0);
    toggleNavigator();
  };

  const submitSearch = e => {
    e.preventDefault();
    dispatch(
      allActions.communicatorActions.conversationsGetAction({
        chIn: searchValue,
        uId: id,
      }),
    );
  };

  const handleSort = sortType => {
    dispatch(
      allActions.communicatorActions.conversationsGetAction({
        sortType,
        uId: id,
      }),
    );
  };

  const handleChangeStatusConversation = ({ value }) => {
    dispatch(
      allActions.communicatorActions.conversationsGetAction({
        conversation: value,
        uId: id,
      }),
    );
  };

  const renderSortOptios = sortOptions.map(el => (
    <button
      key={el.value}
      type='button'
      value={el.value}
      onClick={() => handleSort(el.value)}
    >
      {el.label}
    </button>
  ));

  return (
    <ConversationsNavigatorWrapper isNavigatorOpen={isNavigatorOpen}>
      <ConversationNavigatorMenu>
        <h1>Komunikacja</h1>
        <form onSubmit={submitSearch}>
          <SearchWrapper>
            <input
              placeholder='Szukaj odbiorcę lub temat wiadomości'
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
            <ButtonSearch type='submit'>
              <SearchIcon />
            </ButtonSearch>
          </SearchWrapper>
        </form>

        <FiltersAndSortContainer>
          <Filter title='Sortuj' right>
            {renderSortOptios}
          </Filter>
          <Filter right>
            <FiltersComunicator topicOptions={topicOptions} />
          </Filter>
        </FiltersAndSortContainer>
        <Select
          options={threadOptions}
          styles={customSelectStyles}
          placeholder='Wszystkie wiadomości'
          onChange={handleChangeStatusConversation}
        />
      </ConversationNavigatorMenu>
      <ConversationsList>
        <ConversationTile
          onClick={handleNewConversationTile}
          selected={selected === 0 ? selected : null}
          visible={newConversation}
        >
          <p></p>
          <SneakPeak>
            <Avatar />
            <div>
              <h2>Nowa wiadomość</h2>
              <p></p>
            </div>
          </SneakPeak>
        </ConversationTile>
        {conversations?.map((conversation, i) => (
          <Conversation
            key={i + conversation.lastMessageContent}
            setSelectedThread={setSelectedThread}
            setIsNavigatorOpen={setIsNavigatorOpen}
            toggleNavigator={toggleNavigator}
            conversation={conversation}
            index={i + 1}
            selected={selected === i + 1 ? selected : null}
          />
        ))}
      </ConversationsList>
      <NewMessageButtonContainer>
        <Button onClick={handleNewConversation}>+ Nowa wiadomość</Button>
        {user?.permissions?.includes('SEND_NEWSLETTER') && (
          <Button onClick={handleNewsletter}>Newsletter</Button>
        )}
      </NewMessageButtonContainer>
    </ConversationsNavigatorWrapper>
  );
};

export default ConversationNavigator;
