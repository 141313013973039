import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import MainWrapper from 'components/atoms/MainWrapper';
import SingleTail from 'components/Home/SingleTile';
import ImportedFilesTable from 'components/ImportedFiles/ImportedFilesTable';

import Title from 'components/atoms/Title';
import * as S from 'components/Home/Home.css';

import { routes } from 'routes';
import { apiEndpoints, sendRequest } from 'API';
import HomeAdminNewUsersTable from 'components/Home/HomeAdminNewUsersTable';
import { useSelector } from 'react-redux';

const HomeAdmin = () => {
  const { user } = useSelector(state => state.auth);
  const [adminData, setAdminData] = useState({});

  const getValues = async () => {
    const { data } = await sendRequest.get(apiEndpoints.getAdminData);
    setAdminData(data);
  };

  useEffect(() => {
    getValues();
  }, []);

  return (
    <MainWrapper>
      <Title>
        Panel{' '}
        {user?.userDetails?.role === 'Admin' ? 'Administratora' : 'Urzędnika'}
      </Title>
      <S.Header>
        <SingleTail
          data={adminData?.data?.allUsers}
          title='Wszyscy użytkownicy'
          admin
        />
        <SingleTail
          data={adminData?.data?.activeUsers}
          title='Aktywni użytkownicy'
          admin
        />
        <SingleTail
          data={adminData?.data?.notActiveUsers}
          title='Niezweryfikowani użytkownicy'
          admin
        />
      </S.Header>

      <S.TableWrapper>
        <h3>Nowi użytkownicy</h3>
        <HomeAdminNewUsersTable
          getValues={getValues}
          dataTable={adminData.newUsers}
          simple
        />
        <Link to={routes.admin.users}>Zobacz wszystkie</Link>
      </S.TableWrapper>

      <S.TableWrapper>
        <h3>Ostatnio importowane pliki</h3>
        <ImportedFilesTable dataTable={adminData.files} simple />
        <Link to={routes.admin.importedFiles}>Zobacz wszystkie</Link>
      </S.TableWrapper>
    </MainWrapper>
  );
};

export default HomeAdmin;
