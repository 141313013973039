import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import { styles } from './Facture.css';

const SingleCompany = ({ data, title }) => (
  <View style={styles.company}>
    <Text style={styles.title}>{title}</Text>
    <Text>{data.name}</Text>
    <Text>{data.street}</Text>
    <Text>
      {data.code} {data.city}
    </Text>
    <Text>NIP: {data.nip}</Text>
    {data.nrBankAccount && (
      <View>
        <Text>BS Terespol, nr.konta: </Text>
        <Text>{data.nrBankAccount}</Text>
      </View>
    )}
  </View>
);

export default SingleCompany;
