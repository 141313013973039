import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'm-web-components';
import CustomCheckbox from 'components/Shared/CustomCheckbox/CustomCheckbox.css';

import { apiEndpoints, sendRequest } from 'API';
import allActions from 'actions';

import {
  SettingsSection,
  SettingsSectionHeader,
  NotificationData,
  ButtonSecondary,
} from '../Shared/Settings/Settings.css';

const AccountSettings = ({ user }) => {
  const settings = user?.settings;
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [userNotificationData, setUserNotificationData] = useState(settings);

  const toggleEdit = () => {
    setIsEditMode(prev => !prev);
  };

  const dataToRender = [
    {
      text: 'Nowa faktura w systemie',
      name: 'newFactureNotification',
      checked: userNotificationData.newFactureNotification,
    },
    {
      text: 'Nowa wiadomość w systemie',
      name: 'newMessageNotification',
      checked: userNotificationData.newMessageNotification,
    },
    {
      text: 'Powiadomienia e-mail o aktywności na koncie',
      name: 'accountActivity',
      checked: userNotificationData.accountActivity,
    },
  ];

  const handleSwitchChange = e => {
    setUserNotificationData(prev => ({
      ...prev,
      [e.target.name]: !prev[e.target.name],
    }));
  };

  const changeFacture = async () => {
    try {
      await sendRequest.put(apiEndpoints.putNotifyFacture);
      toast.success('Notyfikacje faktur zostały zaktualizowane');
    } catch (e) {
      toast.success('Wystąpił błąd podczas zapisywania danych.');
    }
  };

  const changeNewMessage = async () => {
    try {
      await sendRequest.put(apiEndpoints.putNotifyNewMessage);
      toast.success('Notyfikacje wiadomości zostały zaktualizowane');
    } catch (e) {
      toast.success('Wystąpił błąd podczas zapisywania danych.');
    }
  };

  const changeAccountActivity = async () => {
    try {
      await sendRequest.put(apiEndpoints.putNotifyAccount);
      toast.success('Notyfikacje aktywności na koncie zostały zaktualizowane');
    } catch (e) {
      toast.success('Wystąpił błąd podczas zapisywania danych.');
    }
  };

  const handleSave = () => {
    if (
      settings.newFactureNotification !==
      userNotificationData.newFactureNotification
    ) {
      changeFacture();
    }
    if (
      settings.newMessageNotification !==
      userNotificationData.newMessageNotification
    ) {
      changeNewMessage();
    }
    if (settings.accountActivity !== userNotificationData.accountActivity) {
      changeAccountActivity();
    }

    dispatch(allActions.authActions.getCurrentUserAction());
    toggleEdit();
  };

  const renderOptions = () =>
    dataToRender.map(el => (
      <NotificationData key={el.name}>
        <h4>{el.text}</h4>
        <CustomCheckbox
          disabled={!isEditMode}
          checked={el.checked}
          onClick={handleSwitchChange}
          name={el.name}
          bgColor={`${isEditMode ? 'white' : 'black'}`}
          style={{
            color: `${isEditMode ? '#1B69F2' : 'white'}`,
            padding: '5px 5px 5px 0px',
            transform: 'translateX(-10px)',
          }}
        />
      </NotificationData>
    ));

  return (
    <SettingsSection>
      <SettingsSectionHeader>
        <h2>Powiadomienia</h2>
        {user.permissions.includes('EDIT_MY_DETAILS') && (
          <>
            {isEditMode ? (
              <>
                <ButtonSecondary type='button' onClick={toggleEdit}>
                  Anuluj
                </ButtonSecondary>
                <Button
                  type='button'
                  colorType='primary'
                  onClick={handleSave}
                  padding='2px 30px'
                >
                  Zapisz
                </Button>
              </>
            ) : (
              <Button
                type='button'
                colorType='primary'
                onClick={toggleEdit}
                padding='2px 30px'
              >
                Edytuj
              </Button>
            )}
          </>
        )}
      </SettingsSectionHeader>
      {renderOptions()}
    </SettingsSection>
  );
};

export default AccountSettings;
