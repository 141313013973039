import Filters from 'components/Shared/FilterContrainer/FiltersContainer';
import React from 'react';

import FiltersList from './FiltersList';

const FiltersTable = ({ type, filtersTable, category }) => {
  return (
    <Filters>
      <FiltersList
        type={type}
        filtersTable={filtersTable}
        category={category}
      />
    </Filters>
  );
};

export default FiltersTable;
