import { SET_TOPIC } from 'actions/chatTopics.action';

const INITIAL_STATE = [];

const topicReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TOPIC:
      return action.item;

    default:
      return state;
  }
};

export default topicReducer;
