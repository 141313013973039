import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Button } from 'm-web-components';
import { apiEndpoints, sendRequest } from 'API';
import allActions from 'actions';
import CustomCheckbox from 'components/Shared/CustomCheckbox/CustomCheckbox.css';

import {
  SettingsSection,
  SettingsSectionHeader,
  NotificationData,
  ButtonSecondary,
} from '../Shared/Settings/Settings.css';

const AccountSettings = ({ user }) => {
  const settings = user?.settings;
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [userNewsletterData, setUserNewsletterData] = useState(settings);

  const toggleEdit = () => {
    setIsEditMode(prev => !prev);
  };

  const dataToRender = [
    {
      text: 'Wyrażam zgodę na otrzymywanie wiadomości typu newsletter',
      name: 'newsletter',
      checked: userNewsletterData.newsletter,
    },
  ];

  const handleSwitchChange = e => {
    setUserNewsletterData(prev => ({
      ...prev,
      [e.target.name]: !prev[e.target.name],
    }));
  };

  const handleSave = () => {
    const putNewsletter = async () => {
      try {
        await sendRequest.put(apiEndpoints.putNotifyNewsletter);
        toast.success('Notyfikacje o Newsletterze zostały zaktualizowane');
      } catch (e) {
        toast.success('Wystąpił błąd podczas zapisywania danych.');
      }
      dispatch(allActions.authActions.getCurrentUserAction());
    };
    if (settings.newsletter !== userNewsletterData.newsletter) {
      putNewsletter();
    }
    toggleEdit();
  };

  const renderOptions = () =>
    dataToRender.map(el => (
      <NotificationData key={el.name}>
        <h4>{el.text}</h4>
        <CustomCheckbox
          disabled={!isEditMode}
          checked={userNewsletterData[el.name]}
          onClick={handleSwitchChange}
          name={el.name}
          bgColor={`${isEditMode ? 'white' : 'black'}`}
          style={{
            color: `${isEditMode ? '#1B69F2' : 'white'}`,
            padding: '5px 5px 5px 0px',
            transform: 'translateX(-10px)',
          }}
        />
      </NotificationData>
    ));

  return (
    <SettingsSection>
      <SettingsSectionHeader>
        <h2>Newsletter</h2>
        {user.permissions.includes('EDIT_MY_DETAILS') && (
          <>
            {isEditMode ? (
              <>
                <ButtonSecondary type='button' onClick={toggleEdit}>
                  Anuluj
                </ButtonSecondary>
                <Button
                  type='button'
                  colorType='primary'
                  onClick={handleSave}
                  padding='2px 30px'
                >
                  Zapisz
                </Button>
              </>
            ) : (
              <Button
                type='button'
                colorType='primary'
                onClick={toggleEdit}
                padding='2px 30px'
              >
                Edytuj
              </Button>
            )}
          </>
        )}
      </SettingsSectionHeader>
      {renderOptions()}
    </SettingsSection>
  );
};

export default AccountSettings;
