import styled from 'styled-components';

const Title = styled.h1`
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.primary};
  font-weight: bold;
`;

export default Title;
