import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainWrapper from 'components/atoms/MainWrapper';
import ImportedFilesTable from 'components/ImportedFiles/ImportedFilesTable';
import ModalAddFile from 'components/ImportedFiles/ModalAddFile';

import allActions from 'actions';

import { ImportedFilesTitle } from 'components/ImportedFiles/ImportedFiles.css';

const ImportedFiles = () => {
  const { files } = useSelector(state => state.files);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const getValues = (type, newValue) => {
    dispatch(allActions.filesActions.filesGetAction(type, newValue));
  };

  useEffect(() => {
    getValues('files');
  }, []);

  const toggleModal = () => {
    setIsModalOpen(prev => !prev);
  };

  return (
    <MainWrapper>
      <ModalAddFile
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        getValues={getValues}
      />

      <ImportedFilesTitle>Importowane pliki</ImportedFilesTitle>

      <ImportedFilesTable
        importFile={toggleModal}
        dataTable={files}
        getValues={getValues}
      />
    </MainWrapper>
  );
};

export default ImportedFiles;
