import React from 'react';

import InputUserDetails from 'components/atoms/InputUserDetails';

export const accountFields = (isEditable, canEdit) => {
  return [
    {
      as: <InputUserDetails isEditable={isEditable} disabled={!canEdit} />,
      name: 'name',
      label: 'Imię',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} disabled={!canEdit} />,
      name: 'surname',
      label: 'Nazwisko',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} disabled={!canEdit} />,
      name: 'address',
      label: 'Adres',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} disabled={!canEdit} />,
      name: 'city',
      label: 'Miejscowość',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} disabled />,
      name: 'id',
      label: 'Identyfikator',
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'email',
      label: 'E-mail',
      type: 'text',
    },
    {
      as: <InputUserDetails isEditable={isEditable} />,
      name: 'phoneNumber',
      label: 'Telefon',
      type: 'text',
    },
  ];
};
