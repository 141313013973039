import styled from 'styled-components';
import ImgBg from 'assets/images/auth-bg-1.png';
import { device } from 'styles/devices';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;

  @media ${device.tablet} {
    flex-direction: row;
  }

  .slick-dots {
    bottom: 30px;
    left: 30px;
    width: 100px;

    li {
      button::before {
        font-size: 15px;
        color: ${({ theme }) => theme.secondary};
      }
    }
  }
`;

export const Main = styled.main`
  position: relative;

  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    width: 50%;
  }
`;

export const Header = styled.header`
  padding: 70px 10px 0;
  display: flex;
  align-items: center;
  max-width: 450px;

  @media (min-width: 500px) {
    padding: 20px;
  }

  @media ${device.tablet} {
    padding: 70px 10px 0;
  }

  @media ${device.desktop} {
    padding: 30px;
  }

  h1 {
    font-size: 16px;
    margin: 0 0 0 20px;

    @media ${device.mobileM} {
      font-size: 20px;
    }
    @media ${device.mobileL} {
      font-size: 24px;
    }
  }

  div.logo {
    height: 50px;
    width: 100px;
    background-color: black;
    border-radius: 50%;
  }
`;

export const Aside = styled.aside`
  background-color: black;
  position: relative;
  background: url('${ImgBg}') no-repeat center;
  background-size: cover;

  @media ${device.tablet} {
    width: 50%;
  }
`;

export const SliderWrapper = styled.div`
  width: 100%;
  height: 80vh;

  @media ${device.tablet} {
    height: 100vh;
  }
`;

export const SliderItem = styled.div`
  width: 100%;
  height: 80vh;
  padding: 30px;

  @media ${device.tablet} {
    height: 100vh;
    padding: 70px;
  }

  img {
    margin: 10vh auto 0;
    height: 50vw;
    width: 80vw;
    object-fit: object;

    @media ${device.tablet} {
      margin: 20vh auto 0;
      height: 22vw;
      width: 35vw;
      object-fit: object;
    }
  }

  h2 {
    color: white;
    font-size: 40px;
    font-weight: 700;
  }

  p {
    color: ${({ theme }) => theme.secondary};
    font-size: 16px;
    font-weight: 500;
  }
`;

export const PageZoomSettings = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const Logo = styled.img`
  height: 60px;
`;

export const MainContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
`;
