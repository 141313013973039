import React from 'react';
import { Form } from 'm-web-components';
import { toast } from 'react-toastify';
import { apiEndpoints, sendRequest } from 'API';
import Arrow from 'assets/icons/arrow_back.svg';
import {
  SettingsDeleteSection,
  DeleteSectionHeader,
  ButtonCancel,
} from 'components/Shared/Settings/Settings.css';
import { formFields } from './createAdminFields';

const buttonFormProps = {
  colorType: 'primary',
  padding: '5px 20px',
  margin: '0',
  style: {
    position: 'absolute',
    bottom: '15px',
    right: '15px',
  },
};

const CreateAdmin = ({ toggleAddAdmin }) => {
  const handleAdd = async input => {
    const addNewAdmin = async () => {
      try {
        await sendRequest.post(apiEndpoints.postCreateAdmin, input);
        toggleAddAdmin();
        toast.success('Stworzono nowe konto administratora.');
      } catch (e) {
        toast.error(`Wystąpił błąd podczas tworzenia nowego konta.`);
        e.response?.data?.errors?.map(error =>
          toast.error(`${error.defaultMessage}`),
        );
      }
    };

    if (input.password === input.repeatPassword) {
      addNewAdmin();
    } else {
      toast.error('Podane hasła nie są takie same');
    }
  };

  const getBack = () => {
    toggleAddAdmin();
  };

  return (
    <SettingsDeleteSection style={{ position: 'relative' }}>
      <DeleteSectionHeader>
        <div>
          <img src={Arrow} alt='strzałka powrotu' onClick={getBack} />
          <h2>Stwórz konto administratora</h2>
        </div>
      </DeleteSectionHeader>
      <Form
        onSubmit={handleAdd}
        formFields={formFields}
        labelSubmit='Załóż konto'
        buttonProps={buttonFormProps}
      />
      <ButtonCancel
        small
        style={{ position: 'absolute', bottom: '15px', right: '150px' }}
        onClick={getBack}
      >
        Anuluj
      </ButtonCancel>
    </SettingsDeleteSection>
  );
};

export default CreateAdmin;
