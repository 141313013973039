export const menuItems = {
  INDIVIDUAL: [
    'Informacje ogólne',
    'Faktury',
    'Zużycie',
    'Raporty',
    'Komunikacja',
    'Logi systemowe',
  ],
  ADMINS: ['Informacje ogólne', 'Logi systemowe'],
};
