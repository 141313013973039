import React from 'react';
import InputUserDetails from 'components/atoms/InputUserDetails';

export const formFields = [
  {
    as: <InputUserDetails isEditable={true} />,
    name: 'name',
    label: 'Imię',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: <InputUserDetails isEditable={true} />,
    name: 'surname',
    label: 'Nazwisko',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: <InputUserDetails isEditable={true} />,
    name: 'email',
    label: 'E-mail',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: <InputUserDetails isEditable={true} />,
    name: 'password',
    label: 'Hasło',
    rules: { required: 'To pole jest wymagane' },
    type: 'password',
  },
  {
    as: <InputUserDetails isEditable={true} />,
    name: 'repeatPassword',
    label: 'Powtórz hasło',
    rules: { required: 'To pole jest wymagane' },
    type: 'password',
  },
  {
    as: <InputUserDetails isEditable={true} />,
    name: 'phoneNumber',
    label: 'Telefon',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
];
