import { toast } from 'react-toastify';
import { apiEndpoints, sendRequest } from 'API';

export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';

const setConversations = item => ({
  type: SET_CONVERSATIONS,
  item,
});

const fetchResults = async paramsData => {
  try {
    const { data } = await sendRequest.get(apiEndpoints.getChat(paramsData));
    return data.conversations;
  } catch (e) {
    toast.error(
      'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z dostawcą oprogramowania',
    );
  }
};

const fetchAdminResults = async paramsData => {
  try {
    const { data } = await sendRequest.get(
      apiEndpoints.getAdminChat(paramsData),
    );
    return data.conversations;
  } catch (e) {
    toast.error(
      'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z dostawcą oprogramowania',
    );
  }
};

const conversationsGetAction = params => async dispatch => {
  const paramsData = {
    ids: params?.ids || '',
    sortType: params?.sortType || '',
    chIn: params?.chIn || '',
    dTo: params?.dTo || '',
    dFrom: params?.dFrom || '',
    conversation: params?.conversation || '',
    uId: params?.uId || '',
  };

  let content;
  if (params?.clear === 'clear') {
    content = [];
  } else {
    if (params?.uId) {
      content = await fetchAdminResults(paramsData);
    } else {
      content = await fetchResults(paramsData);
    }
  }
  dispatch(setConversations(content));
};

export default {
  setConversations,
  conversationsGetAction,
};
