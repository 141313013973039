import React from 'react';

import {
  SettingsSection,
  SettingsSectionHeader,
  ButtonDelete,
} from '../Settings/Settings.css';

const DeleteAccountSection = ({ swapDeleteView }) => {
  return (
    <SettingsSection>
      <SettingsSectionHeader>
        <h2>Usuwanie konta</h2>
      </SettingsSectionHeader>
      <ButtonDelete onClick={swapDeleteView}>Usuń konto</ButtonDelete>
    </SettingsSection>
  );
};

export default DeleteAccountSection;
