import React, { useEffect } from 'react';

import allActions from 'actions';
import { useDispatch, useSelector } from 'react-redux';

import MainWrapper from 'components/atoms/MainWrapper';
import LogsTable from 'components/admin/Logs/LogsTable';
import Title from 'components/atoms/Title';

const Logs = () => {
  const dispatch = useDispatch();
  const { result: dataTable } = useSelector(state => state.logs);

  const getValues = (type, newValue, category) => {
    dispatch(allActions.logsActions.logsGetAction(type, newValue, category));
  };

  useEffect(() => {
    getValues('logs', { uId: '' }, 'admin/');
  }, []);

  return (
    <MainWrapper>
      <Title>Logi systemowe</Title>

      <LogsTable
        getValues={getValues}
        dataTable={dataTable}
        category='admin/'
      />
    </MainWrapper>
  );
};

export default Logs;
