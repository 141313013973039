import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { Form } from 'm-web-components';
import { modalStyle } from 'components/Shared/ModalStyles/ModalStyles';
import InputUserDetails from 'components/atoms/InputUserDetails';
import { apiEndpoints, sendRequest } from 'API';
import { ButtonCancel } from 'components/Shared/Settings/Settings.css';

import CloseX from 'assets/icons/close_X.svg';
import { customSelectStyles } from 'components/tables/Table.css';

Modal.setAppElement('#root');

const buttonFormProps = {
  colorType: 'primary',
  padding: '5px 20px',
  margin: '0',
  style: {
    position: 'absolute',
    bottom: '15px',
    right: '15px',
  },
};

const formFields = selectFields => [
  {
    as: <InputUserDetails isEditable={true} styles={{ maxWidth: '250px' }} />,
    name: 'name',
    label: 'Nazwa roli',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: (
      <InputUserDetails
        isEditable={true}
        select
        options={selectFields}
        styles={{
          ...customSelectStyles,
          container: provided => ({
            ...provided,
            maxWidth: '100%',
          }),
          option: provided => ({
            ...provided,
          }),
        }}
      />
    ),
    name: 'roleId',
    label: 'Rodzaj roli',
    rules: { required: 'To pole jest wymagane' },
  },
];

const AddRoleModal = ({ isModalOpen, toggleModal, superRoles, getRoles }) => {
  const [selectFields, setSelectFields] = useState([]);

  useEffect(() => {
    setSelectFields(
      superRoles.map(role => ({ label: role.name, value: +role.roleId })),
    );
  }, []);

  const handleSave = async input => {
    try {
      await sendRequest.post(apiEndpoints.postAddRole, {
        name: input.name,
        roleId: input.roleId.value,
      });
      toast.success(`Rola została dodana`);
      getRoles();
    } catch (e) {
      toast.error('Wystąpił błąd podczas dodawania roli');
    }

    toggleModal();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel='Dodawanie roli'
      onRequestClose={toggleModal}
      style={modalStyle}
    >
      <img src={CloseX} onClick={toggleModal} />
      <div style={{ padding: '0px 0px 5vh 0px' }}>
        <Form
          defaultValues={{ name: '', roleId: selectFields[0] }}
          onSubmit={handleSave}
          formFields={formFields(selectFields)}
          labelSubmit='Zapisz'
          buttonProps={buttonFormProps}
        />

        <ButtonCancel
          small
          style={{ position: 'absolute', bottom: '15px', right: '125px' }}
          onClick={toggleModal}
        >
          Anuluj
        </ButtonCancel>
      </div>
    </Modal>
  );
};

export default AddRoleModal;
