import React, { useState } from 'react';

import { ClickAwayListener } from '@material-ui/core';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';

import * as S from './Filter.css';

const FiltersContainer = ({ children, title = 'Filtruj', right }) => {
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setOpenFilter(false)}>
      <S.FiltersContainer>
        <S.FiltersButtonToggle
          type='button'
          onClick={() => setOpenFilter(prev => !prev)}
        >
          {title}
          <ExpandMoreTwoToneIcon />
        </S.FiltersButtonToggle>
        <S.FiltersStyled openFilter={openFilter} title={title} right={right}>
          {children}
        </S.FiltersStyled>
      </S.FiltersContainer>
    </ClickAwayListener>
  );
};

export default FiltersContainer;
