import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.primary};
  padding: 10px;
  min-width: 100px;
  border-radius: 5px;

  p {
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  p:nth-child(1) {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
    margin-bottom: 10px;
  }
  p:nth-child(2) {
    color: #fff;
    font-size: 18px;
  }
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Wrapper className='custom-tooltip'>
        <p className='label'>{label}</p>
        <p className='label'>
          {payload[0].value} m<sup>2</sup>
        </p>
      </Wrapper>
    );
  }

  return null;
};

export default CustomTooltip;
