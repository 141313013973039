import React from 'react';
import { useDispatch } from 'react-redux';

import allActions from 'actions';

import PuapForm from 'components/PuapForm/PuapForm';

const SignupPuap = () => {
  const dispatch = useDispatch();

  return (
    <PuapForm
      title='Krok 1: Rejestracja przy pomocy Profilu Zaufanego'
      footer={{
        question: 'Masz już konto?',
        info: 'Zaloguj się do systemu eBOK i wygodnie sprawdzaj swoje faktury.',
        button: 'Zaloguj się',
        url: '/auth/login',
      }}
    />
  );
};

export default SignupPuap;
