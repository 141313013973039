import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'm-web-components';
import { toast } from 'react-toastify';
import { apiEndpoints, sendRequest } from 'API';
import Arrow from 'assets/icons/arrow_back.svg';
import { Input } from 'components/atoms/Input';
import allActions from 'actions';

import {
  SettingsDeleteSection,
  DeleteSectionHeader,
  DeleteAccountDescription,
  CodeLabel,
  ButtonCancel,
} from '../Settings/Settings.css';
import { useHistory, useParams } from 'react-router';

const formFields = [
  {
    as: <Input variant='outlined' />,
    name: 'code',
    label: 'Podaj kod',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
];

const AccountSettings = ({ swapDeleteView, userType }) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [code, setCode] = useState(null);

  useEffect(() => {
    const getCodeByUser = async () => {
      const { data } = await sendRequest.get(apiEndpoints.getUserCode);
      setCode(data);
    };
    const getCodeByAdmin = async () => {
      const { data } = await sendRequest.get(
        apiEndpoints.getCodeToDeleteUserByAdmin(id),
      );
      setCode(data);
    };

    if (userType === 'admin') getCodeByAdmin();
    else getCodeByUser();
  }, []);

  const handleDelete = async input => {
    const deleteAccount = async () => {
      try {
        if (userType === 'admin') {
          await sendRequest.delete(apiEndpoints.deleteUserByAdmin(input.code));

          toast.success(
            `Konto użytkownika o ID: ${id} zostało pomyślnie usunięte.`,
          );
          history.push('/admin/users');
        } else {
          await sendRequest.delete(apiEndpoints.deleteAccount(input.code));
        }
        if (userType !== 'admin') {
          dispatch(allActions.authActions.deactivationAccountAction());
        }
      } catch (e) {
        toast.error('Wystąpił problem podczas usuwania konta');
      }
    };

    if (input.code === code) {
      deleteAccount();
    } else {
      toast.error('Podano niepoprawny kod');
    }
  };

  const getBack = () => {
    if (userType === 'admin') history.goBack();
    else swapDeleteView();
  };

  return (
    <SettingsDeleteSection>
      <DeleteSectionHeader>
        <div>
          <img src={Arrow} alt='strzałka powrotu' onClick={getBack} />
          <h2>Usuń konto</h2>
        </div>
      </DeleteSectionHeader>
      <DeleteAccountDescription>
        <h3>Usunięcie konta jest nieodwracalne.</h3>
        <p>
          Po usunięciu konta stracisz dostęp do wszystkich swoich faktur i
          raportów. Nie będziesz mógł także kontaktować się z urzędem za pomocą
          chatu.
        </p>
        <br />
        <p>Przepisz poniższe hasło, aby potwierdzić swoją decyzję:</p>
      </DeleteAccountDescription>
      <CodeLabel>{code}</CodeLabel>
      <Form
        onSubmit={handleDelete}
        formFields={formFields}
        labelSubmit='Usuń konto'
        center
        buttonProps={{
          colorType: 'loginAdmin',
          padding: '10px 50px',
        }}
      />
      <ButtonCancel onClick={getBack}>Anuluj</ButtonCancel>
    </SettingsDeleteSection>
  );
};

export default AccountSettings;
