import React from 'react';
import exportFromJSON from 'export-from-json';

import GetAppIcon from '@material-ui/icons/GetApp';
import { Button } from 'm-web-components';

export const ExportButton = ({ fileName, data }) => {
  const dataToExport = () =>
    data.map(el => ({
      ['Data']: el.name,
      ['Zużycie']: el.usage,
      ['Opłata']: el.charge,
    }));

  const exportFile = () => {
    exportFromJSON({ data: dataToExport(), fileName, exportType: 'xls' });
  };

  return (
    <Button
      type='button'
      colorType='primary'
      onClick={exportFile}
      padding='10px 20px'
    >
      <GetAppIcon style={{ marginRight: '10px' }} />
      Pobierz raport (xls)
    </Button>
  );
};
