export const routes = {
  login: '/auth/login',
  loginAdmin: '/auth/login-admin',
  signup: '/auth/signup',
  signupForm: '/auth/signup-form',
  aouthRedirect: '/oauth2/redirect',
  signupRedirect: '/oauth2/redirect',
  deactivation: '/auth/deactivation',

  home: '/',

  admin: {
    home: '/admin',
    communicator: '/admin/communicator',
    importedFiles: '/admin/imported-files',
    invoices: '/admin/invoices',
    consumption: '/admin/consumption',
    users: '/admin/users',
    usersDetails: '/admin/users/:id',
    logs: '/admin/logs',
    permissions: '/admin/permissions',
    deleteUser: '/admin/users/delete/:id',
    settings: '/admin/settings',
  },

  user: {
    home: '/user',
    communicator: '/user/communicator',
    consumption: '/user/consumption',
    invoices: '/user/invoices',
    reports: '/user/reports',
    download: '/user/reports-download',
    settings: '/user/settings',
  },
};
