import React, { useEffect } from 'react';

import allActions from 'actions';
import { useDispatch, useSelector } from 'react-redux';

import MainWrapper from 'components/atoms/MainWrapper';
import InvoicesTable from 'components/Invoices/InvoicesTable';
import Title from 'components/atoms/Title';
import { role } from 'auth/auth';

const Invoices = () => {
  const dispatch = useDispatch();
  const { result: dataTable } = useSelector(state => state.factures);
  const { user } = useSelector(state => state.auth);

  const checkRole = () => user.userDetails.role !== role.user;

  const getValues = (type, newValue, category) => {
    dispatch(
      allActions.facturesActions.facturesGetAction(type, newValue, category),
    );
  };

  useEffect(() => {
    const category = checkRole() ? 'admin/' : '';
    getValues('factures', { uId: '' }, category);
  }, []);

  return (
    <MainWrapper>
      <Title>Faktury</Title>

      <InvoicesTable
        getValues={getValues}
        dataTable={dataTable}
        category={checkRole() ? 'admin/' : ''}
        admin={checkRole()}
      />
    </MainWrapper>
  );
};

export default Invoices;
