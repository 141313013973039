import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import Modal from 'react-modal';

import ConversationNavigator from './components/ConversationsNavigator';
import ConversationsWindow from './components/ConversationsWindow';
import ConversationsWindowEmpty from './components/ConversationsWindowEmpty';
import AddTopicModal from './components/AddTopicModal';
import {
  modalStyle,
  baseStyle,
} from 'components/Shared/ModalStyles/ModalStyles';

import {
  CommunicatorWrapper,
  Button,
  ErrorMessage,
} from './components/Communicator.css';

Modal.setAppElement('#root');

const CommunicatorComponent = ({
  handleSendClick,
  topicOptions,
  receiverOptions,
  currentConversation,
  getConversation,
  handleNewConversationMessage,
  setSendOptions,
  getAttachment,
  isAdmin,
  handleSaveTopics,
  handleDeleteTopic,
  setNewsletterMessages,
  attachmentInfo,
}) => {
  const [isNavigatorOpen, setIsNavigatorOpen] = useState(true);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddTopicModalOpen, setIsAddTopicModalOpen] = useState(false);

  const { user } = useSelector(state => state.auth);
  const { conversations } = useSelector(state => state.communicator);

  const toggleNavigator = () => {
    setIsNavigatorOpen(prev => !prev);
  };

  const toggleModal = () => {
    setIsModalOpen(prev => !prev);
  };

  const toggleAddTopicModal = () => {
    setIsAddTopicModalOpen(prev => !prev);
  };

  useEffect(() => {
    getConversation(selectedConversation);
  }, [selectedConversation]);

  useEffect(() => {
    getConversation(null);
    if (conversations !== undefined) {
      if (conversations.length !== 0) {
        setSelectedConversation(1);
        getConversation(1);
      }
    }
  }, [conversations]);

  const handleAttachmentClick = () => {
    toggleModal();
  };

  const handleSubmit = () => {
    setSendOptions(prev => ({
      ...prev,
      file: acceptedFiles,
    }));
    toggleModal();
  };

  const handleNewsletter = () => {
    setSelectedConversation('Newsletter');
    setNewsletterMessages();
    toggleNavigator();
  };

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept: attachmentInfo?.extensions?.map(ext => `.${ext}`),
    maxSize: attachmentInfo?.attachmentSize * 1048576,
  });

  const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>);
  const rejected = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path}
      {errors.map(e => (
        <ErrorMessage key={e.code}>
          {e.code === 'file-invalid-type'
            ? 'Błędny format pliku.'
            : e.code === 'file-too-large'
            ? 'Zbyt duży rozmiar pliku.'
            : e.code}
        </ErrorMessage>
      ))}
    </li>
  ));

  return (
    <CommunicatorWrapper>
      <ConversationNavigator
        setSelectedThread={setSelectedConversation}
        setIsNavigatorOpen={setIsNavigatorOpen}
        toggleNavigator={toggleNavigator}
        isNavigatorOpen={isNavigatorOpen}
        selected={selectedConversation}
        topicOptions={topicOptions}
        currentUser={user}
        isAdmin={isAdmin}
        handleNewsletter={handleNewsletter}
      />
      {!currentConversation ? (
        <ConversationsWindowEmpty
          handleNewConversationMessage={handleNewConversationMessage}
          isNewConversation={selectedConversation === 0}
          isNavigatorOpen={isNavigatorOpen}
          handleAttachmentClick={handleAttachmentClick}
          topicOptions={topicOptions}
          receiverOptions={receiverOptions}
          setSendOptions={setSendOptions}
          toggleNavigator={toggleNavigator}
          isAdmin={isAdmin}
          toggleAddTopicModal={toggleAddTopicModal}
        />
      ) : (
        <ConversationsWindow
          currentConversation={currentConversation}
          toggleNavigator={toggleNavigator}
          isNavigatorOpen={isNavigatorOpen}
          handleSendClick={handleSendClick}
          handleAttachmentClick={handleAttachmentClick}
          getAttachment={getAttachment}
          isAdmin={isAdmin}
          currentUser={user}
        />
      )}
      <AddTopicModal
        isModalOpen={isAddTopicModalOpen}
        toggleModal={toggleAddTopicModal}
        modalStyle={modalStyle}
        topicOptions={topicOptions}
        handleSaveTopics={handleSaveTopics}
        handleDeleteTopic={handleDeleteTopic}
      />
      <Modal
        isOpen={isModalOpen}
        contentLabel='Załącznik'
        onRequestClose={toggleModal}
        style={modalStyle}
      >
        <p onClick={toggleModal}>Zamknij</p>
        <div {...getRootProps({ style: baseStyle })}>
          <input {...getInputProps()} />
          <p>(Naciśnij by dodać)</p>
          <p>
            Rozszerzenia: {attachmentInfo?.extensions?.map(ext => `.${ext}, `)}
          </p>
          <p>Maksymalny rozmiar: {attachmentInfo?.attachmentSize} MB</p>
        </div>
        <br />
        <aside>
          <h4>Wybrane pliki</h4>
          <ul>{files}</ul>
          <h4>Odrzucone pliki:</h4>
          <ul>{rejected}</ul>
        </aside>
        <br />
        <Button onClick={handleSubmit}>Zatwierdź</Button>
      </Modal>
    </CommunicatorWrapper>
  );
};

export default CommunicatorComponent;
