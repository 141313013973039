import React from 'react';
import styled from 'styled-components';
import { device } from 'styles/devices';
import ReactTooltip from 'react-tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Select from 'react-select';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  label {
    text-transform: uppercase;
    color: ${({ theme }) => theme.titleLight};
    padding-left: 10px;
  }

  input {
    transition: 0.3s;
    border: ${({ iseditable }) =>
      !iseditable
        ? '1px solid transparent'
        : '1px solid rgba(136, 135, 148, 0.5)'};
    border-radius: 4px;

    font-size: 16px;
    padding: 5px 10px;
    background-color: ${({ disabled, iseditable }) =>
      disabled && iseditable ? 'rgba(122, 122, 122, 0.3)' : '#fff'};
  }

  @media ${device.mobileL} {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 300px;

    label {
      min-width: 200px;
    }
    input {
      flex-grow: 1;
    }
  }
`;

const Tooltip = styled.div`
  opacity: ${({ tooltip }) => (tooltip ? 1 : 0)};
  display: flex;
  align-items: center;
`;

const InputUserDetails = ({
  label,
  value,
  onChange,
  isEditable,
  disabled,
  styles,
  select,
  options,
  type,
  tooltip,
  ...rest
}) => {
  return (
    <InputWrapper iseditable={isEditable} disabled={disabled}>
      <label style={{ fontWeight: '600', fontSize: '16px' }}>{label}</label>

      {!select && (
        <input
          style={styles}
          disabled={disabled}
          readOnly={!isEditable}
          value={value}
          onChange={e => onChange(e.target.value)}
          type={type}
        />
      )}

      {select && value && isEditable && (
        <div style={{ width: '250px' }}>
          <Select
            options={options}
            placeholder='nonono'
            styles={styles}
            onChange={value => onChange(value)}
            defaultValue={value}
            {...rest}
          />
        </div>
      )}

      {select && value && !isEditable && (
        <p style={{ width: '250px', color: value.color, padding: '9.5px' }}>
          {value.label}
        </p>
      )}

      <Tooltip tooltip={tooltip}>
        <a data-tip={tooltip}>
          <InfoOutlinedIcon fontSize='large' />
        </a>
        <ReactTooltip place='right' type='info' effect='solid' />
      </Tooltip>
    </InputWrapper>
  );
};

export default InputUserDetails;
