import React from 'react';
import DataPicker from 'components/atoms/DataPicker';
import { useDispatch, useSelector } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import allActions from 'actions';

import RadioFilter from './Singles/RadioFilter';

const SingleFilter = ({
  filterType = 'dataPicker',
  name = 'CreationDate',
  nameTable = 'userFactures',
  label = 'Data wystawienia',

  //only for facutres // if we have different types of dates
  currentDateFilter,
  setCurrentDateFilter,

  // for list of checkboxes
  checkboxes = [],

  //for radio buttons
  radios = [],
}) => {
  const dispatch = useDispatch();

  const allFilters = useSelector(state => state.filtersTable[nameTable]);

  // for DataPickers
  const setDate = (nameFilter, value) => {
    if (setCurrentDateFilter) setCurrentDateFilter(nameFilter);

    dispatch(
      allActions.filtersTableActions.setUserFilterTable({
        nameFilter,
        value,
        nameTable,
      }),
    );
  };

  //for lists
  const handleChangeIdsInList = e => {
    let tempArray = allFilters[name];
    if (e.target.checked) {
      tempArray = [...tempArray, e.target.value.toString()];
    } else {
      tempArray = tempArray.filter(el => el !== e.target.value.toString());
    }

    dispatch(
      allActions.filtersTableActions.setUserFilterTable({
        nameFilter: name,
        value: tempArray,
        nameTable,
      }),
    );
  };

  switch (filterType) {
    case 'dataPicker': {
      //conditions for different typres dates
      const startDateName = setCurrentDateFilter ? `startDate${name}` : 'dFrom';
      const endDateName = setCurrentDateFilter ? `endDate${name}` : 'dTo';
      const disabled = setCurrentDateFilter
        ? currentDateFilter !== '' && !currentDateFilter?.includes(name)
        : false;

      return (
        <>
          <p style={{ margin: '20px 0 0 0' }}>{label}</p>
          <DataPicker
            startDate={allFilters[startDateName]}
            endDate={allFilters[endDateName]}
            setStartDate={value => setDate(startDateName, value)}
            setEndDate={value => setDate(endDateName, value)}
            disabled={disabled}
          />
        </>
      );
    }
    case 'list':
      return (
        <>
          <p style={{ margin: '20px 0 0 0' }}>{label}</p>
          <FormGroup>
            {checkboxes.map(check => (
              <FormControlLabel
                key={check.name}
                control={
                  <Checkbox
                    checked={allFilters[name].includes(check.id.toString())}
                    onChange={handleChangeIdsInList}
                    name={check.name}
                    value={check.id}
                    style={{ color: '#1B69F2', padding: '5px' }}
                  />
                }
                label={check.name}
              />
            ))}
          </FormGroup>
        </>
      );

    case 'radio':
      return (
        <RadioFilter
          radios={radios}
          label={label}
          currentValue={allFilters.status}
          name={name}
          nameTable={nameTable}
        />
      );

    default:
      break;
  }
};

export default SingleFilter;
