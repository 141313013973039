export const SET_USER_FILTER_TABLE = 'SET_USER_FILTER_TABLE';
export const CLEAR_USER_FILTER_TABLE = 'CLEAR_USER_FILTER_TABLE';

const setUserFilterTable = item => ({
  type: SET_USER_FILTER_TABLE,
  item,
});

const clearUserFiltersTable = item => ({
  type: CLEAR_USER_FILTER_TABLE,
  item,
});

export default {
  setUserFilterTable,
  clearUserFiltersTable,
};
