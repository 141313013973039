import { apiEndpoints, sendRequest } from 'API';
import { toast } from 'react-toastify';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

const defaultParameters = {
  size: 10,
  totalPages: 1,
  page: 1,
  sortBy: '',
  sortType: 'desc',
  dFrom: '',
  dTo: '',
  filterBy: '',
  search: '',
};

const setSearchParameters = item => ({
  type: SET_SEARCH_PARAMETERS,
  item,
});

// eslint-disable-next-line consistent-return
const fetchResults = async params => {
  try {
    const { data } = await sendRequest.get(apiEndpoints.pagination(params));
    return data;
  } catch (e) {
    toast.error(
      'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z dostawcą oprogramowania',
    );
  }
};

// eslint-disable-next-line consistent-return
const getResultsAction = (type, newValue, category = '') => async (
  dispatch,
  getState,
) => {
  const { pagination } = getState();
  const parametersName = pagination[`${type}Parameters`]
    ? `${type}Parameters`
    : 'defaultParameters';

  const prevParams = pagination[parametersName];

  const params =
    newValue === 'clear'
      ? { ...defaultParameters, type, category, uId: prevParams.uId }
      : {
          ...prevParams,
          ...newValue,
          //path before params
          category,
          type,
        };

  try {
    const { content, data, evidences } = await fetchResults(params);
    dispatch(
      setSearchParameters({
        ...params,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
      }),
    );

    return { content, evidences };
  } catch (e) {
    toast.error(
      'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z dostawcą oprogramowania',
    );
  }
};

export default { getResultsAction };
