import styled from 'styled-components';
import theme from 'styles/theme';

export const ErrorTitle = styled.div`
  h1 {
    font-size: 28px;
    font-weight: 700;
  }
  p {
    font-size: 22px;
    margin-bottom: 35px;
    color: ${theme.textSecondaryColor};
  }

  a {
    color: blue;
    text-decoration: underline;
  }
`;
