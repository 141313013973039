import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, SocialLogin } from 'm-web-components';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Img from 'assets/images/Profil_zaufany_logo.png';
import { EPUAP_AUTH_URL } from 'API/constants';
import { device } from 'styles/devices';
import { Link } from 'react-router-dom';
import { loginEpuap } from 'auth/auth';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  width: 100%;
  max-width: 380px;

  h3 {
    font-weight: 700;
  }

  @media ${device.tablet} {
    h3 {
      font-size: 28px;
    }
  }
`;

const Footer = styled.footer`
  margin-top: 100px;

  p:nth-child(1) {
    font-weight: 700;
    font-size: 20px;
  }

  p:nth-child(2) {
    color: rgba(122, 122, 122, 1);
    font-size: 16px;
  }
`;

const PuapForm = ({ title, footer }) => {
  const formRef = useRef();
  const [redirect, setRedirect] = useState(null);
  const customList = [
    {
      name: 'ePuap',
      title: <ArrowForwardIosIcon />,
      img: Img,
      url: '#',
    },
  ];

  const login = async () => {
    try {
      const { data } = await loginEpuap();
      setRedirect(data);
    } catch (e) {
      toast.error(
        'Wystąpił błąd podczas próby przekierowania do strony logowania',
      );
    }
  };

  useEffect(() => {
    if (redirect) formRef.current?.submit();
  }, [redirect]);

  return (
    <Wrapper>
      <h3>{title}</h3>
      <SocialLogin
        fontSize='40px'
        customList={customList}
        buttonProps={{
          onClick: login,
          buttonBg: '#fff',
          padding: '20px',
          maxWidth: '100%',
          height: '120px',
          style: {
            border: 'none',
            boxShadow: '2px 2px 15px 10px rgba(12, 11, 38, 0.1)',
          },
        }}
      />
      <Footer>
        <p>{footer.question}</p>
        <p>{footer.info}</p>
        <Link to={footer.url}>
          <Button buttonBg='white' fontWeight='600' padding='10px 40px'>
            {footer.button}
          </Button>
        </Link>
      </Footer>
      <form
        ref={formRef}
        style={{ display: 'none' }}
        method='post'
        action='https://symulator.login.gov.pl/login/SingleSignOnService'
      >
        <input type='hidden' name='SAMLRequest' value={redirect} />
      </form>
    </Wrapper>
  );
};
export default PuapForm;
