import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { device } from 'styles/devices';

export const Input = styled(TextField)`
  width: 300px;

  @media ${device.desktop} {
    width: 380px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.primary};
  }
`;
