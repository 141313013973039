import { SET_CURRENT_CONVERSATION } from 'actions/currentConversation.action';

const INITIAL_STATE = {
  currentConversation: null,
  currentConversationId: null,
};

const currentConvesationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_CONVERSATION: {
      return { ...state, currentConversation: action.item };
    }

    default:
      return state;
  }
};

export default currentConvesationReducer;
