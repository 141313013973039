import paginationActions from 'actions/pagination.action';

export const SET_CURRENT_PAGE_CONSUMPTIONS = 'SET_CURRENT_PAGE_CONSUMPTIONS';
export const SET_EVIDENCES_CONSUMPTIONS = 'SET_EVIDENCES_CONSUMPTIONS';

const setCurrentPageConsumption = item => ({
  type: SET_CURRENT_PAGE_CONSUMPTIONS,
  item,
});

const setEvidencesConsumption = item => ({
  type: SET_EVIDENCES_CONSUMPTIONS,
  item,
});

const consumptionGetAction = (
  type = 'consumption',
  newValue,
  category,
) => async dispatch => {
  const { content, evidences } = await dispatch(
    paginationActions.getResultsAction(type, newValue, category),
  );
  dispatch(setCurrentPageConsumption(content));
  dispatch(setEvidencesConsumption(evidences));
};

export default {
  setCurrentPageConsumption,
  consumptionGetAction,
};
