import React from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { Button } from 'm-web-components';
import { modalStyle } from 'components/Shared/ModalStyles/ModalStyles';
import { apiEndpoints, sendRequest } from 'API';
import allActions from 'actions';
import {
  DeleteModalContainer,
  ButtonSecondary,
  ButtonsContainer,
} from 'components/ImportedFiles/ImportedFiles.css';

import CloseX from 'assets/icons/close_X.svg';

Modal.setAppElement('#root');

const DeleteModal = ({ isDeleteModalOpen, toggleDeleteModal }) => {
  const dispatch = useDispatch();

  const handleDeleteFileSubmit = () => {
    const deleteFile = async () => {
      try {
        await sendRequest.delete(apiEndpoints.regulation);
        toast.success('Regulamin został usunięty');
      } catch (e) {
        toast.error('Wystąpił błąd podczas usuwania pliku');
      } finally {
        dispatch(allActions.authActions.getCurrentUserAction());
      }
    };
    deleteFile();
    toggleDeleteModal();
  };

  return (
    <Modal
      isOpen={isDeleteModalOpen}
      contentLabel='Usuwanie pliku'
      onRequestClose={toggleDeleteModal}
      style={modalStyle}
    >
      <img src={CloseX} onClick={toggleDeleteModal} />
      <DeleteModalContainer>
        <h2>Czy na pewno chcesz usunąć regulamin?</h2>
        <p>
          Regulamin zostanie trwale usunięty. Nie będzie możliwości przywrócenia
          go.
        </p>
        <ButtonsContainer>
          <ButtonSecondary onClick={toggleDeleteModal} type='button'>
            Anuluj
          </ButtonSecondary>
          <Button
            onClick={handleDeleteFileSubmit}
            type='button'
            colorType='primary'
          >
            Usuń
          </Button>
        </ButtonsContainer>
      </DeleteModalContainer>
    </Modal>
  );
};

export default DeleteModal;
