import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form } from 'm-web-components';

import { useDispatch, useSelector } from 'react-redux';
import allActions from 'actions';
import { device } from 'styles/devices';

import { formFields, businessFormFields } from './formFields';
import HorizontalMenuSwitch from 'components/Shared/HorizontalMenuSwitch/HorizontalMenuSwitch';
import { apiEndpoints, sendRequestBlob } from 'API';
import { toast } from 'react-toastify';
import { Redirect, useLocation } from 'react-router';

const Wrapper = styled.div`
  width: 100%;
  max-width: 380px;

  h3 {
    font-weight: 700;
  }

  @media ${device.tablet} {
    h3 {
      font-size: 22px;
    }
  }

  form > div {
    margin: 10px 0;
  }
`;

const Buttons = styled.div`
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
`;

const SignupForm = () => {
  const dispatch = useDispatch();
  const [isBusiness, setIsBusiness] = useState(false);
  const auth = useSelector(state => state.auth);
  const [isLogin, setIsLogin] = useState(auth.isAuthenticated);
  const search = useLocation().search;
  const name = new URLSearchParams(search).get('firstName');
  const surname = new URLSearchParams(search).get('secondName');
  const email = new URLSearchParams(search).get('email');

  useEffect(() => {
    setIsLogin(auth.isAuthenticated);
  }, [auth.isAuthenticated]);

  const handleSubmit = async data => {
    dispatch(allActions.authActions.signupAction(data));
  };

  const horizontalMenuButtons = [
    { type: false, label: 'Klient indywidualny' },
    { type: true, label: 'Klient biznesowy' },
  ];

  const showRegulation = async () => {
    try {
      const { data } = await sendRequestBlob.get(apiEndpoints.getRegulations);

      const blob = new Blob([data], {
        type: 'application/pdf',
      });
      const url = URL.createObjectURL(blob);
      window.open(url);
      URL.revokeObjectURL(url);
    } catch (e) {
      toast.error('Wystąpił błąd podczas pobierania pliku');
    }
  };

  if (isLogin) {
    return <Redirect to='/' />;
  }

  return (
    <Wrapper>
      <h3>Krok 2: Rejestracja konta</h3>
      <Buttons>
        <HorizontalMenuSwitch
          buttons={horizontalMenuButtons}
          onClick={setIsBusiness}
          currentType={isBusiness}
        />
      </Buttons>
      <Form
        defaultValues={{ name, surname, email }}
        onSubmit={handleSubmit}
        formFields={
          isBusiness
            ? businessFormFields(showRegulation)
            : formFields(showRegulation)
        }
        labelSubmit='Zarejestruj się'
        center
        buttonProps={{
          colorType: 'loginAdmin',
          padding: '10px 50px',
        }}
      />
    </Wrapper>
  );
};

export default SignupForm;
