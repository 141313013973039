import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'm-web-components';
import styled from 'styled-components';
import allActions from 'actions';

import { formatDate } from 'helpers/newDateFormat';
import SingleFilter from './SingleFilter';

const ButtonStyled = styled(Button)`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.primary};
  margin-left: auto;
  border-radius: 5px;
`;

const FiltersList = ({ type, filtersTable = [], category }) => {
  const dispatch = useDispatch();
  const nameTable = filtersTable[0]?.nameTable;

  const filtersValues = useSelector(state => state.filtersTable[nameTable]);

  //only for facutres // if we have different types of dates
  const [currentDateFilter, setCurrentDateFilter] = useState('');

  const actionsName = `${type}Actions`;
  const getActionName = `${type}GetAction`;

  const clearFilters = () => {
    //clear filterTable reducer
    dispatch(allActions.filtersTableActions.clearUserFiltersTable(nameTable));
    //clear pagination reducer
    dispatch(allActions[actionsName][getActionName](type, 'clear', category));

    setCurrentDateFilter('');
  };

  const onSubmit = () => {
    let dataToReq = { ...filtersValues };

    if (currentDateFilter.includes('PaymentDate')) {
      dataToReq = {
        ...dataToReq,
        dFrom: formatDate(filtersValues.startDatePaymentDate),
        dTo: formatDate(filtersValues.endDatePaymentDate),
        filterBy: 'PaymentDate',
      };
    }

    if (currentDateFilter.includes('CreationDate')) {
      dataToReq = {
        ...dataToReq,
        dFrom: formatDate(filtersValues.startDateCreationDate),
        dTo: formatDate(filtersValues.endDateCreationDate),
        filterBy: 'CreationDate',
      };
    }

    if (currentDateFilter.includes('UploadDate')) {
      dataToReq = {
        ...dataToReq,
        dFrom: formatDate(filtersValues.startDateUploadDate),
        dTo: formatDate(filtersValues.endDateUploadDate),
        filterBy: 'UploadDate',
      };
    }

    dispatch(allActions[actionsName][getActionName](type, dataToReq, category));
  };

  return (
    <>
      {filtersTable.map(el => (
        <SingleFilter
          key={el.name}
          filterType={el.type}
          name={el.name}
          nameTable={el.nameTable}
          label={el.label}
          //only for facutres // if we have different types of dates
          currentDateFilter={currentDateFilter}
          setCurrentDateFilter={setCurrentDateFilter}
          //for list with checkboxes
          checkboxes={el.checkboxes}
          //for radios
          radios={el.radios}
        />
      ))}

      <div style={{ display: 'flex', marginTop: '20px' }}>
        <ButtonStyled type='button' onClick={clearFilters}>
          Wyczyść filtry
        </ButtonStyled>
        <Button
          type='button'
          colorType='primary'
          onClick={onSubmit}
          padding='2px 30px'
        >
          Filtruj
        </Button>
      </div>
    </>
  );
};

export default FiltersList;
