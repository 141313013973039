import {
  SET_USER_FILTER_TABLE,
  CLEAR_USER_FILTER_TABLE,
} from 'actions/filtersTable.action';

const INITIAL_STATE = {
  userFactures: {
    startDateCreationDate: null,
    endDateCreationDate: null,
    startDatePaymentDate: null,
    endDatePaymentDate: null,
  },
  importedFiles: {
    startDateUploadDate: null,
    endDateUploadDate: null,
  },
  userCommunicator: {
    dFrom: null,
    dTo: null,
    ids: [],
  },
  users: {
    userStatus: '',
  },
  consumption: {
    startDateCreationDate: null,
    endDateCreationDate: null,
  },
  systemLogs: {
    userTypes: [],
    logTypes: [],
    startDateUploadDate: null,
    endDateUploadDate: null,
  },
};

const filtersTableReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER_FILTER_TABLE:
      return {
        ...state,
        [action.item.nameTable]: {
          ...state[action.item.nameTable],
          [action.item.nameFilter]: action.item.value,
        },
      };

    case CLEAR_USER_FILTER_TABLE:
      return {
        ...state,
        [action.item]: INITIAL_STATE[action.item],
      };

    default:
      return state;
  }
};

export default filtersTableReducer;
