import styled from 'styled-components';
import { device } from 'styles/devices';

const MainWrapper = styled.main`
  max-width: 100vw;
  height: 100%;
  padding: 0px;
  margin: 20px;

  @media ${device.tablet} {
    max-width: calc(100vw - 270px);
    margin-left: 270px;
  }
`;

export default MainWrapper;
