import { getCurrentUser, login, signup, logoutEpuap } from 'auth/auth';
import { ACCESS_TOKEN, EXPIRE_TOKEN } from 'API/constants';
import { toast } from 'react-toastify';

export const SET_USER = 'SET_USER';
export const SET_AUTHENTICATED_TRUE = 'SET_AUTHENTICATED_TRUE';
export const SET_AUTHENTICATED_FALSE = 'SET_AUTHENTICATED_FALSE';
export const LOGOUT = 'LOGOUT';

const getCurrentUserAction = () => async dispatch => {
  try {
    const { data } = await getCurrentUser();

    dispatch({ type: SET_USER, data });
    dispatch({ type: SET_AUTHENTICATED_TRUE });
  } catch (e) {
    // eslint-disable-next-line no-console
    if (
      (e?.response?.status === 401 || e?.response?.status === 403) &&
      !location.pathname.includes('login') &&
      !location.pathname.includes('signup') &&
      !location.pathname.includes('deactivation')
    ) {
      window.location.replace('/auth/login');
    }
    throw e;
  }
  return 'done';
};

const loginAction = loginRequest => async dispatch => {
  try {
    const { data } = await login(loginRequest);
    dispatch(setToken(data));
    toast.success('Nastąpiło poprawne zalogowanie!');
  } catch (e) {
    if (e?.response?.status === 403) {
      toast.error(
        'Podany adres e-mail lub hasło są nieprawidłowe. Spróbuj ponownie',
        {
          position: 'top-left',
        },
      );
    }
    // eslint-disable-next-line no-console
    console.log(e);
  }
  return 'done';
};

const setToken = token => async dispatch => {
  localStorage.setItem(ACCESS_TOKEN, `${token}`);
  localStorage.setItem(
    EXPIRE_TOKEN,
    new Date().getTime() + 24 * 60 * 60 * 1000,
  );

  dispatch({ type: SET_AUTHENTICATED_TRUE });
};

const signupAction = signupRequest => async dispatch => {
  try {
    const { data } = await signup(signupRequest);
    dispatch(setToken(data));
    toast.success('Nastąpiła poprawna rejestracja !');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    toast.error(
      'Wystąpił problem podczas tworzenia konta w systemie, spróbuj ponownie, bądź skontaktuj się z administracją.',
    );
  }
  return 'done';
};

const logoutAction = () => async dispatch => {
  try {
    await logoutEpuap();
    dispatch({ type: LOGOUT });
    localStorage.removeItem(ACCESS_TOKEN);
    toast.success('Zostałeś bezpiecznie wylogowany!');
    window.location.replace('/auth/login');
    return 'done';
  } catch (e) {
    toast.error('Wystąpił problem podczas wylogowania.');
  }
};

const deactivationAccountAction = () => async dispatch => {
  try {
    await logoutEpuap();
    dispatch({ type: LOGOUT });
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(EXPIRE_TOKEN);
    toast.success(
      'Zostałeś bezpiecznie wylogowany, a Twoje konto zostało dezaktywowane',
    );
    window.location.replace('/auth/deactivation');
    return 'done';
  } catch (e) {
    toast.error('Wystąpił problem podczas dezaktywacji konta.');
  }
};

const checkTokenExpire = () => async dispatch => {
  const tokenExpire = localStorage.getItem(EXPIRE_TOKEN);

  if (new Date().getTime() > tokenExpire) {
    dispatch(logoutAction());
  }
};

export default {
  setToken,
  loginAction,
  signupAction,
  getCurrentUserAction,
  logoutAction,
  deactivationAccountAction,
  checkTokenExpire,
};
