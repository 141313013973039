import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Pagination, Select, Button } from 'm-web-components';
import { Table, Thead, Tbody, Tr, Th } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import * as S from './Table.css';
import SearchTable from './SearchTable';
import useSortResults from './useSortResults';
import FiltersTable from './Filters/FiltersTable';
import HorizontalMenuSwitch from 'components/Shared/HorizontalMenuSwitch/HorizontalMenuSwitch';

const options = [
  { label: '5 wyników', value: 5 },
  { label: '10 wyników', value: 10 },
  { label: '15 wyników', value: 15 },
  { label: '20 wyników', value: 20 },
  { label: '50 wyników', value: 50 },
];

const TableComponent = ({
  columns,
  rows,
  type = 'factures',
  getValues,
  searchPlaceholder,
  simple,
  filtersTable,
  horizontalMenu,
  category,
  evidences,
  menuButtonOnClick,
}) => {
  const { changeSort } = useSortResults(type, category);
  const pagination = useSelector(state => state.pagination);
  const { evidences: evidencesList } = useSelector(state => state[type]);
  const parametersName = `${type}Parameters`;
  const { size, totalPages } =
    pagination[parametersName] || pagination.defaultParameters;

  const changeSize = value => getValues(type, { size: value.value }, category);
  const changePage = (event, value) =>
    getValues(type, { page: value }, category);
  const changeEvidenceParam = value =>
    getValues(type, { eNr: value.value }, category);

  useEffect(() => {
    //clear filters
    return () => {
      if (getValues) {
        getValues(type, 'clear', category);
      }
    };
  }, []);

  const header = columns.map(el => (
    <Th
      onClick={el.sorting ? () => changeSort(el.fieldName) : null}
      key={el.title}
      style={{ cursor: `${el.sorting && 'pointer'}` }}
    >
      {el.title} {el.sorting && !simple && <ImportExportIcon />}
    </Th>
  ));

  const evidencesOptions = evidencesList?.map(el => ({
    value: el.id,
    label: el.displayName,
  }));

  // !simple - for table at home
  return (
    <S.Wrapper simple={simple}>
      {!simple && (
        <S.Header>
          {horizontalMenu && (
            <S.Menu>
              <HorizontalMenuSwitch
                buttons={horizontalMenu.horizontalMenuButtons}
                onClick={horizontalMenu.setCurrentType}
                currentType={horizontalMenu.curentType}
                big
              />
            </S.Menu>
          )}

          {menuButtonOnClick && (
            <Button
              onClick={menuButtonOnClick}
              type='button'
              colorType='primary'
            >
              + Importuj pliki
            </Button>
          )}

          {/* for factures, consumption, reports USER */}
          {evidences && category !== 'admin/' && (
            <Select
              styles={{
                ...S.customSelectStyles,
                container: provided => ({
                  ...provided,
                  maxWidth: '350px',
                  minWidth: '350px',
                }),
              }}
              onChange={changeEvidenceParam}
              placeholder='Wszystkie numery ewidencyjne'
              options={[
                { value: '', label: 'Wszystkie numery ewidencyjne' },
                ...evidencesOptions,
              ]}
            />
          )}
          {/* trick - for admin table, where we dont have elements on left and flex cant create space between them */}
          <div />

          <aside style={{ marginLeft: 'auto' }}>
            <SearchTable
              searchPlaceholder={searchPlaceholder}
              type={type}
              category={category}
            />
            <FiltersTable
              type={type}
              filtersTable={filtersTable}
              category={category}
            />
          </aside>
        </S.Header>
      )}
      <Table>
        <Thead>
          <Tr>{header}</Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </Table>
      {!simple && (
        <Pagination
          changePage={changePage}
          changeSize={changeSize}
          countPages={totalPages}
          size={size}
          optionsPagination={options}
          customStyles={S.customStyles}
          customSelectStyles={S.customSelectStyles}
        />
      )}
    </S.Wrapper>
  );
};

export default TableComponent;
