import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'actions';

import MainWrapper from 'components/atoms/MainWrapper';
import ConsumptionTable from 'components/Consumption/ConsumptionTable';
import Title from 'components/atoms/Title';

const Consumption = () => {
  const dispatch = useDispatch();
  const { result } = useSelector(state => state.consumption);

  const getValues = (type, newValue) => {
    dispatch(
      allActions.consumptionActions.consumptionGetAction(
        type,
        newValue,
        'user/',
      ),
    );
  };
  useEffect(() => {
    getValues('consumption', { uId: '' }, 'user/');
  }, []);

  return (
    <MainWrapper>
      <Title>Zużycie</Title>
      <ConsumptionTable
        dataTable={result}
        getValues={getValues}
        category='user/'
      />
    </MainWrapper>
  );
};

export default Consumption;
