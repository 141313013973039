import React from 'react';

import Communicator from 'components/Communicator/Communicator';

const UserCommunicator = ({ userData }) => (
  <div style={{ maxHeight: '90%', height: '90%' }}>
    <Communicator userData={userData} />
  </div>
);

export default UserCommunicator;
