import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import { Button } from 'm-web-components';
import { apiEndpoints, sendRequest } from 'API';
import { ACCESS_TOKEN } from 'API/constants';
import allActions from 'actions';

import Padlock from 'assets/icons/padlock.svg';

import {
  SettingsSection,
  SettingsSectionHeader,
  ButtonSecondary,
  SettinsSubsectionTitle,
  UserData,
  UserDataInfo,
} from '../Shared/Settings/Settings.css';

const SingleData = ({ isEditMode, title, data }) => (
  <UserData isEditMode={isEditMode}>
    <h4>{title}</h4>
    <label>{data}</label>
  </UserData>
);

const AccountSettings = ({ userData }) => {
  const details = userData?.userDetails;
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [userContactData, setUserContactData] = useState({
    email: null,
    phoneNumber: null,
  });

  const toggleEdit = () => {
    setIsEditMode(prev => !prev);
  };

  const handleInputChange = (e, field) => {
    setUserContactData(prev => ({
      ...prev,
      [field]: e.target.value !== '' ? e.target.value : null,
    }));
  };

  const handleSave = e => {
    e.preventDefault();
    const putContact = async () => {
      try {
        const { data } = await sendRequest.put(
          apiEndpoints.putUserContact,
          userContactData,
        );
        if (data) {
          localStorage.setItem(ACCESS_TOKEN, `${data}`);
        }
        dispatch(allActions.authActions.getCurrentUserAction());

        toast.success('Dane zostały zaktualizowane');
      } catch (e) {
        toast.error('Nie udało się zapisać danych');
      }
    };
    putContact();
    toggleEdit();
  };

  const mapEvidences = details.evidences
    ?.split(', ')
    ?.map((el, index) => (
      <SingleData
        key={el}
        isEditMode={isEditMode}
        title={`numer ewidencyjny ${index + 1}`}
        data={el}
      />
    ));

  return (
    <SettingsSection>
      <SettingsSectionHeader>
        <h2>Dane konta</h2>
        {userData.permissions.includes('EDIT_MY_DETAILS') && (
          <>
            {isEditMode ? (
              <>
                <ButtonSecondary type='button' onClick={toggleEdit}>
                  Anuluj
                </ButtonSecondary>
                <Button
                  type='submit'
                  colorType='primary'
                  padding='2px 30px'
                  form='user_form'
                >
                  Zapisz
                </Button>
              </>
            ) : (
              <Button
                type='button'
                colorType='primary'
                onClick={toggleEdit}
                padding='2px 30px'
              >
                Edytuj
              </Button>
            )}
          </>
        )}
      </SettingsSectionHeader>
      <SettinsSubsectionTitle>Dane użytkownika</SettinsSubsectionTitle>
      {isEditMode && (
        <UserDataInfo>
          <img src={Padlock} alt='padlock' />
          Nie posiadasz uprawnień do zarządzanie tymi danymi. Skontaktuj się z
          urzędem aby uzyskać więcej informacji.
        </UserDataInfo>
      )}
      <SingleData isEditMode={isEditMode} title={'imie'} data={details.name} />
      <SingleData
        isEditMode={isEditMode}
        title={'nazwisko'}
        data={details.surname}
      />
      {details.nip && (
        <>
          <SingleData
            isEditMode={isEditMode}
            title='nazwa firmy'
            data={details.companyName}
          />
          <SingleData isEditMode={isEditMode} title='nip' data={details.nip} />
        </>
      )}
      <SingleData
        isEditMode={isEditMode}
        title='adres'
        data={details.address}
      />
      <SingleData
        isEditMode={isEditMode}
        title='miejscowość'
        data={details.city}
      />
      {mapEvidences}
      <SingleData
        isEditMode={isEditMode}
        title='identyfikator'
        data={details.id}
      />
      <SettinsSubsectionTitle>Informacje kontaktowe</SettinsSubsectionTitle>
      <form id='user_form' onSubmit={handleSave}>
        <UserData isEditMode={isEditMode}>
          <h4>e-mail</h4>
          <input
            type='email'
            pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$'
            placeholder={details.email}
            onChange={e => handleInputChange(e, 'email')}
            disabled={true}
          />
        </UserData>
        <UserData isEditMode={isEditMode}>
          <h4>telefon</h4>
          <input
            type='tel'
            placeholder={details.phoneNumber}
            onChange={e => handleInputChange(e, 'phoneNumber')}
            disabled={!isEditMode}
          />
        </UserData>
      </form>
    </SettingsSection>
  );
};

export default AccountSettings;
