import React from 'react';

import MainWrapper from 'components/atoms/MainWrapper';
import Title from 'components/atoms/Title';

import Chart from 'components/Chart/Chart';

//temporary

const elementsFirstChart = [{ id: 1, dataKey: 'usage', fill: '#1B69F2' }];
const elementsSecondChart = [{ id: 1, dataKey: 'charge', fill: '#1B69F2' }];

const Raports = () => {
  return (
    <MainWrapper>
      <Title>Raporty</Title>

      <Chart elements={elementsFirstChart} title='Zużycie (m3)' />
      <Chart elements={elementsSecondChart} title='Opłaty (PLN)' />
    </MainWrapper>
  );
};

export default Raports;
