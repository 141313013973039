import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import {
  ModalSaveButton,
  CancelButton,
  AddTopicButton,
  AddTopicModalForm,
  DeleteTopicButton,
  SingleTopicWrapper,
  TitlesListWrapper,
  ModalTitle,
} from './Communicator.css';

import TrashCan from 'assets/icons/trash_can.svg';
import CloseX from 'assets/icons/close_X.svg';

const AddTopicModal = ({
  isModalOpen,
  toggleModal,
  modalStyle,
  topicOptions,
  handleSaveTopics,
  handleDeleteTopic,
}) => {
  const scrollHelper = useRef(null);
  const [newTopicOptions, setNewTopicOptions] = useState([...topicOptions]);

  useEffect(() => {
    setNewTopicOptions([...topicOptions]);
  }, [topicOptions]);

  useEffect(() => {
    scrollHelper.current?.scrollIntoView({ behavior: 'smooth' });
  }, [newTopicOptions]);

  const handleSave = e => {
    e.preventDefault();

    handleSaveTopics(newTopicOptions);

    toggleModal();
  };

  const handleTopicDelete = (e, index) => {
    e.preventDefault();
    if (newTopicOptions[index].id === null) {
      setNewTopicOptions(prev => prev.filter((el, i) => index !== i));
    } else {
      handleDeleteTopic(newTopicOptions[index].id);
    }
  };

  const handleAdd = () => {
    setNewTopicOptions(prev => [...prev, { id: null, name: '' }]);
  };

  const handleInputChange = (e, index) => {
    //********* TODO: There must be be better way to do that
    newTopicOptions[index].name = e.target.value;
  };

  const mapTopicOptions = newTopicOptions?.map((topic, index) => (
    <SingleTopicWrapper key={`wrapper_${topic.name}_${index}`}>
      <h3>{topic.id ? `TEMAT ${topic.id}` : 'NOWY TEMAT'}</h3>
      <div>
        <input
          onChange={e => handleInputChange(e, index)}
          placeholder='Wpisz temat'
          defaultValue={topic.name}
        />
        <DeleteTopicButton onClick={e => handleTopicDelete(e, index)}>
          <img src={TrashCan} alt='ikona kosza' />
          Usuń
        </DeleteTopicButton>
      </div>
    </SingleTopicWrapper>
  ));

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel='Załącznik'
      onRequestClose={toggleModal}
      style={modalStyle}
    >
      <img
        src={CloseX}
        alt='zamknij'
        style={{ marginLeft: '90%' }}
        onClick={toggleModal}
      />
      <AddTopicModalForm onSubmit={handleSave}>
        <ModalTitle>Edycja tematów wiadomości</ModalTitle>
        <TitlesListWrapper>
          {mapTopicOptions}
          <div ref={scrollHelper} />
        </TitlesListWrapper>
        <AddTopicButton type='button' onClick={handleAdd}>
          + Dodaj
        </AddTopicButton>
        <br />
        <CancelButton type='button' onClick={toggleModal}>
          Anuluj
        </CancelButton>
        <ModalSaveButton type='submit'>Zapisz</ModalSaveButton>
      </AddTopicModalForm>
    </Modal>
  );
};

export default AddTopicModal;
