import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { formatDistanceStrict } from 'date-fns';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import { pl } from 'date-fns/locale';

import { apiEndpoints, sendRequest } from 'API';
import { ClickAwayListener } from '@material-ui/core';
import allActions from 'actions';

import * as S from './Notification.css';
import { useHistory, useLocation } from 'react-router';

const Notification = ({ role }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [notificationList, setNotificationList] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);

  const getNotifications = async () => {
    const { data } = await sendRequest.get(apiEndpoints.notifications);
    setNotificationList(data.notifications);
  };

  const pickNotification = async (id, link) => {
    await sendRequest.delete(apiEndpoints.deleteOneNotification(id));
    getNotifications();
    setOpenNotification(false);
    history.push(link);
  };

  const deleteAll = async () => {
    await sendRequest.delete(apiEndpoints.notifications);
    getNotifications();
    setOpenNotification(false);
  };

  useEffect(() => {
    getNotifications();

    // it is here becouse this function is execute every time we change page
    dispatch(allActions.authActions.checkTokenExpire());
    dispatch(allActions.authActions.getCurrentUserAction());
  }, [pathname]);

  const notificationListRender = notificationList.map(el => {
    const date = formatDistanceStrict(new Date(el.date), new Date(), {
      roundingMethod: 'ceil',
      locale: pl,
      addSuffix: true,
    });

    const link = `/${role === 'Użytkownik' ? 'user' : 'admin'}/${
      el.type === 'FACTURE' ? 'invoices' : 'communicator'
    }`;

    return (
      <S.SingleNotification
        key={el.notificationId}
        onClick={() => pickNotification(el.notificationId, link)}
      >
        {el.type === 'FACTURE' ? (
          <DescriptionOutlinedIcon />
        ) : (
          <ForumOutlinedIcon />
        )}
        <S.Info>
          <p>{el.message}</p>
          <S.Date>{date}</S.Date>
        </S.Info>
      </S.SingleNotification>
    );
  });

  return (
    <ClickAwayListener onClickAway={() => setOpenNotification(false)}>
      <S.FiltersContainer>
        <S.FiltersButtonToggle
          type='button'
          onClick={() => setOpenNotification(prev => !prev)}
        >
          <Badge
            badgeContent={notificationList.length}
            color='secondary'
            style={{ zIndex: 0 }}
          >
            <NotificationsNoneIcon fontSize='large' />
          </Badge>
        </S.FiltersButtonToggle>
        <S.FiltersStyled openNotification={openNotification}>
          <header>
            <h4>Powiadomienia</h4>
            {notificationList.length !== 0 && (
              <S.ButtonRemove type='button' onClick={deleteAll}>
                Usuń wszystkie
              </S.ButtonRemove>
            )}
          </header>
          {notificationList.length === 0 ? (
            <S.NoNotification>
              Nie masz obecnie żadnych powiadomień
            </S.NoNotification>
          ) : (
            notificationListRender
          )}
        </S.FiltersStyled>
      </S.FiltersContainer>
    </ClickAwayListener>
  );
};

export default Notification;
