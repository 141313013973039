import authActions from './auth.action';
import facturesActions from './factures.action';
import communicatorActions from './communicator.action';
import currentConversationActions from './currentConversation.action';
import filtersTableActions from './filtersTable.action';
import usersActions from './users.action';
import filesActions from './files.action';
import chatTopicActions from './chatTopics.action';
import consumptionActions from './consumption.action';
import logsActions from './logs.action';

const allActions = {
  authActions,
  facturesActions,
  communicatorActions,
  currentConversationActions,
  filtersTableActions,
  chatTopicActions,
  consumptionActions,
  filesActions,
  usersActions,
  logsActions,
};

export default allActions;
