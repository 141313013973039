import React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 9.5,
    justifyContent: 'space-between',
  },
  title: {
    padding: '3px 6px',
    borderBottom: '1pt solid #000000',
    fontFamily: 'RobotoBold',
  },
  sum: {
    padding: '8px 6px 3px',
    borderBottom: '2pt solid #2473db',
    fontFamily: 'RobotoBold',
    fontSize: 10,
  },
  text: {
    padding: '3px 6px',
    borderBottom: '1pt solid #ddd',
    maxWidth: 300,
  },
  row: {
    flexGrow: 1,
  },
});

const SingleColumn = ({ data, title, unit, sum }) => (
  <View style={styles.row}>
    <Text style={styles.title}>{title}</Text>
    {data.map((el, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Text key={i} style={styles.text}>
        {el?.toString().slice(0, 45)} {unit}
      </Text>
    ))}
    {sum && (
      <Text style={styles.sum}>
        {sum.toString()}{' '}
        {unit !== '%' && title !== 'Cena jedn. netto' ? unit : ''}
      </Text>
    )}
  </View>
);

const FactureList = ({ list, total }) => (
  <View style={styles.header}>
    <SingleColumn data={list.map((el, i) => i + 1)} title='LP' />
    <SingleColumn
      data={list.map(el => el.name)}
      title='Nazwa artykułu/usługi'
    />
    <SingleColumn data={list.map(el => el.pkwiu)} title='PKWiU' />
    <SingleColumn data={list.map(el => el.quantity)} title='Ilość' />
    <SingleColumn data={list.map(el => el.unit)} title='Jedn.' />
    <SingleColumn
      data={list.map(el => el.priceNet)}
      title='Cena netto'
      unit='zł'
      sum='RAZEM:'
    />
    <SingleColumn
      data={list.map(el => el.totalNet)}
      title='Wartość netto'
      unit='zł'
      sum={total.totalNet}
    />
    <SingleColumn
      data={list.map(el => el.taxPercentage)}
      title='St. VAT'
      sum=' '
      unit='%'
    />
    <SingleColumn
      data={list.map(el => el.totalTax)}
      title='Kwota VAT'
      unit='zł'
      sum={total.totalVat}
    />
    <SingleColumn
      data={list.map(el => el.totalGross)}
      title='Wartość Brutto'
      unit='zł'
      sum={total.totalGross}
    />
  </View>
);

export default FactureList;
