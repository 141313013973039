import { SET_CURRENT_PAGE_FILES } from 'actions/files.action';

const INITIAL_STATE = {
  files: [],
};

const importedFilesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE_FILES:
      return { ...state, files: action.item };

    default:
      return state;
  }
};

export default importedFilesReducer;
