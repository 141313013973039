import React from 'react';

import PuapForm from 'components/PuapForm/PuapForm';

const LoginForm = () => (
  <PuapForm
    title='Logowanie przy pomocy Profilu Zaufanego'
    footer={{
      question: 'Nie masz jeszcze konta?',
      info: 'Załóż konto w systemie eBOK i wygodnie sprawdzaj swoje faktury.',
      button: 'Przejdź do rejestracji',
      url: '/auth/signup',
    }}
  />
);

export default LoginForm;
