import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import Indicator from 'components/atoms/Indicator';
import { role } from 'auth/auth';
import { routes } from 'routes';

const UserRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector(state => state.auth);

  if (!auth.user || !auth.isAuthenticated) {
    return <Indicator bacgroundColor='white' />;
  }

  return (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated && auth.user.userDetails.role === role.user ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: routes.login,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default UserRoute;
