export const usersTypes = {
  INDIVIDUAL: 'INDIVIDUAL',
  BUSINESS: 'BUSINESS',
  FUNCTIONARY: 'FUNCTIONARY',
  ADMINS: 'ADMIN',
  USER: 'USER',
};

export const statusValues = {
  ACTIVE: 'ACTIVE',
  NOT_ACTIVE: 'NOT_ACTIVE',
  NOT_VERIFIED: 'NOT_VERIFIED',
};

export const statusNames = {
  ACTIVE: 'Aktywny',
  NOT_ACTIVE: 'Nieaktywny',
  NOT_VERIFIED: 'Niezweryfikowany',
};

export const usersFiltersTable = [
  {
    type: 'radio',
    name: 'userStatus',
    nameTable: 'users',
    label: 'Status',
    radios: [
      { name: 'NOT_ACTIVE', label: statusNames.NOT_ACTIVE },
      { name: 'NOT_VERIFIED', label: statusNames.NOT_VERIFIED },
      { name: 'ACTIVE', label: statusNames.ACTIVE },
    ],
  },
];
