import styled from 'styled-components';
import { Button } from 'm-web-components';
import theme from 'styles/theme';
import ErrorSign from 'assets/icons/error_sign.svg';

export const ErrorMessage = styled.span`
  min-width: 150px;
  display: inline-block;
  text-align: right;
  color: #b43236;

  &:before {
    content: url(${ErrorSign});
  }
`;

export const ImportedFilesTitle = styled.h1`
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 20px;
`;

export const DeleteModalContainer = styled.div`
  padding: 20px;

  h2 {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
  }

  p {
    color: ${theme.textSecondaryColor};
    font-size: 16px;
  }
`;

export const ButtonSecondary = styled(Button)`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.primary};
  margin-left: auto;
  border-radius: 5px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;
