import { useState } from 'react';
import allActions from 'actions';
import { useDispatch } from 'react-redux';

const useSortResults = (type, category) => {
  const dispatch = useDispatch();
  const [lastSortBy, setLastSortBy] = useState('');
  const [lastSortType, setLastSortType] = useState(false);

  const actionsName = `${type}Actions`;
  const getActionName = `${type}GetAction`;

  const changeSort = value => {
    const newSortType = lastSortBy === value ? !lastSortType : false;

    const data = {
      sortBy: value,
      sortType: newSortType ? 'desc' : 'asc',
    };

    setLastSortType(newSortType);
    setLastSortBy(value);
    dispatch(allActions[actionsName][getActionName](type, data, category));
  };

  return { changeSort };
};

export default useSortResults;
