import React from 'react';
import { useParams } from 'react-router-dom';

import Chart from 'components/Chart/Chart';

const elementsFirstChart = [{ id: 1, dataKey: 'usage', fill: '#1B69F2' }];
const elementsSecondChart = [{ id: 1, dataKey: 'charge', fill: '#1B69F2' }];

const UserReports = () => {
  const { id } = useParams();

  return (
    <>
      <Chart
        uId={id}
        elements={elementsFirstChart}
        title='Zużycie (m3)'
        category='admin/'
      />
      <Chart
        uId={id}
        elements={elementsSecondChart}
        title='Opłaty (PLN)'
        category='admin/'
      />
    </>
  );
};

export default UserReports;
