import React from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Button } from 'm-web-components';
import { apiEndpoints, sendRequestFormData } from 'API';
import allActions from 'actions';
import {
  baseStyle,
  modalStyle,
} from 'components/Shared/ModalStyles/ModalStyles';

import CloseX from 'assets/icons/close_X.svg';

import { ErrorMessage } from 'components/ImportedFiles/ImportedFiles.css';

Modal.setAppElement('#root');

const AddModal = ({ isModalOpen, toggleModal }) => {
  const dispatch = useDispatch();
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    multiple: false,
    accept: '.pdf',
  });

  const handleAddFileSubmit = () => {
    const formData = new FormData();
    acceptedFiles?.forEach(file => {
      const blob = new Blob([file], { type: 'application/pdf' });
      formData.append('file', blob, file.name);
    });

    const addFiles = async () => {
      try {
        await sendRequestFormData.put(apiEndpoints.regulation, formData);
        toast.success('Plik został dodany poprawnie');
      } catch (e) {
        toast.error('Wystąpił błąd podczas dodawania pliku.');
      } finally {
        dispatch(allActions.authActions.getCurrentUserAction());
      }
    };
    addFiles();
    toggleModal();
  };

  const acceptedFilesItems = acceptedFiles.map(file => (
    <li key={file.path}>{file.path}</li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path}
      {errors.map(e => (
        <ErrorMessage key={e.code}>
          {e.code === 'file-invalid-type' ? 'Błędny format pliku.' : e.code}
        </ErrorMessage>
      ))}
    </li>
  ));

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel='Załącznik'
      onRequestClose={toggleModal}
      style={modalStyle}
    >
      <img src={CloseX} onClick={toggleModal} />
      <div {...getRootProps({ style: baseStyle })}>
        <input {...getInputProps()} />
        <p>(Naciśnij, aby wybrać pliki)</p>
      </div>
      <br />
      <aside>
        <h4>Zaakceptowane pliki:</h4>
        <ul>{acceptedFilesItems}</ul>
        <h4>Odrzucone pliki:</h4>
        <ul>{fileRejectionItems}</ul>
      </aside>
      <br />
      <Button
        onClick={handleAddFileSubmit}
        type='button'
        colorType='primary'
        margin='auto'
      >
        Dodaj regulamin
      </Button>
    </Modal>
  );
};

export default AddModal;
