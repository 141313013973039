import { sendRequest, apiEndpoints } from '../API/index';

export const getCurrentUser = () =>
  sendRequest.get(apiEndpoints.getCurrentUser);

export const login = loginRequest =>
  sendRequest.post(apiEndpoints.loginAdmin, loginRequest);

export const signup = signupRequest =>
  sendRequest.post(apiEndpoints.signup, signupRequest);

export const logoutEpuap = () => sendRequest.get(apiEndpoints.logutEpuap);

export const loginEpuap = () => sendRequest.get(apiEndpoints.loginEpuap);

export const role = {
  user: 'Użytkownik',
  admin: 'Admin',
  functionary: 'Urzędnik',
};
