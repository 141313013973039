import {
  SET_CURRENT_PAGE_FACTURES,
  SET_EVIDENCES_FACTURES,
} from 'actions/factures.action';

const INITIAL_STATE = {
  result: [],
  evidences: [],
};

const facturesReduxer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE_FACTURES:
      return { ...state, result: action.item };

    case SET_EVIDENCES_FACTURES:
      return { ...state, evidences: action.item };

    default:
      return state;
  }
};

export default facturesReduxer;
