import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

import Attachment from 'assets/icons/clarity_attachment-line.svg';
import ArrowBack from 'assets/icons/arrow_back.svg';
import AddTopic from 'assets/icons/add_topic.svg';
import {
  MessagesWindowWrapper,
  MessagesWindowHeader,
  MessageEditorWrapper,
  ReceiverData,
  ConversationMessagesWrapper,
  Button,
  AttachmentButton,
  ArrowBackButton,
  HeaderSelectContainer,
  AddTopicIcon,
  customSelectStyles,
  ButtonsWrapper,
} from './Communicator.css';
import { useSelector } from 'react-redux';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ align: [] }],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],

    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [],
    [{ color: [] }, { background: [] }],

    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const ConversationsWindow = ({
  isNavigatorOpen,
  isNewConversation,
  handleNewConversationMessage,
  handleAttachmentClick,
  topicOptions,
  receiverOptions,
  setSendOptions,
  toggleNavigator,
  toggleAddTopicModal,
}) => {
  const placeholder = isNewConversation
    ? 'Wpisz treść wiadomości...'
    : 'Aby wysłać nową wiadomość naciśnij "+ Nowa wiadomość".';
  const { user } = useSelector(state => state.auth);
  const { quill, quillRef } = useQuill({ placeholder, modules });
  const [selectTopic, setSelectTopic] = useState({});

  const sendOnClick = () => {
    handleNewConversationMessage(quill.container.firstChild.innerHTML);
    quill.setText('');
  };

  const handleOptionChange = (e, type) => {
    setSendOptions(prev => ({
      ...prev,
      [type]: e.value,
    }));
  };

  const mapTopicOptions = () => {
    const mapped = topicOptions.map(el => ({
      value: el.id,
      label: el.name,
    }));
    setSelectTopic(mapped);
  };

  const mapReceiverOptions = receiverOptions.map(el => ({
    value: el.id,
    label: el.name,
  }));

  useEffect(() => {
    mapTopicOptions();
  }, [topicOptions]);

  return (
    <MessagesWindowWrapper isNavigatorOpen={isNavigatorOpen}>
      <MessagesWindowHeader>
        {isNewConversation === true ? (
          <HeaderSelectContainer>
            <ArrowBackButton onClick={toggleNavigator} src={ArrowBack} />
            <h3>Do: </h3>
            <Select
              styles={customSelectStyles}
              onChange={e => handleOptionChange(e, 'userId')}
              placeholder='Wybierz odbiorcę'
              options={mapReceiverOptions}
            />
            <>
              <h3>Temat sprawy: </h3>
              <Select
                key={JSON.stringify(selectTopic)}
                styles={customSelectStyles}
                onChange={e => handleOptionChange(e, 'categoryId')}
                placeholder='Wybierz temat'
                options={selectTopic}
              />
              {user.permissions.includes('CRUD_CATEGORY') && (
                <AddTopicIcon
                  src={AddTopic}
                  alt='ikona dodania tytułu'
                  onClick={toggleAddTopicModal}
                />
              )}
            </>
          </HeaderSelectContainer>
        ) : (
          <ReceiverData>
            <ArrowBackButton onClick={toggleNavigator} src={ArrowBack} />
            <div>
              <h2>Odbiorca</h2>
              <p>Temat sprawy:</p>
            </div>
          </ReceiverData>
        )}
      </MessagesWindowHeader>
      <ConversationMessagesWrapper />
      <MessageEditorWrapper>
        <div className='wrapperEditor'>
          <div ref={quillRef} />
        </div>
        <ButtonsWrapper>
          <AttachmentButton onClick={handleAttachmentClick} src={Attachment} />
          <Button
            onClick={sendOnClick}
            disabled={!isNewConversation}
            style={{ margin: 'auto 2.5% auto 10px' }}
          >
            Wyślij
          </Button>
        </ButtonsWrapper>
      </MessageEditorWrapper>
    </MessagesWindowWrapper>
  );
};

export default ConversationsWindow;
