import { StyleSheet, Font } from '@react-pdf/renderer';

import font from './assets/Roboto-Regular.ttf';
import fontBold from './assets/Roboto-Bold.ttf';
import fontItalic from './assets/Roboto-Italic.ttf';

Font.register({ family: 'Roboto', src: font });
Font.register({ family: 'RobotoBold', src: fontBold });
Font.register({ family: 'RobotoItalic', src: fontItalic });

export const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 25,
    border: '1px solid black',
    fontFamily: 'Roboto',
  },
  h1: {
    fontSize: 22,
    flexGrow: 1,
    margin: 0,
    padding: 0,
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 10,
    paddingTop: 20,
  },
  date: {
    fontSize: 11,
  },
  buyer: {
    paddingRight: 50,
  },
  nrFV: {
    fontFamily: 'RobotoBold',
    fontSize: '12',
  },
  company: {
    width: '40%',
  },
  title: {
    marginBottom: 5,
    fontFamily: 'RobotoItalic',
    borderBottom: '1pt solid #000',
  },
  moreInfo: {
    fontSize: 11,
    width: '40%',
  },
  endData: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sum: {
    fontSize: 10,
    color: '#333',
    maxWidth: '50%',
  },
  sumWords: {
    fontSize: 10,
    textAlign: 'right',
    color: '#777',
    paddingRight: 20,
    maxWidth: 200,
  },
  signatures: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  signature: {
    width: '45%',
    padding: '10px 30px 0',
    fontSize: 10,
    color: '#555',
    textAlign: 'center',
    fontFamily: 'RobotoBold',
  },
  signatureName: {
    height: '20px',
    fontSize: 12,
    borderBottom: '2pt solid #2473db',
    marginBottom: 10,
  },
});
