import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'actions';

import MainWrapper from 'components/atoms/MainWrapper';
import ConsumptionTable from 'components/Consumption/ConsumptionTable';
import Title from 'components/atoms/Title';

const Consumption = () => {
  const dispatch = useDispatch();
  const { result } = useSelector(state => state.consumption);

  const getValues = (type, newValue) => {
    dispatch(
      allActions.consumptionActions.consumptionGetAction(
        type,
        newValue,
        'admin/',
      ),
    );
  };

  useEffect(() => {
    getValues('consumption', { uId: '' }, 'admin/');
  }, []);

  return (
    <MainWrapper>
      <Title>Zużycia</Title>
      <ConsumptionTable
        dataTable={result}
        getValues={getValues}
        category='admin/'
        isAdmin
      />
    </MainWrapper>
  );
};

export default Consumption;
