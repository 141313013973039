const category = {
  USERS: { name: 'Użytkownicy', value: 'users', helper: '' },
  SYSTEM: { name: 'System eBOK', value: 'system', helper: '' },
  PERMISSIONS: { name: 'Uprawnienia', value: 'permissions', helper: '' },
  LOGS: { name: 'Logi systemowe', value: 'logs', helper: '' },
  FILES: { name: 'Importowane pliki', value: 'files', helper: '' },
  CHAT: { name: 'Komunikacja', value: 'chat', helper: '' },
  CHAT_CLIENT: { name: 'Komunikacja', value: 'chat', helper: '_client' },
};

export const sections = {
  Użytkownik: [category.SYSTEM, category.CHAT_CLIENT],
  Urzędnik: [
    category.SYSTEM,
    category.CHAT,
    category.FILES,
    category.USERS,
    category.LOGS,
  ],
  Admin: [
    category.SYSTEM,
    category.CHAT,
    category.FILES,
    category.USERS,
    category.LOGS,
    category.PERMISSIONS,
  ],
};

export const permissions = {
  users: {
    SHOW_OTHERS_DETAILS: {
      text: 'Wyświetlanie profili użytkowników',
      name: 'SHOW_OTHERS_DETAILS',
      status: false,
    },
    EDIT_OTHERS_DETAILS: {
      text: 'Edycja danych konta i usuwanie kont',
      name: 'EDIT_OTHERS_DETAILS',
      status: false,
    },
    EDIT_ACCOUNT_STATUS: {
      text: 'Zmiana statusu konta',
      name: 'EDIT_ACCOUNT_STATUS',
      status: false,
    },
  },
  system: {
    SYSTEM_LOGIN: {
      text: 'Logowanie do systemu',
      name: 'SYSTEM_LOGIN',
      status: false,
    },
    SHOW_MY_DETAILS: {
      text: 'Wyświetlanie danych swojego konta',
      name: 'SHOW_MY_DETAILS',
      status: false,
    },
    EDIT_MY_DETAILS: {
      text: 'Edycja danych swojego konta',
      name: 'EDIT_MY_DETAILS',
      status: false,
    },
  },
  permissions: {
    SHOW_PERMISSIONS: {
      text: 'Wyświetlanie uprawnień',
      name: 'SHOW_PERMISSIONS',
      status: false,
    },
    EDIT_USER_ROLE: {
      text: 'Nadawanie i edycja uprawnień',
      name: 'EDIT_USER_ROLE',
      status: false,
    },
    ADD_ROLE: {
      text: 'Tworzenie nowych ról',
      name: 'ADD_ROLE',
      status: false,
    },
  },
  logs: {
    SHOW_LOGS: {
      text: 'Wyświetlanie logów systemowych',
      name: 'SHOW_LOGS',
      status: false,
    },
  },
  files: {
    CRUD_FILES: {
      text: 'Importowanie i zarządzanie plikami',
      name: 'CRUD_FILES',
      status: false,
    },
  },
  chat_client: {
    SHOW_SEND_MESSAGES: {
      text: 'Wyświetlanie, wysyłanie i odbieranie wiadomości',
      name: 'SHOW_SEND_MESSAGES',
      status: false,
    },
  },
  chat: {
    SHOW_SEND_MESSAGES: {
      text: 'Wyświetlanie, wysyłanie i odbieranie wiadomości',
      name: 'SHOW_SEND_MESSAGES',
      status: false,
    },
    SEND_NEWSLETTER: {
      text: 'Tworzenie i wysyłanie wiadomości newsletter',
      name: 'SEND_NEWSLETTER',
      status: false,
    },
    CRUD_CATEGORY: {
      text: 'Tworzenie i edycja tematów wiadomości',
      name: 'CRUD_CATEGORY',
      status: false,
    },
  },
};
