const theme = {
  primary: '#000428',
  primaryLight: '#1B69F2',
  light: '#aaa9ad',
  titleLight: '#888794',
  secondary: '#3BBFBF',

  loginAdminButtonBg: '#000428',
  loginAdminButtonColor: '#fff',

  primaryButtonBg: '#000428',
  primaryButtonColor: '#fff',

  denyButtonBg: '#fff',
  denyButtonColor: '#000428',

  buttonRadius: '4px',
  mainColor: '#000428',
  secondaryColor: '#262948',
  marginColor: '#1b69f2',
  lightColor: '#f8f8fa',
  fontColor: '#b2b3be',

  lightSecondaryColor: '#fbfcfc',
  shadowColor: '#f2f2f2',
  selectedConversationColor: '#e5edfb',
  topicColor: '#1b69f2',
  myMessageColor: '#c8d8f5',
  incomingMessageColor: 'white',
  messageWindowColor: '#f8f8fa',
  textSecondaryColor: '#7a889f',

  settingsLabel: '#d8d8d8',
  settingsInfo: '#ebebeb',
  settingsSection: 'white',

  //colors status in tables
  ACTIVE: '#3B9833',
  NOT_ACTIVE: '#B43236',
  NOT_VERIFIED: '#C7A362',

  //colors types of logs
  INFO: '#1B69F2',
  DELETE: '#E92828',
  EDIT: '#C7A362',
};

export default theme;
