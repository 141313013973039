import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

import Attachment from 'assets/icons/clarity_attachment-line.svg';
import ArrowBack from 'assets/icons/arrow_back.svg';
import {
  MessagesWindowWrapper,
  MessagesWindowHeader,
  MessageEditorWrapper,
  MessageEditorWrapperOverlay,
  ReceiverData,
  ConversationMessagesWrapper,
  Button,
  AttachmentButton,
  ArrowBackButton,
  ButtonsWrapper,
} from './Communicator.css';
import Message from './Message';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ align: [] }],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],

    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [],
    [{ color: [] }, { background: [] }],

    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const ConversationsWindow = ({
  currentConversation,
  handleAttachmentClick,
  handleSendClick,
  isNavigatorOpen,
  toggleNavigator,
  getAttachment,
  currentUser,
  isAdmin,
}) => {
  const placeholder = 'Wpisz treść wiadomości...';
  const { quill, quillRef } = useQuill({ placeholder, modules });
  const { user } = useSelector(state => state.auth);
  const scrollHelper = useRef(null);

  useEffect(() => {
    scrollHelper.current?.scrollIntoView({ behavior: 'smooth' });
  }, [currentConversation]);

  const sendOnClick = () => {
    handleSendClick(quill.container.firstChild.innerHTML);
    quill.setText('');
  };

  const canSendMessages = () => {
    if (
      (currentConversation.conversationWith === 'Newsletter' && !isAdmin) ||
      (currentConversation.conversationWith === 'Newsletter' &&
        !user.permissions.includes('SEND_NEWSLETTER'))
    ) {
      return false;
    }
    return true;
  };

  return (
    <MessagesWindowWrapper isNavigatorOpen={isNavigatorOpen}>
      <MessagesWindowHeader>
        <ReceiverData>
          <ArrowBackButton onClick={toggleNavigator} src={ArrowBack} />
          <div>
            <h2>{currentConversation?.conversationWith}</h2>
            <p>
              Temat sprawy: <span>{currentConversation?.category?.name}</span>
            </p>
          </div>
        </ReceiverData>
      </MessagesWindowHeader>
      <ConversationMessagesWrapper>
        {currentConversation?.messages?.map((message, i) => (
          <Message
            key={i}
            message={message}
            getAttachment={getAttachment}
            currentUser={currentUser}
          />
        ))}
        <div ref={scrollHelper} />
      </ConversationMessagesWrapper>

      <MessageEditorWrapper>
        <div className='wrapperEditor'>
          <div ref={quillRef} />
        </div>
        <ButtonsWrapper>
          <AttachmentButton src={Attachment} onClick={handleAttachmentClick} />
          <Button
            onClick={sendOnClick}
            disabled={!canSendMessages()}
            style={{ margin: 'auto 2.5% auto 10px' }}
          >
            Wyślij
          </Button>
        </ButtonsWrapper>
      </MessageEditorWrapper>
      {!canSendMessages() && <MessageEditorWrapperOverlay />}
    </MessagesWindowWrapper>
  );
};

export default ConversationsWindow;
