import React from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ReactComponent as BigText } from 'assets/icons/bigText.svg';
import { ReactComponent as SmallText } from 'assets/icons/smallText.svg';
import Photo1 from 'assets/images/auth-image-1.png';
import Photo2 from 'assets/images/auth-image-2.png';
import LogoTerespol from 'assets/images/terespol_logo.png';

import * as S from './AuthLayout.css';
import MayerynLogo from 'components/atoms/MayerynLogo';

const AuthLayout = ({ children, changeZoom }) => {
  const images = [
    {
      id: 1,
      src: Photo1,
      alt: 'photo-one',
    },
    {
      id: 2,
      src: Photo2,
      alt: 'photo-two',
    },
  ];

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <S.Wrapper>
      <S.Main>
        <S.Header>
          <S.PageZoomSettings>
            <BigText
              onClick={() => changeZoom(0.1)}
              style={{ cursor: 'pointer' }}
            />
            <SmallText
              onClick={() => changeZoom(-0.1)}
              style={{ cursor: 'pointer' }}
            />
          </S.PageZoomSettings>
          <S.Logo src={LogoTerespol} alt='logo-terespol' />
          <h1>Elektroniczne Biuro Obsługi Klienta</h1>
        </S.Header>
        <S.MainContent>{children}</S.MainContent>
      </S.Main>
      <S.Aside>
        <S.SliderWrapper>
          <Slider {...settings}>
            <S.SliderItem>
              <img src={images[0].src} alt={images[0].alt} />
              <h2>Sprawdzaj faktury online</h2>
              <p>Wszystkie Twoje faktury w jednym miejscu</p>
            </S.SliderItem>
            <div>
              <S.SliderItem>
                <img src={images[1].src} alt={images[1].alt} />
                <h2>Monitoruj zużycie wody</h2>
                <p> Generuj roczne, kwartalne lub miesięczne raporty</p>
              </S.SliderItem>
            </div>
          </Slider>
        </S.SliderWrapper>
        <MayerynLogo />
      </S.Aside>
    </S.Wrapper>
  );
};

export default AuthLayout;
