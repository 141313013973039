import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'm-web-components';
import { toast } from 'react-toastify';

import Indicator from 'components/atoms/Indicator';
import UserDataSection from '../../UserDetails/UserData/UserDataSection';
import { accountFields } from './accountDataInputs';
import DeleteAccountSection from 'components/Shared/DeleteAccountSection/DeleteAccountSection';
import Section from 'components/Shared/Settings/Section';
import { NotificationData } from 'components/Shared/Settings/Settings.css';
import { apiEndpoints, sendRequest } from 'API';
import { ACCESS_TOKEN } from 'API/constants';
import allActions from 'actions';
import { role } from 'auth/auth';
import CustomCheckbox from 'components/Shared/CustomCheckbox/CustomCheckbox.css';

const AccountSettings = ({ swapDeleteView, admin, userData }) => {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const settings = admin
    ? userData.adminSettings
    : userData.functionarySettings;

  const [userNotificationData, setUserNotificationData] = useState(settings);
  const [isEditableUserData, setIsEditableUserData] = useState(false);
  const [isEditNotifications, setIsEditNotifications] = useState(false);

  const dataToRender = [
    {
      text: 'Nowa wiadomość w systemie',
      name: 'newMessageNotification',
      checked: settings?.newMessageNotification,
    },
    {
      text: 'Powiadomienia e-mail o aktywności na koncie',
      name: 'accountActivity',
      checked: settings?.accountActivity,
    },
  ];

  const changeNewMessage = async () => {
    try {
      await sendRequest.put(apiEndpoints.putNotifyNewMessage);
      toast.success('Dane zostały zaktualizowane');
    } catch (e) {
      toast.error('Nie udało się zapisać danych');
    }
  };

  const changeAccountActivity = async () => {
    try {
      await sendRequest.put(apiEndpoints.putNotifyAccount);
      toast.success('Dane zostały zaktualizowane');
    } catch (e) {
      toast.error('Nie udało się zapisać danych');
    }
  };

  const toggleEditNotifications = () => {
    setIsEditNotifications(prev => !prev);
  };

  const handleSwitchChange = e => {
    setUserNotificationData(prev => ({
      ...prev,
      [e.target.name]: !prev[e.target.name],
    }));
  };

  const handleSave = async values => {
    if (admin) {
      try {
        const {
          data,
        } = await sendRequest.put(
          apiEndpoints.updateUserDataByAdmin(userData.userDetails?.id),
          { ...values, phone: values.phoneNumber },
        );

        if (data?.token) {
          localStorage.setItem(ACCESS_TOKEN, `${data?.token}`);
        }

        dispatch(allActions.authActions.getCurrentUserAction());

        toast.success('Dane zostały zaktualizowane');
      } catch (e) {
        toast.error('Nie udało się zapisać danych');
      }
    } else {
      try {
        const { data } = await sendRequest.put(
          apiEndpoints.putUserContact,
          values,
        );
        if (data) {
          localStorage.setItem(ACCESS_TOKEN, `${data}`);
        }
        dispatch(allActions.authActions.getCurrentUserAction());

        toast.success('Dane zostały zaktualizowane');
      } catch (e) {
        toast.error('Nie udało się zapisać danych');
      }
    }
    setIsEditableUserData(false);
  };

  const handleSaveNotifications = () => {
    if (
      settings.newMessageNotification !==
      userNotificationData.newMessageNotification
    ) {
      changeNewMessage();
    }
    if (settings.accountActivity !== userNotificationData.accountActivity) {
      changeAccountActivity();
    }
    toggleEditNotifications();
  };

  const buttonFormProps = isEditable => ({
    colorType: 'primary',
    padding: '5px 20px',
    margin: '0',
    style: {
      display: `${isEditable ? 'block' : 'none'}`,
      position: 'absolute',
      top: '15px',
      right: '15px',
    },
  });

  const renderOptions = () =>
    dataToRender.map(el => (
      <NotificationData key={el.name}>
        <h4>{el.text}</h4>
        <CustomCheckbox
          disabled={!isEditNotifications}
          checked={userNotificationData[el.name]}
          onClick={handleSwitchChange}
          name={el.name}
          bgColor={`${isEditNotifications ? 'white' : 'black'}`}
          style={{
            color: `${isEditNotifications ? '#1B69F2' : 'white'}`,
            padding: '5px 5px 5px 0px',
            transform: 'translateX(-10px)',
          }}
        />
      </NotificationData>
    ));

  return (
    <>
      {!userData ? (
        <Indicator />
      ) : (
        <>
          <UserDataSection
            title='Dane użytkownika'
            status={isEditableUserData}
            setStatus={setIsEditableUserData}
            isPermission={user.permissions.includes('EDIT_MY_DETAILS')}
          >
            <Form
              defaultValues={userData.userDetails}
              maxWidth='500px'
              onSubmit={handleSave}
              formFields={accountFields(
                isEditableUserData,
                admin ? true : false,
              )}
              labelSubmit='Zapisz'
              center
              buttonProps={buttonFormProps(isEditableUserData)}
            />
          </UserDataSection>
          <Section
            editPermissions='EDIT_MY_DETAILS'
            title='Powiadomienia'
            isEditable={isEditNotifications}
            toggleIsEditable={toggleEditNotifications}
            handleSave={handleSaveNotifications}
            style={{ marginTop: '25px' }}
          >
            {renderOptions()}
          </Section>

          {user.userDetails.role !== role.admin && (
            <DeleteAccountSection swapDeleteView={swapDeleteView} />
          )}
        </>
      )}
    </>
  );
};

export default AccountSettings;
