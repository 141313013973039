/* eslint-disable indent */
import styled from 'styled-components';
import theme from 'styles/theme';
import { device } from 'styles/devices';
import ErrorSign from 'assets/icons/error_sign.svg';

export const ErrorMessage = styled.span`
  min-width: 150px;
  display: inline-block;
  text-align: right;
  color: #b43236;

  &:before {
    content: url(${ErrorSign});
  }
`;

// Wrappers

export const CommunicatorWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${theme.lightSecondaryColor};
`;

export const ConversationsNavigatorWrapper = styled.div`
  display: ${({ isNavigatorOpen }) =>
    isNavigatorOpen === true ? 'block' : 'none'};
  width: 100%;
  height: 100%;
  background-color: ${theme.lightSecondaryColor};
  box-shadow: 0px 10px 10px ${theme.shadowColor};

  @media ${device.tablet} {
    display: block;
    width: 30%;
    min-width: 250px;
  }
`;

export const MessagesWindowWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: ${({ isNavigatorOpen }) =>
    isNavigatorOpen === false ? 'block' : 'none'};
  background-color: ${theme.messageWindowColor};

  @media ${device.tablet} {
    display: block;
    width: 70%;
  }
`;

// ConverstionNavigator Components

export const ConversationNavigatorMenu = styled.div`
  width: 100%;
  height: 200px;
  padding: 15px;
  padding-top: 0px;

  h1 {
    margin: 0px;
  }
`;

export const ConversationsList = styled.div`
  width: 100%;
  height: 50%;
  max-height: 50vh;
  overflow-y: auto;

  @media ${device.tablet} {
    height: 63%;
    max-height: 63vh;
  }
`;

export const ConversationButtonField = styled.div`
  height: 10%;
  max-height: 10%;
  width: 100%;
  background-color: ${theme.lightSecondaryColor};
`;

export const FiltersAndSortContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
`;

export const ConversationTile = styled.div`
  width: 100%;
  height: 110px;
  cursor: pointer;
  background-color: ${({ selected }) =>
    selected !== null
      ? theme.selectedConversationColor
      : theme.lightSecondaryColor};
  border-right: ${({ selected }) =>
    selected !== null ? `5px solid ${theme.topicColor}` : 'none'};
  border-bottom: 1px solid ${theme.shadowColor};
  display: ${({ visible }) => (visible === false ? 'none' : 'flex')};
  flex-direction: column;

  > p {
    margin: 0px 5px 0px 0px;
    font-size: 12px;
    color: ${theme.textSecondaryColor};
    text-align: right;
  }
`;

export const Avatar = styled.div`
  margin: auto 15px auto 15px;
  background-color: ${theme.primary};

  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;

  > p {
    margin: auto !important;
    color: white !important;
    font-size: 18px !important;

    &::after {
      content: '' !important;
    }
  }
`;

export const SneakPeak = styled.div`
  height: 70%;
  display: flex;
  flex-direction: row;

  h2 {
    font-size: 15px;
    margin: 0px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const SneakPeakText = styled.div`
  max-height: 70%;
  overflow: hidden;
  margin: 0px;
  margin-top: 10px;
  font-size: 12px;
  color: ${theme.textSecondaryColor};
`;

// ConversationWindow

export const MessagesWindowHeader = styled.div`
  width: 100%;
  height: 10%;
  max-height: 10%;
  display: flex;
  flex-direction: column;
`;

export const MessageEditorWrapper = styled.div`
  width: 100%;
  height: 35%;
  max-height: 35%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${theme.lightSecondaryColor};

  .wrapperEditor {
    min-width: 95%;
    max-width: 95%;
    height: 50%;
  }

  .ql-toolbar,
  .ql-editor {
    min-width: 100%;
    max-width: 100%;

    p {
      min-width: 100%;
      max-width: 100%;
      word-break: break-word;
    }
  }

  .ql-container {
    max-height: 70%;

    @media ${device.tablet} {
      max-height: 90%;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  min-width: 100%;
  max-width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: end;

  @media ${device.tablet} {
    margin-top: 50px;
  }
`;

export const MessageEditorWrapperOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 30%;
  max-height: 30%;
  background-color: rgba(100, 100, 100, 0.5);
  transform: translateY(-100%);
  z-index: 1;
`;

export const ConversationMessagesWrapper = styled.div`
  height: 55%;
  max-height: 55vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const ReceiverData = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.lightSecondaryColor};
  display: flex;
  flex-direction: row;

  h2 {
    font-size: 16px;
    font-weight: 700;
    margin: 0px;
  }

  p {
    font-size: 15px;
    margin: 0px;
    color: ${theme.textSecondaryColor};
    > span {
      font-weight: 600;
      color: ${theme.topicColor};
    }
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const HeaderSelectContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${theme.textSecondaryColor};
  background-color: ${theme.lightSecondaryColor};

  h3 {
    font-size: 15px;
    margin: auto auto auto 0px;
  }

  > div {
    margin: auto;
    width: 100%;
  }

  @media ${device.desktop} {
    flex-direction: row;
    justify-content: space-between;

    > div {
      width: 35%;
    }

    h3 {
      margin: auto;
    }
  }
`;

export const HeaderConversationTitle = styled.div`
  width: 100%;
  height: 35px;
  padding: 9px;
  background-color: lightgrey;

  h1 {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const MessageTile = styled.div`
  margin-top: 30px;
  width: 100%;

  span {
    margin: 0px;
    width: 100%;
    white-space: pre-wrap;
  }

  div {
    div:first-child {
      display: flex;
      flex-direction: ${({ isCurrentUser }) =>
        isCurrentUser === true ? 'row-reverse' : 'row'};

      div {
        margin-left: 15px;
      }

      > div:first-child {
        display: ${({ isCurrentUser }) => isCurrentUser === false && 'none'};
      }
    }
  }
`;

export const MessageTimeText = styled.p`
  margin-left: 15px;
  margin-right: 15px;
  text-align: ${({ isCurrentUser }) =>
    isCurrentUser === false ? 'left' : 'right'};
  font-size: 12px;
  color: ${theme.textSecondaryColor};
`;

export const AttachmentsWrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  margin-left: ${({ isCurrentUser }) =>
    isCurrentUser === false ? '10px' : 'auto'};

  margin-right: ${({ isCurrentUser }) =>
    isCurrentUser === false ? 'auto' : '55px'};

  > button {
    cursor: pointer;
    margin: 10px;
    background-color: ${theme.lightColor};
    border: 1px solid ${theme.primary};
    border-radius: 5px;
  }
`;

export const AttachmentsHeader = styled.h4`
  margin: auto;
  margin-top: 10px !important;
  font-size: 12px;
  color: ${theme.textSecondaryColor};
`;

export const MessageContent = styled.div`
  padding: 10px;
  width: 70%;

  background-color: ${({ isCurrentUser }) =>
    isCurrentUser === true ? theme.myMessageColor : theme.incomingMessageColor};
  border-radius: 5px;

  p {
    word-break: break-word;
  }
`;

export const Button = styled.button`
  background-color: ${theme.primary};
  color: white;
  max-height: 80px;
  margin: auto;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: ${theme.secondaryColor};
  }
`;

export const AttachmentButton = styled.img`
  width: 36px;
  height: 36px;
  margin: auto 10px auto auto;
  cursor: pointer;
`;

export const ArrowBackButton = styled.img`
  width: 24px;
  height: 24px;
  margin: auto 10px auto 10px;
  cursor: pointer;

  @media ${device.tablet} {
    display: none;
  }
`;

export const NewMessageButtonContainer = styled.div`
  width: 100%;
  height: 15%;
  margin-bottom: 0px;
  margin-top: auto;
  display: flex;
  flex-direction: column;

  background-color: ${theme.lightSecondaryColor};

  @media (min-width: 1224px) {
    flex-direction: row;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  border: 1px solid #f2f2f2;
  box-shadow: 1px 1px 15px rgba(0, 4, 40, 0.03);
  background-color: #fff;

  svg {
    height: 30px;
    fill: #888794;
    margin: 0 10px;
  }

  input {
    border: none;
    margin: 0;
    width: 100%;
    height: 25px;
    padding: 0 10px;

    @media ${device.tablet} {
      height: 40px;
    }
  }
`;

export const customSelectStyles = {
  control: provided => ({
    ...provided,
    border: 'none',
    borderRadius: '4px',
    boxShadow: '1px 1px 15px rgba(0, 4, 40, 0.03)',
    fontFamily: `'Source Sans Pro', sans-serif`,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'black',
  }),
};

export const AddTopicIcon = styled.img`
  max-width: 20px;
  max-height: 20px;
  margin: auto 0px auto auto;

  &:hover {
    cursor: pointer;
  }

  @media ${device.desktop} {
    margin: auto;
  }
`;

// Add Topic Modal

export const AddTopicModalForm = styled.form`
  padding: 5px 15px 5px 15px;
`;

export const ModalTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 30px;
`;

export const TitlesListWrapper = styled.div`
  width: 100%;
  min-height: 320px;
  max-height: 320px;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const SingleTopicWrapper = styled.div`
  width: 100%;
  min-height: 60px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.textSecondaryColor};
    margin-bottom: 5px;
  }

  div {
    display: flex;
    flex-direction: column;

    input {
      padding: 10px;
      height: 32px;
      min-height: 32px;
      width: 75%;
      font-size: 16px;
      border: none;
      border: 1px solid #c4c3ca;
      border-radius: 5px;
    }

    @media ${device.tablet} {
      flex-direction: row;
    }
  }
`;

export const AddTopicButton = styled.button`
  margin: auto;
  border: none;
  background-color: transparent;
  color: ${theme.topicColor};
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }
`;

export const DeleteTopicButton = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  color: #c4c3ca;
  font-size: 16px;
  font-weight: 600;
  margin: auto 0px auto 0px;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 16px;
    height: 16px;
    margin: auto 6px auto 0px;
  }

  @media ${device.tablet} {
    margin-left: 16px;
  }
`;

export const ModalSaveButton = styled(Button)`
  padding: 3px 16px 3px 16px;
`;

export const CancelButton = styled(ModalSaveButton)`
  background-color: transparent;
  color: ${theme.primary};
  margin: 25px 0px auto 15%;

  &:hover {
    background-color: transparent;
  }

  @media ${device.tablet} {
    margin-left: 55%;
  }
`;

export const ButtonSearch = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;
