/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable dot-notation */
export function numberToWords(number) {
  // eslint-disable-next-line radix
  var liczba = parseInt(number);

  var jednosci = [
    '',
    ' jeden',
    ' dwa',
    ' trzy',
    ' cztery',
    ' pięć',
    ' sześć',
    ' siedem',
    ' osiem',
    ' dziewięć',
  ];
  var nascie = [
    '',
    ' jedenaście',
    ' dwanaście',
    ' trzynaście',
    ' czternaście',
    ' piętnaście',
    ' szesnaście',
    ' siedemnaście',
    ' osiemnaście',
    ' dziewietnaście',
  ];
  var dziesiatki = [
    '',
    ' dziesięć',
    ' dwadzieścia',
    ' trzydzieści',
    ' czterdzieści',
    ' pięćdziesiąt',
    ' sześćdziesiąt',
    ' siedemdziesiąt',
    ' osiemdziesiąt',
    ' dziewięćdziesiąt',
  ];
  var setki = [
    '',
    ' sto',
    ' dwieście',
    ' trzysta',
    ' czterysta',
    ' pięćset',
    ' sześćset',
    ' siedemset',
    ' osiemset',
    ' dziewięćset',
  ];
  var grupy = [
    ['', '', ''],
    [' tysiąc', ' tysiące', ' tysięcy'],
    [' milion', ' miliony', ' milionów'],
    [' miliard', ' miliardy', ' miliardów'],
    [' bilion', ' biliony', ' bilionów'],
    [' biliard', ' biliardy', ' biliardów'],
    [' trylion', ' tryliony', ' trylionów'],
  ];

  // jezeli pole zawiera poprawna wartosc calkowita
  var wynik = '';
  var znak = '';
  if (liczba == 0) wynik = 'zero';
  if (liczba < 0) {
    znak = 'minus';
    liczba = -liczba;
  }

  var g = 0;
  while (liczba > 0) {
    var s = Math.floor((liczba % 1000) / 100);
    var n = 0;
    var d = Math.floor((liczba % 100) / 10);
    var j = Math.floor(liczba % 10);

    if (d == 1 && j > 0) {
      n = j;
      d = 0;
      j = 0;
    }

    var k = 2;
    if (j == 1 && s + d + n == 0) k = 0;
    if (j == 2 || j == 3 || j == 4) k = 1;
    // eslint-disable-next-line curly
    if (s + d + n + j > 0)
      // eslint-disable-next-line nonblock-statement-body-position
      wynik =
        setki[s] +
        dziesiatki[d] +
        nascie[n] +
        jednosci[j] +
        grupy[g][k] +
        wynik;

    g++;
    liczba = Math.floor(liczba / 1000);
  }
  return znak + wynik;
}
