import paginationActions from 'actions/pagination.action';

export const SET_CURRENT_PAGE_FACTURES = 'SET_CURRENT_PAGE_FACTURES';
export const SET_EVIDENCES_FACTURES = 'SET_EVIDENCES_FACTURES';

const setCurrentPageFactures = item => ({
  type: SET_CURRENT_PAGE_FACTURES,
  item,
});

const setEvidencesFactures = item => ({
  type: SET_EVIDENCES_FACTURES,
  item,
});

const facturesGetAction = (
  type = 'factures',
  newValue,
  category,
) => async dispatch => {
  const { content, evidences } = await dispatch(
    paginationActions.getResultsAction(type, newValue, category),
  );
  dispatch(setCurrentPageFactures(content));
  dispatch(setEvidencesFactures(evidences));
};

export default {
  setCurrentPageFactures,
  facturesGetAction,
};
