import React from 'react';
import { routes } from 'routes';
import { Link } from 'react-router-dom';

import { ErrorTitle } from './Error.css';

const AuthError404 = () => {
  return (
    <>
      <ErrorTitle>
        <h1>Błąd 404</h1>
        <p>Ta strona nie istnieje</p>
        <Link to={routes.login}>Wróć do strony logowania</Link>
      </ErrorTitle>
    </>
  );
};

export default AuthError404;
