export const convertDate = ISOString => {
  const today = new Date();
  const date = new Date(ISOString);

  if (today.toLocaleDateString() === date.toLocaleDateString()) {
    return `dzisiaj, ${date.toLocaleTimeString()}`;
  } else {
    return `${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`;
  }
};

export const convertSneakPeakDate = ISOString => {
  const today = new Date();
  const date = new Date(ISOString);

  if (today.toLocaleDateString() === date.toLocaleDateString()) {
    return date.toLocaleTimeString();
  } else {
    return `${('0' + date.getDate()).slice(-2)}.${(
      '0' +
      (date.getMonth() + 1)
    ).slice(-2)}`;
  }
};
