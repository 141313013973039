import { Button } from 'm-web-components';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { device } from 'styles/devices';

const Header = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0, 4, 40, 0.06);
  margin-bottom: 30px;

  h3 {
    margin: 0;
  }

  @media ${device.desktop} {
    padding: 15px 15px 15px 25px;

    h3 {
      margin: 0;
      font-size: 20px;
    }
  }
`;

const Section = styled.section`
  position: relative;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0, 4, 40, 0.04);
  margin-top: 20px;
  padding-bottom: 10px;

  form > div {
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column-reverse;

  :nth-child(2) {
    margin: 5px -15px 0 0;
  }

  @media (min-width: 350px) {
    flex-direction: row;

    :nth-child(2) {
      margin: 0;
    }
  }
`;

const UserDataSection = ({
  children,
  setStatus,
  status,
  title,
  isPermission,
}) => {
  return (
    <Section>
      <Header>
        <h3>{title}</h3>
        {setStatus && !status && isPermission && (
          <Button
            type='button'
            colorType='primary'
            padding='5px 20px'
            margin='0'
            onClick={() => setStatus(true)}
          >
            Edytuj
          </Button>
        )}
        {setStatus && status && (
          <Buttons>
            <Button
              type='button'
              colorType='deny'
              padding='5px 20px'
              margin='0 15px'
              onClick={() => setStatus(false)}
              fontWeight='600'
              style={{ border: 'none' }}
            >
              Anuluj
            </Button>
            <Button
              type='button'
              colorType='primary'
              padding='5px 20px'
              margin='0'
              style={{ opacity: '0' }}
            >
              Zapisz
            </Button>
          </Buttons>
        )}
      </Header>
      {children}
    </Section>
  );
};

export default UserDataSection;
