import { Button } from 'm-web-components';
import React from 'react';
import styled, { css } from 'styled-components';

const ButtonMenu = styled(Button)`
  border-radius: 0;
  flex-grow: 1;
  margin: 0;
  border: none;
  background-color: #888794;
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
  padding: 7px 15px;

  :hover {
    transform: scale(1.1);
    color: white;
    box-shadow: none;
  }

  ${({ active }) =>
    active &&
    css`
      color: white;
      background-color: ${({ theme }) => theme.primary};
    `}
`;

export const Menu = styled.nav`
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  height: 32px;
  margin-left: auto;
  width: 100%;
  margin: 0 0 0 0px;
  flex-wrap: wrap;

  @media (max-width: 530px) {
    height: ${({ big }) => (big ? '70px' : '32px')};
  }
`;

const HorizontalMenuSwitch = ({ buttons, onClick, currentType, big }) => {
  const renderButtons = buttons.map(button => (
    <ButtonMenu
      key={button.type}
      active={currentType === button.type}
      onClick={() => onClick(button.type)}
    >
      {button.label}
    </ButtonMenu>
  ));

  return <Menu big={big}>{renderButtons}</Menu>;
};

export default HorizontalMenuSwitch;
