import styled from 'styled-components';
import { device } from 'styles/devices';

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const WrapperTail = styled.div`
  width: 100%;
  background-color: white;
  margin: 10px 0;
  padding: 20px 20px 30px;
  max-width: 270px;

  @media ${device.tablet} {
    margin: 0 10px;
  }
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.titleLight};
  font-size: 14px;
`;

export const Value = styled.p`
  font-size: 34px;
  font-weight: bold;
`;

export const TableWrapper = styled.div`
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;

  table {
    box-shadow: none;
  }

  > a {
    display: block;
    padding: 10px;
    padding-top: 20px;
    color: ${({ theme }) => theme.primaryLight};
    text-decoration: underline;
    transition: 0.3s;

    :hover {
      transform: translateX(10px);
    }
  }
`;
