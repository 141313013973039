import axios from 'axios';
import { ACCESS_TOKEN } from './constants';
import Qs from 'qs';
import { toast } from 'react-toastify';

export const sendRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
});

export const sendRequestFormData = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/xml' },
});

export const sendRequestBlob = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/octet-stream' },
});

sendRequest.interceptors.request.use(
  config => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  },
  error => {
    if (
      window.location.pathname.includes('login') &&
      error?.response?.status === 401
    ) {
      return;
    }
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      window.location.href = '/login';
    }

    console.log(error);
    throw error;
  },
);

sendRequestFormData.interceptors.request.use(
  config => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  },
  error => {
    if (
      window.location.pathname.includes('login') &&
      error?.response?.status === 401
    ) {
      return;
    }
    if (error?.response?.status === 401) {
      window.location.href = '/login';
    }

    console.log(error);
    throw error;
  },
);

sendRequestBlob.interceptors.request.use(
  config => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.responseType = 'blob';
    return config;
  },
  error => {
    if (
      window.location.pathname.includes('login') &&
      error?.response?.status === 401
    ) {
      return;
    }
    if (error?.response?.status === 401) {
      window.location.href = '/login';
    }

    toast.error(
      'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z dostawcą oprogramowania',
    );
    console.log(error);
    throw error;
  },
);

const params = paramsObj => Qs.stringify(paramsObj, { arrayFormat: 'repeat' });

export const apiEndpoints = {
  loginAdmin: '/login-admin',
  signup: '/signup',
  getCurrentUser: '/user/me',
  logutEpuap: '/logout-epuap',
  loginEpuap: '/login-epuap',

  getRegulations: '/regulations',

  getReport: ({ aggBy, dFrom, dTo, category, uId, eNr }) =>
    `/${category}report?aggBy=${aggBy}&dFrom=${dFrom}&dTo=${dTo}&uId=${uId}&eNr=${eNr}`,

  getUserCode: '/user/code',
  deleteAccount: code => `/user/delete?CODE=${code}`,
  putUserContact: '/user/contact',
  putNotifyNewsletter: '/user/notify/newsletter',
  putNotifyFacture: '/user/notify/facture',
  putNotifyNewMessage: '/user/notify/new-message',
  putNotifyAccount: '/user/notify/account',

  facture: id => `/factures/${id}`,

  pagination: ({ category, type, ...paramsObj }) => {
    delete paramsObj.totalElements;
    delete paramsObj.search;
    const params = Qs.stringify(paramsObj, { arrayFormat: 'repeat' });
    return `/${category}${type}?${params}`;
  },

  getChat: ({ ids, sortType, chIn, dTo, dFrom, conversation }) =>
    `/chat?ids=${ids}&sortType=${sortType}&chIn=${chIn}&dTo=${dTo}&dFrom=${dFrom}&conversation=${conversation}`,

  getAdminChat: ({ ids, sortType, chIn, dTo, dFrom, conversation, uId }) =>
    `/admin/chat?ids=${ids}&sortType=${sortType}&chIn=${chIn}&dTo=${dTo}&dFrom=${dFrom}&conversation=${conversation}&uId=${uId}`,

  getCategory: '/chat/category',
  putDeactivateCategory: id => `/chat/deactivate/${id}/category`,
  putUpdateCategory: id => `/chat/${id}/category`,
  postNewCategory: '/chat/category',
  getConversation: id => `/chat/${id}/conversation`,
  getAttachment: id => `/chat/${id}/attachment`,
  getAttachmentInfo: '/chat/attachment-info',
  getReceivers: '/chat/users',

  postNewChat: '/chat/new',
  putSendMessage: id => `/chat/${id}/send`,

  getFile: id => `/files/${id}/download`,
  postAddFile: '/files/add',
  deleteFile: id => `/files/${id}`,

  // ADMIN
  getAdminData: '/admin',
  postCreateAdmin: '/admin/create',
  getRoles: '/admin/roles',
  getRole: '/admin/role',
  getRolesSuper: '/admin/role/super',
  getRolePermissions: id => `/admin/role/${id}`,
  postAddRole: '/admin/role/add',
  putUpdatePermissions: (id, category) => `/admin/role/${id}/${category}`,
  getUserDetailsByAdmin: id => `/admin/details/${id}/user`,
  updateUserRoleByAdmin: id => `/admin/role/${id}/user`,
  updateUserDataByAdmin: id => `/admin/details/${id}/user`,
  getCodeToDeleteUserByAdmin: id => `/admin/${id}/code`,
  deleteUserByAdmin: code => `/admin/user/delete?CODE=${code}`,
  changeRoleName: (id, roleName) => `/admin/role/${id}?roleName=${roleName}`,

  //EBOK SETTINGS
  regulation: '/admin/settings/regulations', //get, put, delete
  putUpdateFactureData: '/admin/settings/facture-data',
  putUpdateAttachmentSize: size =>
    `/admin/settings/attachment-size?size=${size}`,
  putUpdateExtensions: arr =>
    `/admin/settings/attachment-extensions?${params(arr)}`,
  updatePkwiu: '/admin/settings/pkwiu',

  //NOTIFICATIONS
  notifications: 'user/notifications',
  deleteOneNotification: id => `user/notifications/${id}`,
};
