import { SET_CURRENT_PAGE_LOGS } from 'actions/logs.action';

const INITIAL_STATE = {
  result: [],
};

const logsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE_LOGS:
      return { ...state, result: action.item };

    default:
      return state;
  }
};

export default logsReducer;
