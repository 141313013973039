import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Button } from 'm-web-components';
import { apiEndpoints, sendRequestBlob } from 'API';
import Section from 'components/Shared/Settings/Section';
import DeleteModal from './DeleteModal';
import AddModal from './AddModal';
import theme from 'styles/theme';
import { device } from 'styles/devices';

import EyeIcon from 'assets/icons/eye.svg';
import TrashCan from 'assets/icons/trash_can.svg';

const RegulationWrapper = styled.div`
  width: 100%;
  padding: 40px 25px 0px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  p,
  h4 {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 400;
  }

  p {
    color: ${theme.textSecondaryColor};
  }

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FileButton = styled.button`
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding: 0px;
  border: none;
  background-color: transparent;
  font-weight: 600;
  color: ${({ theme, deleteButton }) =>
    deleteButton ? '#c4c3ca' : theme.primaryLight};
  transition: 0.3s;
  cursor: pointer;

  :hover {
    transform: scale(1.05);
  }

  img {
    margin-right: 10px;
  }
`;

const RegulationSection = ({
  isEditRegulation,
  toggleEditRegulation,
  handleSaveRegulation,
  regulationName,
}) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const toggleAddModal = () => {
    setIsAddModalOpen(prev => !prev);
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(prev => !prev);
  };

  const showRegulation = async () => {
    try {
      const { data } = await sendRequestBlob.get(apiEndpoints.regulation);

      const blob = new Blob([data], {
        type: 'application/pdf',
      });
      const url = URL.createObjectURL(blob);
      window.open(url);
      URL.revokeObjectURL(url);
    } catch (e) {
      toast.error('Wystąpił błąd podczas pobierania pliku');
    }
  };

  return (
    <Section
      editPermissions='EDIT_MY_DETAILS'
      title='Regulamin'
      isEditable={isEditRegulation}
      toggleIsEditable={toggleEditRegulation}
      handleSave={handleSaveRegulation}
      style={{ marginTop: '25px' }}
    >
      <DeleteModal
        toggleDeleteModal={toggleDeleteModal}
        isDeleteModalOpen={isDeleteModalOpen}
      />

      <AddModal toggleModal={toggleAddModal} isModalOpen={isAddModalOpen} />

      <RegulationWrapper>
        {regulationName ? (
          <h4>{regulationName}</h4>
        ) : (
          <p>Brak wczytanego pliku.</p>
        )}
        {isEditRegulation && (
          <ButtonsWrapper>
            {regulationName && (
              <>
                <FileButton type='button' onClick={() => showRegulation()}>
                  <img src={EyeIcon} alt='ikona oka' /> Podgląd
                </FileButton>
                <FileButton
                  type='button'
                  onClick={toggleDeleteModal}
                  deleteButton
                >
                  <img src={TrashCan} alt='ikona kosza' /> Usuń
                </FileButton>
              </>
            )}
            <Button
              type='button'
              colorType='primary'
              padding='10px 20px'
              margin='0px auto 0px 40px'
              onClick={toggleAddModal}
            >
              Wybierz plik
            </Button>
          </ButtonsWrapper>
        )}
      </RegulationWrapper>
    </Section>
  );
};

export default RegulationSection;
