import { routes } from 'routes/index';

import ImportedFiles from 'assets/icons/imported_files.svg';
import Communicator from 'assets/icons/communicator.svg';
import Permissions from 'assets/icons/permissions.svg';
import SystemLogs from 'assets/icons/system_logs.svg';
import UserPanel from 'assets/icons/user_panel.svg';
import Invoices from 'assets/icons/invoices.svg';
import Reports from 'assets/icons/reports.svg';
import Usage from 'assets/icons/usage.svg';
import Users from 'assets/icons/users.svg';

export const userRoutesSet = [
  {
    permissions: ['SYSTEM_LOGIN'],
    to: routes.user.home,
    src: UserPanel,
    alt: 'okona panelu klienta',
    text: 'Panel klienta',
  },
  {
    permissions: ['SHOW_SEND_MESSAGES'],
    to: routes.user.communicator,
    src: Communicator,
    alt: 'ikona komunikatora',
    text: 'Komunikacja',
  },
];

export const userSecondaryRoutesSet = [
  {
    permissions: ['SYSTEM_LOGIN'],
    to: routes.user.consumption,
    src: Usage,
    alt: 'ikona zużycia',
    text: 'Zużycie',
  },
  {
    permissions: ['SYSTEM_LOGIN'],
    to: routes.user.invoices,
    src: Invoices,
    alt: 'ikona faktur',
    text: 'Faktury',
  },
  {
    permissions: ['SYSTEM_LOGIN'],
    to: routes.user.reports,
    src: Reports,
    alt: 'ikona raportów',
    text: 'Raporty',
  },
];

export const adminRoutesSet = name => [
  {
    permissions: ['SYSTEM_LOGIN'],
    to: routes.admin.home,
    src: UserPanel,
    alt: 'ikona panelu admina',
    text: `Panel ${name}`,
  },
  {
    permissions: ['SHOW_SEND_MESSAGES'],
    to: routes.admin.communicator,
    src: Communicator,
    alt: 'ikona komunikatora',
    text: 'Komunikacja',
  },
];

export const adminSecondaryRoutesSet = [
  {
    permissions: ['SYSTEM_LOGIN'],
    to: routes.admin.importedFiles,
    src: ImportedFiles,
    alt: 'ikona faktur',
    text: 'Importowanie plików',
  },
  {
    permissions: ['SYSTEM_LOGIN'],
    to: routes.admin.invoices,
    src: Invoices,
    alt: 'ikona faktur',
    text: 'Faktury',
  },
  {
    permissions: ['SYSTEM_LOGIN'],
    to: routes.admin.consumption,
    src: Usage,
    alt: 'ikona zaużycia',
    text: 'Zużycia',
  },
];

export const adminManagementRoutesSet = [
  {
    permissions: ['SHOW_OTHERS_DETAILS'],
    to: routes.admin.users,
    src: Users,
    alt: 'ikona użytkowników',
    text: 'Użytkownicy',
  },
  {
    permissions: ['SHOW_LOGS'],
    to: routes.admin.logs,
    src: SystemLogs,
    alt: 'ikona logów',
    text: 'Logi systemowe',
  },
  {
    permissions: ['SHOW_PERMISSIONS'],
    to: routes.admin.permissions,
    src: Permissions,
    alt: 'ikona uprawnień',
    text: 'Uprawnienia',
  },
];
