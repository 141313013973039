import React from 'react';
import PropTypes from 'prop-types';

import { Document, Page, Text, View, PDFViewer } from '@react-pdf/renderer';

import FactureList from './FactureList';
import SingleCompany from './SingleCompany';
import { numberToWords } from './numberToWords';
import { styles } from './Facture.css';

const Facture = ({
  factureNumber,
  dateCreated,
  seller,
  buyer,
  productList,
  total,
  typeOfPayment,
  paymentDeadLine,
  dateFrom,
  dateTo,
  address,
  group,
  fabricNr,
  measurement1,
  measurement2,
  measurementSum,
}) => {
  const [a, b] = total.totalGross.toFixed(2).toString().split('.');
  const totalGrossWords = `${numberToWords(a)} złotych${
    b ? `,  ${numberToWords(b)} groszy` : ''
  }`;

  return (
    <PDFViewer width='1000px' height='1200px'>
      <Document>
        <Page size='A4' style={styles.page}>
          <View>
            <Text style={styles.h1}>FAKTURA VAT</Text>
            <View style={styles.section}>
              <View>
                <Text style={styles.nrFV}>Nr {factureNumber}</Text>
                <Text style={styles.date}>
                  za dostawę wody i odprowadzanie ścieków
                </Text>
                <Text style={styles.date}>
                  za okres: {dateFrom} - {dateTo}
                </Text>
              </View>
              <View>
                <Text style={styles.date}>Data wystawienia: {dateCreated}</Text>
                <Text style={styles.date}>
                  Termin płatnośći: {paymentDeadLine}
                </Text>
              </View>
            </View>
            <View style={styles.section}>
              <SingleCompany data={seller} title='SPRZEDAWCA' />
              <SingleCompany data={buyer} title='NABYWCA' />
            </View>

            <View style={styles.section}>
              <View style={styles.moreInfo}>
                <Text>Punkt poboru: {address}</Text>
                <Text>Nr odbiorcy: {buyer.buyerId}</Text>
                <Text>Grupa: {group}</Text>
                <Text>Nr fabr.: {fabricNr}</Text>
              </View>
              <View style={styles.moreInfo}>
                <Text>Odczyt1: {measurement1} m3</Text>
                <Text>Odczyt2: {measurement2} m3</Text>
                <Text>Zużycie: {measurementSum} m3</Text>
              </View>
            </View>

            <View style={styles.section}>
              <FactureList list={productList} total={total} />
            </View>
            <View style={styles.endData}>
              <View style={styles.sum}>
                <Text>Sposób zapłaty: {typeOfPayment}</Text>
                <Text>Termin płatności: {paymentDeadLine}</Text>
              </View>
              <Text style={styles.sumWords}>{totalGrossWords}</Text>
            </View>
            <View style={styles.signatures}>
              <View style={styles.signature}>
                <Text style={styles.signatureName}> {seller.sign}</Text>
                <Text>Podpis osoby upoważnionej do wystawienia faktury</Text>
              </View>
              <View style={styles.signature}>
                <Text style={styles.signatureName}> </Text>
                <Text>Podpis osoby upoważnionej do odbioru faktury</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default Facture;

Facture.propTypes = {
  factureNumber: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  buyer: PropTypes.objectOf(PropTypes.string).isRequired,
  productList: PropTypes.objectOf(PropTypes.any).isRequired,
  total: PropTypes.objectOf(PropTypes.number).isRequired,
  typeOfPayment: PropTypes.string.isRequired,
  paymentDeadLine: PropTypes.string.isRequired,
};
