import paginationActions from 'actions/pagination.action';

export const SET_CURRENT_PAGE_USERS = 'SET_CURRENT_PAGE_USERS';

const setCurrentPageUsers = item => ({
  type: SET_CURRENT_PAGE_USERS,
  item,
});

const usersGetAction = (
  type = 'factures',
  newValue,
  category,
) => async dispatch => {
  const { content } = await dispatch(
    paginationActions.getResultsAction(type, newValue, category),
  );
  dispatch(setCurrentPageUsers(content));
};

export default {
  setCurrentPageUsers,
  usersGetAction,
};
