import React from 'react';
import { useSelector } from 'react-redux';
import MainWrapper from 'components/atoms/MainWrapper';
import { routes } from 'routes';
import { Link } from 'react-router-dom';

import { ErrorTitle } from './Error.css';

const Error404 = () => {
  const auth = useSelector(state => state.auth);

  return (
    <MainWrapper>
      <ErrorTitle>
        <h1>Błąd 404</h1>
        <p>Ta strona nie istnieje</p>
        {auth.user?.userDetails?.role === 'Admin' ? (
          <Link to={routes.admin.home}>Powrót do panelu admina</Link>
        ) : (
          <Link to={routes.user.home}>Powrót do panelu klienta</Link>
        )}
      </ErrorTitle>
    </MainWrapper>
  );
};

export default Error404;
