import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowDown from 'assets/icons/down_arrow_dark.svg';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import allActions from 'actions';
import { HeaderArrowDown } from './MainLayout.css';
import { Link } from 'react-router-dom';

import { role } from 'auth/auth';

const HeaderMenu = () => {
  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);

    await dispatch(allActions.authActions.logoutAction());
  };

  const handleCloseMenu = async () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: 'flex' }}>
      <HeaderArrowDown src={ArrowDown} onClick={handleClick} />
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        style={{ marginTop: '20px' }}
      >
        {user?.permissions?.includes('SHOW_MY_DETAILS') && (
          <Link
            to={
              user?.userDetails.role !== role.user
                ? '/admin/settings'
                : '/user/settings'
            }
            style={{ textDecoration: 'none' }}
          >
            <MenuItem onClick={handleCloseMenu}>Ustawienia</MenuItem>
          </Link>
        )}
        <MenuItem onClick={handleClose}>Wyloguj się</MenuItem>
      </Menu>
    </div>
  );
};

export default HeaderMenu;
