import React from 'react';
import { routes } from 'routes';
import { Link } from 'react-router-dom';

import { ErrorTitle } from '../Errors/Error.css';

const DeactivatingAccount = () => {
  return (
    <>
      <ErrorTitle>
        <h1>Zostałeś wylogowany.</h1>
        <p>
          Twoje konto zostało dezaktywowane, a prośba o usunięcie konta zostanie
          rozpatrzona przez administratora
        </p>
        <Link to={routes.login}>Wróć do strony startowej</Link>
      </ErrorTitle>
    </>
  );
};

export default DeactivatingAccount;
